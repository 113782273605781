<template>
 <div class="">
    <p class="image"><img src="../../../assets/可比性分析框架.png" alt=""></p>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "CA_Framework",
 data() {
   return {}
 },
 components:{}
}
</script>

<style scoped lang='scss'>
.image{
  text-align: center;
}
</style>