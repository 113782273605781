<template>
 <div class="container" v-if="Object.keys(analysisResult).length > 0">
   <ol class="ranking">
     <li>ETF轮换策略</li>
        <el-table
            :data="analysisResult.etf_rotation"
            style="width: 931px"
            border>
            <el-table-column
            prop="id"
            label="索引"
            width="100">
            </el-table-column>            
            <el-table-column
            prop="time"
            label="时间"
            width="150">
            </el-table-column>
            <el-table-column
            prop="sz50_month_up"
            label="上证50月度涨幅（%）"
            width="200">
            </el-table-column>
            <el-table-column
            prop="cyb_month_up"
            label="创业板月度涨幅（%）"
            width="180">
            </el-table-column>
            <el-table-column
            prop="gz_month_up"
            label="国债月度涨幅（%）"
            width="150">
            </el-table-column>
            <el-table-column
            prop="target"
            label="操作"
            width="150">
            </el-table-column>           
        </el-table>
     <li>行业ETF指数三折</li>
        <el-table
            :data="analysisResult.index_70_off"
            style="width: 451px"
            border>         
            <el-table-column
            prop="time"
            label="时间"
            width="150">
            </el-table-column>
            <el-table-column
            prop="index_code"
            label="指数代码"
            width="150">
            </el-table-column>
            <el-table-column
            prop="index_name"
            label="指数名称"
            width="150">
            </el-table-column>          
        </el-table>     
     <li>北向净流入</li>
       <ul style="list-style-type:disc" class="ranking">
         <li>30天持续净流入占总市值买入比例大于2%,总市值小于400亿，PE(TTM)大于0并且小于30</li>
            <div>
              <el-table
                  :data="analysisResult.northward_30"
                  style="width: 1051px"
                  border>
                  <el-table-column
                    prop="id"
                    label="索引"
                    width="100">
                  </el-table-column>                    
                  <el-table-column
                    prop="time"
                    label="时间"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="code"
                    label="股票代码"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    label="公司名称"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="pe_ttm"
                    label="PE_TTM"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="mv"
                    label="市值（万）"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="stock_hsgt_net_flow"
                    label="沪深股通净流入"
                    width="150">
                  </el-table-column>             
              </el-table>
            </div>         
         <li>60天持续净流入占总市值买入比例大于3%,总市值小于400亿，PE(TTM)大于0并且小于40</li>
            <div>
              <el-table
                  :data="analysisResult.northward_60"
                  style="width: 1051px"
                  border>
                  <el-table-column
                    prop="id"
                    label="索引"
                    width="100">
                  </el-table-column>                    
                  <el-table-column
                    prop="time"
                    label="时间"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="code"
                    label="股票代码"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    label="公司名称"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="pe_ttm"
                    label="PE_TTM"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="mv"
                    label="市值（万）"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="stock_hsgt_net_flow"
                    label="沪深股通净流入"
                    width="150">
                  </el-table-column>             
              </el-table>
            </div>              
       </ul>
   </ol>
 </div>
</template>

<script type="text/ecmascript-6">
import {Table,TableColumn} from 'element-ui'
export default {
 name: "Quantitative_Result",
 data() {
   return {
    analysisResult:{}
   }
 },
 components:{
   [Table.name]:Table,
   [TableColumn.name]:TableColumn        
 },
 mounted(){
   this.$http.quantitative_result_list().then(res=>{
     this.analysisResult = res.data
     console.log(res.data);
    }).catch(err=>{
        console.log(err)
    })     
 } 
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 20px;
    margin-left: 30px;
    font-size: 16px;
}
.ranking{
  margin: 20px;
}
</style>