<template>
 <div class="container" v-if="Object.keys(rankingData).length > 0">
   <ol class="">
     <li>分部估值法排行(当前市值/分部汇总市值从小到大)</li>
       <ul style="list-style-type:disc" class="ranking">
         <li>公司排行榜TOP30</li>
            <div>
              <el-table
                  :data="rankingData.mv_divide_samv"
                  style="width: 601px"
                  border>
                  <el-table-column
                    prop="id"
                    label="序号"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="info_id"
                    label="代码"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="industry_name"
                    label="行业"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="mv_divide_segment_aggregate_market_value"
                    label="当前市值/分部汇总市值"
                    width="200">
                  </el-table-column>
              </el-table>
            </div>         
         <li>行业TOP10数量占比</li>
            <div v-if="Object.keys(rankingData).length > 0">
              <div class="proCharts" ref="mvDivideSAMVIndustryChart" ></div>
            </div>              
       </ul>
     <li>DCF极值估值法排行</li>  
       <ul style="list-style-type:disc" class="ranking">
         <li>行业TOP10数量占比</li>
            <div v-if="Object.keys(rankingData).length > 0">
              <div class="proCharts" ref="mvDivideFCFEIndustryChart" ></div>
            </div>         
       </ul>
     <li>EV除以自由现金流的倍率排行</li>
       <ul style="list-style-type:disc" class="ranking">
         <li>公司排行榜TOP20</li>     
            <div>
              <el-table
                  :data="rankingData.ev_divide_fcff"
                  style="width: 651px"
                  border>
                  <el-table-column
                    prop="id"
                    label="序号"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="info_id"
                    label="代码"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="industry_name"
                    label="行业"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="ev_divide_fcff_last_year"
                    label="企业价值除以上一年的自由现金流"
                    width="250">
                  </el-table-column>
              </el-table>
            </div>
       </ul>              
     <li>公司年度PE除以行业年度PE排行</li>  
       <ul style="list-style-type:disc" class="ranking">
         <li>公司排行榜TOP20</li> 
            <div>
              <el-table
                  :data="rankingData.stock_pe_divide_industry_pe"
                  style="width: 601px"
                  border>
                  <el-table-column
                    prop="id"
                    label="序号"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="info_id"
                    label="代码"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="industry_name"
                    label="行业"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="stock_PE_TTM_devide_industry_PE"
                    label="公司PE（TTM）除以行业PE"
                    width="200">
                  </el-table-column>
              </el-table>
            </div>         
       </ul>           
     <li>毛利率年化增长排行</li>
       <ul style="list-style-type:disc" class="ranking">
         <li>公司排行榜TOP20</li> 
            <div>
              <el-table
                  :data="rankingData.gross_margin_annual_growth"
                  style="width: 611px"
                  border>
                  <el-table-column
                    prop="id"
                    label="序号"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="info_id"
                    label="代码"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="industry_name"
                    label="行业"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="gross_margin_annual_growth"
                    label="十年毛利率年化增长率（%）"
                    width="210">
                  </el-table-column>
              </el-table>
            </div>         
       </ul>       
     <li>公司营收占全行业营收比例的年化增长排行</li>
       <ul style="list-style-type:disc" class="ranking">
         <li>公司排行榜TOP20</li> 
            <div>
              <el-table
                  :data="rankingData.revenue_industry_ratio_annual_growth"
                  style="width: 721px"
                  border>
                  <el-table-column
                    prop="id"
                    label="序号"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="info_id"
                    label="代码"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="industry_name"
                    label="行业"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="revenue_industry_ratio_annual_growth"
                    label="公司营收占全行业营收比例的年化增长率（%）"
                    width="320">
                  </el-table-column>
              </el-table>
            </div>         
       </ul>       
   </ol> 
</div>
</template>

<script type="text/ecmascript-6">
import {Table,TableColumn} from 'element-ui'
export default {
 name: "FFA_Ranking_List",
 data() {
   return {
    rankingData:{}    
   }
 },
 components:{
   [Table.name]:Table,
   [TableColumn.name]:TableColumn        
 },
 updated(){
   if(Object.keys(this.rankingData.mv_divide_samv_industry).length>0){
     this.mv_divide_samv_industry_chart()
     this.mv_divide_fcfe_industry_chart()
   }
 },
 methods:{
   mv_divide_samv_industry_chart(){
     var data = [],dict={}
     for(var i=0;i<Object.keys(this.rankingData.mv_divide_samv_industry).length;i++){
        dict = {value:this.rankingData.mv_divide_samv_industry[i].count,name:this.rankingData.mv_divide_samv_industry[i].industry_name}
        data.splice(0,0,dict)
      }           
     var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          series: [
            {
              name: '公司个数',
              data: data,
              type: 'pie',
              label: {
                show: true,
                textStyle: {
                  fontSize: 10
                }
              },
              itemStyle: {
                  normal: {
                      shadowBlur: 10,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
              }              
            }
          ]
        }
      let myChart =this.$echarts.init(this.$refs.mvDivideSAMVIndustryChart);
      myChart.setOption(option)

   },
   mv_divide_fcfe_industry_chart(){
     var data = [],dict={}
     for(var i=0;i<Object.keys(this.rankingData.mv_divide_fcfe_industry).length;i++){
        dict = {value:this.rankingData.mv_divide_fcfe_industry[i].count,name:this.rankingData.mv_divide_fcfe_industry[i].industry_name}
        data.splice(0,0,dict)
      }           
     var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          series: [
            {
              name: '公司个数',
              data: data,
              type: 'pie',
              label: {
                show: true,
                textStyle: {
                  fontSize: 10
                }
              },
              itemStyle: {
                  normal: {
                      shadowBlur: 10,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
              }              
            }
          ]
        }
      let myChart =this.$echarts.init(this.$refs.mvDivideFCFEIndustryChart);
      myChart.setOption(option)

   }   
 },
 mounted(){
   this.$http.ffa_ranking_list().then(res=>{
     this.rankingData = res.data
    }).catch(err=>{
        console.log(err)
    })     
 }  
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 20px;
    margin-left: 30px;
    font-size: 16px;
}
.ranking{
  margin: 20px;
}
.proCharts{
    width: 50%;
    height: 500px;
    // background: rgb(14, 51, 129);
  }
</style>