<template>
 <div v-if="Object.keys(analysisResult).length > 0">
  <div class="conclusion" >
    <p><b>根据昨日价格，{{analysisResult.name}}的初始收益率为<font color="red">{{analysisResult.last_day[0].init_yield}}%</font>，5年年复合投资收益率为<font color="red">{{result_5Y_min}}-{{result_5Y_max}}%</font>之间，10年年复合投资收益率为<font color="red">{{result_10Y_min}}-{{result_10Y_max}}%</font>之间。</b></p>               
  </div>
  <div class="navigator">
    <span><router-link :to="{name:'first_math_tool'}" class="one">第1个数学工具</router-link></span>
    <span><router-link :to="{name:'second_math_tool'}" class="two">第2个数学工具</router-link></span>
    <span><router-link :to="{name:'third_math_tool'}" class="three">第三个数学工具</router-link></span>
  </div>
  <div>
   <router-view></router-view>
  </div>
 </div>

</template>

<script type="text/ecmascript-6">
export default {
  name: "What_Price_to_Buy",
  data() {
   return {
     analysisResult:{},
     result_5Y_max:0,
     result_5Y_min:0,
     result_10Y_max:0,
     result_10Y_min:0    
   }
  },
  methods:{
    result_compute(){
      this.result_5Y_max = this.analysisResult.last_day[0].after_5Y_compound_annual_return_rate_EA > this.analysisResult.last_day[0].after_5Y_compound_annual_return_rate_PA ? this.analysisResult.last_day[0].after_5Y_compound_annual_return_rate_EA:this.analysisResult.last_day[0].after_5Y_compound_annual_return_rate_PA
      this.result_10Y_max = this.analysisResult.last_day[0].after_10Y_compound_annual_return_rate_EA > this.analysisResult.last_day[0].after_10Y_compound_annual_return_rate_PA ? this.analysisResult.last_day[0].after_10Y_compound_annual_return_rate_EA:this.analysisResult.last_day[0].after_10Y_compound_annual_return_rate_PA
      this.result_5Y_min = this.analysisResult.last_day[0].after_5Y_compound_annual_return_rate_EA < this.analysisResult.last_day[0].after_5Y_compound_annual_return_rate_PA ? this.analysisResult.last_day[0].after_5Y_compound_annual_return_rate_EA:this.analysisResult.last_day[0].after_5Y_compound_annual_return_rate_PA
      this.result_10Y_min = this.analysisResult.last_day[0].after_10Y_compound_annual_return_rate_EA < this.analysisResult.last_day[0].after_10Y_compound_annual_return_rate_PA ? this.analysisResult.last_day[0].after_10Y_compound_annual_return_rate_EA:this.analysisResult.last_day[0].after_10Y_compound_annual_return_rate_PA
      }, 
    toPercent(point){
      var str=Number(point*100).toFixed(2);
      str+="%";
      return str;
    }          
  },
  mounted(){
    this.analysisResult = this.$store.state.tsa_result
  },
  updated(){
    if(Object.keys(this.analysisResult.last_year).length>0 && Object.keys(this.analysisResult.ten_year_data).length>0 && Object.keys(this.analysisResult.last_day).length>0){
      this.result_compute()
    }
  }      
}
</script>

<style scoped lang='scss'>
.conclusion{
  margin: 15px 5px;
}
.navigator{
  width: 400px;
  margin: 5px 5px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  .one{
    color: coral;
  }
  .two{
    color: chartreuse;
  }
  .three{
    color: deepskyblue;
  }
}
</style>