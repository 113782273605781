<template>
 <div class="">
    <article>
      <section>
        <p class="paragraph">这里的逆向投资分析仅针对上市公司的股票，行业部分的逆向分析请使用“周期投资分析”部分的工具。我们对上市公司股票的逆向分析，主要是采用了美股成功投资人的一套方法论。该方法论主要用于筛选出已发生较大跌幅，但又存在优秀财务特征的公司。</p>
        <p class="paragraph"><b>筛选的条件包括：</b></p>
        <p class="paragraph">1、最近一年内股价跌50%</p>
        <p class="paragraph">2、最近一季度CFO为正</p>
        <p class="paragraph">3、带息负债不超过总资产的50%</p>
        <p class="paragraph">4、5年年化营收增幅为正</p>
        <p class="paragraph"><b>参考的数据和指标包括：</b></p>
        <p class="paragraph">1、时间、代码、上市时间、所在行业、市值、股价</p>
        <p class="paragraph">2、往前250天的股价的最高价</p>
        <p class="paragraph">3、最近季度的CFO、带息负债、总资产、EV</p>
        <p class="paragraph">4、最近5年的营收:</p>
        <p class="paragraph">5、最近一年TTM的EBITDA和DEBT（带息负债）/ EBITDA</p>
        <p class="paragraph">6、最近一年TTM的回购：回购的股份数量、回购的股票金额</p>
        <p class="paragraph">7、3个月（60天）、半年（120天）和年初（年初第一个交易日）至今的股价总回报</p>
        <p class="paragraph">8、收集公司最近10条事件或新闻</p>
        <p class="paragraph">前面4条为筛选条件，不满足条件的公司剔除。满足条件的公司放入逆向分析股票集，提供上述8种数据给投资者。需要注意的是，本分析工具仅做了初步的筛选，投资者需要利用其他分析工具对公司进行定量和定性分析，识别出公司是否具有逆向投资的关键点，千万不能为了逆向而逆向。</p>
        <p class="paragraph">逆向策略可能在这种情况下最有效：在某个大事件或者黑天鹅的影响下，出现对手盘“恐慌性出逃”，发生流动性坍塌，即<b>“典型赔率不对称下出现的两元事件性投资机会”</b>。</p>
      </section>
    </article>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "Reverse_Summary",
 data() {
   return {}
 },
 components:{}
}
</script>

<style scoped lang='scss'>
.paragraph{
  text-indent: 2em;
  margin:5px 5px;
}
</style>