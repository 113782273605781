import { render, staticRenderFns } from "./TSA_Framework.vue?vue&type=template&id=4442c32c&scoped=true&"
import script from "./TSA_Framework.vue?vue&type=script&lang=js&"
export * from "./TSA_Framework.vue?vue&type=script&lang=js&"
import style0 from "./TSA_Framework.vue?vue&type=style&index=0&id=4442c32c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4442c32c",
  null
  
)

export default component.exports