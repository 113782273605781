<template>
 <div class="">
    <!-- <div v-if="searchSymbol">
      <h1>查询结果未找到！</h1>
    </div> -->
    <div>
      <div v-if="hasResult">
        <div v-if="Object.keys(analysisResult.stock_key_info_summary).length > 0">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="好生意" name="first"></el-tab-pane>
            <el-tab-pane label="好公司" name="second"></el-tab-pane>
            <el-tab-pane label="好价格" name="third"></el-tab-pane>
          </el-tabs>        
          <router-view></router-view>
        </div>
        <div v-else>
          <h1>暂不支持金融类公司的财务特征分析，或公司财务数据不全！</h1>  
        </div> 

      </div>  
      <div v-if="searchSymbol == undefined || searchSymbol == null || searchSymbol ==''">
        <h1>查询结果未找到！</h1>        
      </div>
   </div>

 </div>
</template>

<script type="text/ecmascript-6">
import {Tabs,TabPane} from 'element-ui'
export default {
 name: "FFA_Query_Result",
 data() {
   return {
    activeName:"first",
    analysisResult:{},
    searchSymbol:'',
    hasResult:false
   }
 },
 components:{
   [Tabs.name]:Tabs,
   [TabPane.name]:TabPane
 },
 methods: {
   handleClick(tab) {
     if(tab.index == 0){
       this.$router.push({name:'duration_of_operation'})           
     }
     else if(tab.index == 1){
       this.$router.push({name:'comparability_data'})
     }
     else{
       this.$router.push({name:'sensitivity_analysis_of_capital_expenditure'})
     }
   }
 },
 mounted(){
   this.searchSymbol = this.$store.state.searchsymbol
   let params = new URLSearchParams()
   if(this.searchSymbol){
      params.append('symbol',this.searchSymbol)
      this.$http.character_analysis(params).then(res=>{
        this.analysisResult = res.data
        this.$store.state.ffa_result = res.data
        this.hasResult = true
        // console.log(res.data);
        }).catch(err=>{
          console.log(err)
          this.analysisResult = {}
        })  
      
   }
   else{
     this.hasResult = false
   }


 }
}
</script>

<style scoped lang='scss'>
</style>