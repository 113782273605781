<template>
 <div class="container" v-if="Object.keys(rankingData).length>0">
   <ol class="">
     <li>未来10年的年复合收益率排行（权益估值法）</li>
       <ul style="list-style-type:disc" class="ranking">
         <li>正序</li>
            <div>
              <el-table
                  :data="rankingData.ea10yup"
                  style="width: 1401px"
                  border>
                  <el-table-column
                    prop="time"
                    label="时间"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="info_id"
                    label="代码"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="industry"
                    label="行业"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="price"
                    label="价格"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="init_yield"
                    label="初始收益率(%)"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="last_10Y_retained_yield"
                    label="过去十年的留存收益率(%)"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="after_10Y_compound_annual_return_rate_EA"
                    label="未来十年的年化收益率(%)"
                    width="200">
                  </el-table-column>                                                                                          
              </el-table>
            </div>         
         <li>逆序</li>
            <div>
              <el-table
                  :data="rankingData.ea10ydown"
                  style="width: 1401px"
                  border>
                  <el-table-column
                    prop="time"
                    label="时间"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="info_id"
                    label="代码"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="industry"
                    label="行业"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="price"
                    label="价格"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="init_yield"
                    label="初始收益率(%)"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="last_10Y_retained_yield"
                    label="过去十年的留存收益率(%)"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="after_10Y_compound_annual_return_rate_EA"
                    label="未来十年的年化收益率(%)"
                    width="200">
                  </el-table-column>                                                                                          
              </el-table>
            </div>              
       </ul>
     <li>未来10年的年复合收益率排行（每股收益增长估值法）</li>  
       <ul style="list-style-type:disc" class="ranking">
         <li>正序</li>
            <div>
              <el-table
                  :data="rankingData.pa10yup"
                  style="width: 1401px"
                  border>
                  <el-table-column
                    prop="time"
                    label="时间"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="info_id"
                    label="代码"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="industry"
                    label="行业"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="price"
                    label="价格"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="init_yield"
                    label="初始收益率(%)"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="last_10Y_retained_yield"
                    label="过去十年的留存收益率(%)"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="after_10Y_compound_annual_return_rate_PA"
                    label="未来十年的年化收益率(%)"
                    width="200">
                  </el-table-column>                                                                                          
              </el-table>
            </div>         
         <li>逆序</li>
            <div>
              <el-table
                  :data="rankingData.pa10ydown"
                  style="width: 1401px"
                  border>
                  <el-table-column
                    prop="time"
                    label="时间"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="info_id"
                    label="代码"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="industry"
                    label="行业"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="price"
                    label="价格"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="init_yield"
                    label="初始收益率(%)"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="last_10Y_retained_yield"
                    label="过去十年的留存收益率(%)"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="after_10Y_compound_annual_return_rate_PA"
                    label="未来十年的年化收益率(%)"
                    width="200">
                  </el-table-column>                                                                                          
              </el-table>
            </div>              

       </ul>
     <li>未来5年的年复合收益率排行（权益估值法）</li>
       <ul style="list-style-type:disc" class="ranking">
         <li>正序</li>
            <div>
              <el-table
                  :data="rankingData.ea5yup"
                  style="width: 1201px"
                  border>
                  <el-table-column
                    prop="time"
                    label="时间"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="info_id"
                    label="代码"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="industry"
                    label="行业"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="price"
                    label="价格"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="init_yield"
                    label="初始收益率(%)"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="after_5Y_compound_annual_return_rate_EA"
                    label="未来五年的年化收益率(%)"
                    width="200">
                  </el-table-column>                                                                                          
              </el-table>
            </div>         
         <li>逆序</li>
            <div>
              <el-table
                  :data="rankingData.ea5ydown"
                  style="width: 1201px"
                  border>
                  <el-table-column
                    prop="time"
                    label="时间"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="info_id"
                    label="代码"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="industry"
                    label="行业"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="price"
                    label="价格"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="init_yield"
                    label="初始收益率(%)"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="after_5Y_compound_annual_return_rate_EA"
                    label="未来五年的年化收益率(%)"
                    width="200">
                  </el-table-column>                                                                                          
              </el-table>
            </div>              
       </ul>
     <li>未来5年的年复合收益率排行（每股收益增长估值法）</li>
       <ul style="list-style-type:disc" class="ranking">
         <li>正序</li>
            <div>
              <el-table
                  :data="rankingData.pa5yup"
                  style="width: 1201px"
                  border>
                  <el-table-column
                    prop="time"
                    label="时间"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="info_id"
                    label="代码"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="industry"
                    label="行业"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="price"
                    label="价格"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="init_yield"
                    label="初始收益率(%)"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="after_5Y_compound_annual_return_rate_PA"
                    label="未来五年的年化收益率(%)"
                    width="200">
                  </el-table-column>                                                                                          
              </el-table>
            </div>         
         <li>逆序</li>
            <div>
              <el-table
                  :data="rankingData.pa5ydown"
                  style="width: 1201px"
                  border>
                  <el-table-column
                    prop="time"
                    label="时间"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="info_id"
                    label="代码"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="industry"
                    label="行业"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="price"
                    label="价格"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="init_yield"
                    label="初始收益率(%)"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="after_5Y_compound_annual_return_rate_PA"
                    label="未来五年的年化收益率(%)"
                    width="200">
                  </el-table-column>                                                                                          
              </el-table>
            </div>              
         </ul> 
   </ol>
 </div>
</template>

<script type="text/ecmascript-6">
import {Table,TableColumn} from 'element-ui'
export default {
 name: "TSA_Ranking_List",
 data() {
   return {
    rankingData:{}
   }
 },
 components:{
   [Table.name]:Table,
   [TableColumn.name]:TableColumn        
 },
 mounted(){
   this.$http.tsa_ranking_list().then(res=>{
     this.rankingData = res.data
    }).catch(err=>{
        console.log(err)
    })     
 } 
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 20px;
    margin-left: 30px;
    font-size: 16px;
}
.ranking{
  margin: 20px;
}
</style>