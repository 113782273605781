<template>
 <div class="">
    <article>
      <section>
        <p class="paragraph">边缘企业和杠杆收购分析的方法来自《华尔街证券分析：股票分析与公司估值》一书。</p>
        <p class="paragraph">许多投资人对边缘企业（亏损公司或问题公司）的第一反应就是避而远之，亏损公司难以估值，盈利同样难以预测，因此投资机构为这类公司支付过高的风险也会被放大，造成此类公司甚少有人关注。尽管有上述提到的种种困难因素，仍有大量的业绩欠佳的边缘企业拥有重大的股权价值，存在价格的洼地。边缘企业业绩欠佳的原因可能有：</p>
        <p class="paragraph">1、一次性的亏损</p>
        <p class="paragraph">2、一次性的非持续业务被剥离出售</p>
        <p class="paragraph">3、行业周期影响</p>
        <p class="paragraph">4、管理层不称职，缺乏有效的成本控制手段，没有预见到科技、时尚走势或是竞争对手的挑战</p>
        <p class="paragraph">5、不成功的兼并行为</p>
        <p class="paragraph">6、法律诉讼</p>
        <p class="paragraph">7、业务经营正常，销售收入稳定，但高杠杆率导致利息支出过高</p>
        <p class="paragraph">历史经验证明，边缘企业有可能提供超额的投资回报，前提是对于相关的投资机会要进行彻底的调查，边缘企业的一系列不确定需要有高的预期收益率加以补偿。</p>
        <p class="paragraph">边缘公司的估值过程强调以下4个方面:</p>
        <p class="paragraph">1、可持续经营的业务</p>
        <p class="paragraph">2、局势扭转的可能性。<b>目前经营的问题是什么？未来有什么确定的条件扭转？</b></p>
        <p class="paragraph">3、估值。投资者的买入价格相对较低，于是投资失败的风险于成功的可能性二者之间就平衡得较好</p>
        <p class="paragraph">4、时间上的考虑。企业复苏存在一个过程，存在失败的可能。年化的收益率目标不能低于20%</p>
        <p class="paragraph">我们对边缘企业使用了以下三种估值方法：</p>
        <p class="paragraph">1、年度净利润（TTM）为负、EBITDA为正，EQUITY为正</p>
        <p class="paragraph">（1）计算EV/EBITDA和MV/EQUITY</p>
        <p class="paragraph">（2）EBITDA(TTM)乘以6得出粗略的公司企业价值，减去带息债务并加上账面现金，得出最终的权益价值再和当前市场价格比较</p>
        <p class="paragraph">2、年度净利润（TTM）为负、EBITDA为正，EQUITY为负</p>
        <p class="paragraph">（1）计算EV/EBITDA</p>
        <p class="paragraph">（2）EBITDA(TTM)乘以6得出粗略的公司企业价值，减去带息债务并加上账面现金，得出最终的权益价值再和当前市场价格比较</p>  
        <p class="paragraph">3、年度净利润（TTM）为负、EBITDA为负</p>
        <p class="paragraph">（1）计算同行业可比上市公司（EBITDA为正）的“企业价值/销售收入比率”，再对这个比率打上一个30%折扣</p>          
        <p class="paragraph">（2）计算出边缘公司的企业价值，减去带息债务并加上账面现金，得出最终的权益价值再和当前市场价格比较</p>
        <p class="paragraph">注意小型公司的折扣幅度是大型公司的估值乘数的30%。</p>
        <br>
        <p class="paragraph">杠杆收购分析是另一种对正常运营企业的估值分析方法，可以作为对其他估值方法的有效补充。这个方法就是假设有私募股权公司考虑收购一个企业，该企业应该具有什么特征，以及私募投资人应该支付多少价格。通常私募投资人需要通过杠杆收购（LBO），自己出一部分钱，再向银行或者大机构借一笔钱。在寻求收购对象时，需要对方具备以下关键特征：</p>
        <p class="paragraph">1、科技含量低</p>
        <p class="paragraph">2、稳固的过往业绩</p>
        <p class="paragraph">3、具备大量实物资产</p>
        <p class="paragraph">4、原有负债率低</p>
        <p class="paragraph">杠杆收购（LBO）的三个原则是：</p>
        <p class="paragraph">1、用别人的钱。在交易中尽可能多地运用杠杆手段，以便提高未来的权益回报</p>
        <p class="paragraph">2、正确的收购。寻找那些能够以相对较低的估值乘数进行收购的公司</p>
        <p class="paragraph">3、提高运营业绩。转变管理层的定位，使他们象所有者而不是雇员那样开展工作</p>
        <p class="paragraph">我们对杠杆收购企业使用了如下估值方法：</p>
        <p class="paragraph">1、筛选条件</p>
        <p class="paragraph">（1）500亿市值以下</p>
        <p class="paragraph">（2）低到中等科技含量</p>
        <p class="paragraph">（3）具有稳定过往业绩，持续盈利历史（三年利润增速年化为正）</p>
        <p class="paragraph">（4）杠杆率较低（权益：债务不低于65：35）</p>  
        <p class="paragraph">2、估值计算方法</p>
        <p class="paragraph">（1）计算利息。息税前利润/利息费用比率为1.3，计算出杠杆负债的利息</p>
        <p class="paragraph">（2）计算杠杆负债。通过利息计算杠杆债务金额，利率采用“无风险利率的一倍”</p>
        <p class="paragraph">（3）计算企业价值。收购使用的自有资金加上债务（杠杆收购比率为25：75），得出收购评估的企业价值</p>
        <p class="paragraph">（4）计算股权价值。企业价值减去带息负债加上账面现金，得出权益收购价值。如果当前市场交易价格低于杠杆收购价值的75%，则表示收购对象具有投资价值</p>  
        <p class="paragraph">本网站的边缘企业和杠杆收购分析方法目前仅提供计算后的公司排序，分别列出估值最低的前20家公司，需要各位投资人结合其他分析工具，比如“可比性分析”和“财务特征分析”进行综合判断，才能得到一个全方位的企业估值视图。</p>
                               

     </section>
    </article>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "EL_Summary",
 data() {
   return {}
 },
 components:{}
}
</script>

<style scoped lang='scss'>
.paragraph{
  text-indent: 2em;
  margin:5px 5px;
}
</style>