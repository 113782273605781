<template>
 <div class="container" v-if="Object.keys(analysisResult).length > 0">
    <p class="title">权益/债券估值法</p>
    <div>
        <span>（1）根据过去十年的权益数据进行预测</span>
        <p>1、过去10年的每股股东权益、每股利润、每股留存收益和派发股息的数据：</p>
        <el-table
            :data="analysisResult.ten_year_data"
            style="width: 1081px"
            border>
            <el-table-column
                prop="time"
                label="年份"
                width="120"
                :formatter="timeFormat">
            </el-table-column>
            <el-table-column
                prop="equity"
                label="每股股东权益"
                width="120"
                :formatter="dataDivide">
            </el-table-column>
            <el-table-column
                prop="net_profit"
                label="每股净利润"
                width="120"
                :formatter="dataDivide">  
            </el-table-column>                            
            <el-table-column
                prop="eps"
                label="每股归母净利润"
                width="120">
                </el-table-column>
            <el-table-column
                prop="cumulative_dividend"
                label="每股派发股息"
                width="120"
                :formatter="dataDivide">
            </el-table-column>
            <el-table-column
                prop="retained_income"
                label="每股留存收益"
                width="120"
                :formatter="dataDivide">
            </el-table-column> 
            <el-table-column
                prop="ROE"
                label="权益回报率（全面摊薄ROE）"
                width="120"
                :formatter="dataPercentFormat">
            </el-table-column> 
            <el-table-column
                prop="dividend_yield"
                label="分红率"
                width="120"
                :formatter="dataPercentFormat">
            </el-table-column>
            <el-table-column
                prop="retained_yield"
                label="留存收益率"
                width="120"
                :formatter="dataPercentFormat">
            </el-table-column>                                                                               
        </el-table>     
        <p>2、过去10年平均的股东权益回报率为{{roe_avg}}，平均留存收益率为{{retained_yield_avg}},平均派发股息率为{{dividend_yield_avg}}。</p>
        <p>3、基于平均的股东权益回报率和留存收益率，过去十年的股东权益年增长率为{{toPercent(analysisResult.last_year[0].Ten_Year_Equity_Annual_Growth_Rate)}}。</p>
        <p>4、估算未来10年的每股股东权益，根据平均的股东权益回报率预测未来10年的每股净利润：</p>
        <el-table
            :data="analysisResult.ten_year_equity_assessment.slice(1,Object.keys(analysisResult.ten_year_equity_assessment).length)"
            style="width: 601px"
            border>
            <el-table-column
                prop="index"
                label="年份"
                width="120"
                :formatter="timeFormat">
            </el-table-column>
            <el-table-column
                prop="naps"
                label="每股股东权益"
                width="120"
                :formatter="datafix">
            </el-table-column>
            <el-table-column
                prop="eps"
                label="每股净利润"
                width="120"
                :formatter="datafix">
            </el-table-column> 
            <el-table-column
                prop="dividend"
                label="每股派发股息"
                width="120"
                :formatter="datafix">
            </el-table-column> 
            <el-table-column
                prop="retained"
                label="每股留存利润"
                width="120"
                :formatter="datafix">
            </el-table-column> 
        </el-table>
        <p>5、根据过去10年的平均市盈率和10年后的每股净利润，估算10年后的每股价格，根据现价、10年后的每股价格加10年股息后的价格，计算年复合收益率</p>
        <p>过去10年的平均市盈率为{{(analysisResult.last_year[0].Ten_Year_Avg_PE).toFixed(2)}}，预测10年后的每股价格为{{(analysisResult.last_year[0].After_10Y_Price_Equity_Law).toFixed(2)}}。根据昨日价格{{analysisResult.last_day[0].price}}买入，预计10年年复合投资收益率为<b>{{analysisResult.last_day[0].after_10Y_compound_annual_return_rate_EA}}%</b>。</p>                 
    </div>
    <br>
    <div>
        <span>（2）根据过去五年的权益数据进行预测</span>
        <p>1、过去5年的每股股东权益、每股利润、每股留存收益和派发股息的数据：</p>
        <el-table
            :data="analysisResult.ten_year_data.slice(0,6)"
            style="width: 1081px"
            border>
            <el-table-column
                prop="time"
                label="年份"
                width="120"
                :formatter="timeFormat">
            </el-table-column>
            <el-table-column
                prop="equity"
                label="每股股东权益"
                width="120"
                :formatter="dataDivide">
            </el-table-column>
            <el-table-column
                prop="net_profit"
                label="每股净利润"
                width="120"
                :formatter="dataDivide">  
            </el-table-column>                            
            <el-table-column
                prop="eps"
                label="每股归母净利润"
                width="120">
                </el-table-column>
            <el-table-column
                prop="cumulative_dividend"
                label="每股派发股息"
                width="120"
                :formatter="dataDivide">
            </el-table-column>
            <el-table-column
                prop="retained_income"
                label="每股留存收益"
                width="120"
                :formatter="dataDivide">
            </el-table-column> 
            <el-table-column
                prop="ROE"
                label="权益回报率（全面摊薄ROE）"
                width="120"
                :formatter="dataPercentFormat">
            </el-table-column> 
            <el-table-column
                prop="dividend_yield"
                label="分红率"
                width="120"
                :formatter="dataPercentFormat">
            </el-table-column>
                <el-table-column
                prop="retained_yield"
                label="留存收益率"
                width="120"
                :formatter="dataPercentFormat">
            </el-table-column>                                                                               
        </el-table>     
        <p>2、过去5年平均的股东权益回报率为{{toPercent(analysisResult.last_year[0].Five_Year_Avg_ROE)}}，平均留存收益率为{{toPercent(1-analysisResult.last_year[0].Five_Year_Avg_Dividend_Yield)}},平均派发股息率为{{toPercent(analysisResult.last_year[0].Five_Year_Avg_Dividend_Yield)}}。</p>
        <p>3、基于平均的股东权益回报率和留存收益率，过去五年的股东权益年增长率为{{toPercent(analysisResult.last_year[0].Five_Year_Equity_Annual_Growth_Rate)}}。</p>
        <p>4、估算未来5年的每股股东权益，根据平均的股东权益回报率预测未来5年的每股净利润：</p>
        <el-table
            :data="analysisResult.five_year_equity_assessment.slice(1,Object.keys(analysisResult.five_year_equity_assessment).length)"
            style="width: 601px"
            border>
            <el-table-column
                prop="index"
                label="年份"
                width="120"
                :formatter="timeFormat">
            </el-table-column>
            <el-table-column
                prop="naps"
                label="每股股东权益"
                width="120"
                :formatter="datafix">
            </el-table-column>
            <el-table-column
                prop="eps"
                label="每股净利润"
                width="120"
                :formatter="datafix">
            </el-table-column> 
            <el-table-column
                prop="dividend"
                label="每股派发股息"
                width="120"
                :formatter="datafix">
            </el-table-column> 
            <el-table-column
                prop="retained"
                label="每股留存利润"
                width="120"
                :formatter="datafix">
            </el-table-column> 
        </el-table>
        <p>5、根据过去5年的平均市盈率和5年后的每股净利润，估算5年后的每股价格，根据现价、5年后的每股价格加5年股息后的价格，计算年复合收益率</p>
        <p>过去5年的平均市盈率为{{(analysisResult.last_year[0].Five_Year_Avg_PE).toFixed(2)}}，预测5年后的每股价格为{{(analysisResult.last_year[0].After_5Y_Price_Equity_Law).toFixed(2)}}。根据昨日价格{{analysisResult.last_day[0].price}}，预计5年年复合收益率为<b>{{analysisResult.last_day[0].after_5Y_compound_annual_return_rate_EA}}%</b>。</p>                 
    </div>
 
 </div>
</template>

<script type="text/ecmascript-6">
import {Table,TableColumn} from 'element-ui'
export default {
  name: "First_Math_Tool",
  data() {
    return {
      analysisResult:{},  
      roe_avg:0,
      roe_std:0,
      retained_yield_avg:0,
      dividend_yield_avg:0,
    }
  },
  methods:{
    roe_avg_std_compute(){
      this.roe_avg = this.toPercent(this.analysisResult.last_year[0].Ten_Year_Avg_ROE)
      this.roe_std = this.analysisResult.last_year[0].Ten_Year_ROE_Std.toFixed(2)
      this.dividend_yield_avg = this.toPercent(this.analysisResult.last_year[0].Ten_Year_Avg_Dividend_Yield)
      this.retained_yield_avg = this.toPercent(1-this.analysisResult.last_year[0].Ten_Year_Avg_Dividend_Yield)
    },
    toPercent(point){
      var str=Number(point*100).toFixed(2);
      str+="%";
      return str;
    },
    timeFormat(row, column, cellValue) {
      return cellValue.slice(0,4)
    },
    dataPercentFormat(row, column, cellValue) {
      return this.toPercent(cellValue)
    },
    dataDivide(row,column,cellValue,index){
      return (cellValue/this.analysisResult.ten_year_data[index].capital).toFixed(2)
    },
    datafix(row,column,cellValue){
      return (cellValue).toFixed(2)
    }           
  },
  components:{
    [Table.name]:Table,
    [TableColumn.name]:TableColumn        
  },
  mounted(){
    this.analysisResult = this.$store.state.tsa_result
  },
  updated(){
    if(Object.keys(this.analysisResult.last_year).length>0 && Object.keys(this.analysisResult.ten_year_data).length>0 && Object.keys(this.analysisResult.last_day).length>0){
      this.roe_avg_std_compute()
    }
  }    
}
</script>

<style scoped lang='scss'>
.container{
  span{
    font-size: 16px;
  }
  p{
    font-family: "宋体";
    margin: 10px;
  }
  .title{
    font-family: "微软雅黑";
    margin: 10px 10px;
  }
}
</style>