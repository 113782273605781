import Vue from "vue";
import Vuex from 'vuex';

Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
        searchsymbol:'',
        tsa_result:{},
        ffa_result:{},
        reverse_symbol:'',
        bc_result:{},
        searchIndusrty:'',
        bi_result:{}
    }
})

export default store