<template>
 <div v-if="industry.length > 0">
   <div class="inputform">
      <el-form ref="form" label-width="150px">
             <el-form-item label="请输入或选择行业:">
                <el-autocomplete
                popper-class="my-autocomplete"
                v-model="symbol"
                :fetch-suggestions="querySearch"
                :debounce="500"
                @select="handleSelect"
                >
                <template slot-scope="{ item }">
                    <div class="name">{{item.index_code}}&nbsp;&nbsp;&nbsp;&nbsp;{{item.index_name}}</div>
                </template>  
                </el-autocomplete>
                <el-button type="primary" @click="handleClick">查询</el-button>   
            </el-form-item>
       </el-form>  
    </div>
    <div v-if="Object.keys(analysisResult).length > 0" class="container">
        <el-table
            :data="analysisResult"
            style="width: 4000px"
            border>
            <el-table-column
            prop="id"
            label="序号"
            width="50"
            >
              <template slot-scope="scope">{{scope.$index + 1}}</template>
            </el-table-column>
            <el-table-column
            prop="symbol"
            label="公司代码"
            width="100">
            </el-table-column>
            <el-table-column
            prop="name"
            label="公司名称"
            width="100">
            </el-table-column>
            <el-table-column
            prop="time_to_market"
            label="上市时间"
            width="100">
            </el-table-column>
            <el-table-column
            prop="market"
            label="市场"
            width="100">
            </el-table-column>
            <el-table-column
            prop="mv"
            label="市值"
            width="200"
            sortable
            :formatter="toNumber">
            </el-table-column>     
            <el-table-column
            prop="capital"
            label="股本"
            width="150">
            </el-table-column>   
            <el-table-column
            prop="stock_key_info_summary[0].industry_name"
            label="申万二级行业"
            width="150">
            </el-table-column>   
            <el-table-column
            prop="stock_key_info_summary[0].industry_PE"
            label="申万二级行业PE"
            width="150">
            </el-table-column>   
            <el-table-column
            prop="stock_key_info_summary[0].industry_roe_curr"
            label="申万二级行业ROE"
            width="150">
            </el-table-column>  
            <el-table-column
            prop="stock_key_info_summary[0].PE_TTM"
            label="PE（TTM）"
            width="150"
            sortable
            :sort-method="customSortMethodA">
            </el-table-column>              
            <el-table-column
            prop="stock_key_info_summary[0].stock_PE_TTM_devide_industry_PE"
            label="公司PE除以行业PE"
            width="180"
            sortable
            :sort-method="customSortMethodB">
            </el-table-column>  
            <el-table-column
            prop="stock_key_info_summary[0].roe_last_year"
            label="过去一年的ROE"
            width="200"
            sortable
            :formatter="toNumber"
            :sort-method="customSortMethodC">
            </el-table-column>  
            <el-table-column
            prop="stock_key_info_summary[0].ten_year_roe_avg"
            label="十年平均ROE"
            width="180"
            sortable
            :sort-method="customSortMethodD">
            </el-table-column>              
            <el-table-column
            prop="stock_key_info_summary[0].revenue_last_year"
            label="过去一年的营收"
            width="200"
            sortable
            :sort-method="customSortMethodE">
            </el-table-column>     
            <el-table-column
            prop="stock_key_info_summary[0].revenue_industry_ratio"
            label="营收占行业的比率"
            width="180"
            sortable
            :sort-method="customSortMethodF">
            </el-table-column>              
            <el-table-column
            prop="stock_key_info_summary[0].revenue_industry_ratio_annual_growth"
            label="营收占行业的比率的年化增长率"
            width="180"
            sortable
            :sort-method="customSortMethodG">
            </el-table-column>
            <el-table-column
            prop="stock_key_info_summary[0].ten_year_revenue_avg"
            label="十年平均营收"
            width="200"
            sortable
            :sort-method="customSortMethodH">
            </el-table-column>
            <el-table-column
            prop="stock_key_info_summary[0].ten_year_revenue_YOY"
            label="十年营收年化增长率"
            width="180"
            sortable
            :sort-method="customSortMethodI">
            </el-table-column>  
            <el-table-column
            prop="stock_key_info_summary[0].gross_margin_annual_growth"
            label="十年毛利率年化增长率"
            width="150"
            sortable
            :sort-method="customSortMethodJ">
            </el-table-column>                
            <el-table-column
            prop="stock_key_info_summary[0].profit_margin_last_year"
            label="过去一年的净利率"
            width="150"
            sortable
            :sort-method="customSortMethodK">
            </el-table-column>                        
            <el-table-column
            prop="stock_key_info_summary[0].profit_margin_avg"
            label="十年平均净利率"
            width="150"
            sortable
            :sort-method="customSortMethodL">
            </el-table-column>   
            <el-table-column
            prop="stock_key_info_summary[0].account_receivable_ratio"
            label="应收账款占比"
            width="150"
            sortable
            :sort-method="customSortMethodM">
            </el-table-column>  
            <el-table-column
            prop="stock_key_info_summary[0].deposit_received_ratio"
            label="预收账款占比"
            width="150"
            sortable
            :sort-method="customSortMethodN">
            </el-table-column>               
            <el-table-column
            prop="stock_key_info_summary[0].capital_expenditure_ratio"
            label="资本开支占比"
            width="150"
            sortable
            :sort-method="customSortMethodO">
            </el-table-column> 
            <el-table-column
            prop="stock_key_info_summary[0].account_receivable_ratio"
            label="固定资产占比"
            width="150"
            sortable
            :sort-method="customSortMethodP">
            </el-table-column> 
            <el-table-column
            prop="stock_key_info_summary[0].intangible_assets_ratio"
            label="无形资产占比"
            width="150"
            sortable
            :sort-method="customSortMethodQ">
            </el-table-column> 
            <el-table-column
            prop="stock_key_info_summary[0].capital_expenditure_change_rate_last_year"
            label="过去一年资本开支变化率"
            width="150"
            sortable
            :sort-method="customSortMethodR">
            </el-table-column> 
            <el-table-column
            prop="stock_key_info_summary[0].cash_flow_cycle"
            label="现金周转率"
            width="150"
            sortable
            :sort-method="customSortMethodS">
            </el-table-column>            
             <el-table-column
            prop="stock_key_info_summary[0].equity_multiplier_last_year"
            label="过去一年的权益乘数"
            width="150"
            sortable
            :sort-method="customSortMethodT">
            </el-table-column>
             <el-table-column
            prop="stock_key_info_summary[0].equity_multiplier_avg"
            label="十年平均权益乘数"
            width="150"
            sortable
            :sort-method="customSortMethodU">
            </el-table-column>     
             <el-table-column
            prop="stock_key_info_summary[0].total_capital_turnover_last_year"
            label="过去一年的总资产周转率"
            width="150"
            sortable
            :sort-method="customSortMethodV">
            </el-table-column>           
             <el-table-column
            prop="stock_key_info_summary[0].total_capital_turnover_avg"
            label="十年平均总资产周转率"
            width="150"
            sortable
            :sort-method="customSortMethodW">
            </el-table-column>
             <el-table-column
            prop="stock_key_info_summary[0].net_current_assets_per_share_last_year"
            label="过去一年的每股流动资产净值"
            width="150"
            sortable
            :sort-method="customSortMethodX">
            </el-table-column> 
             <el-table-column
            prop="stock_key_info_summary[0].net_current_assets_per_share_YOY"
            label="每股流动资产净值年化增长率"
            width="150"
            sortable
            :sort-method="customSortMethodY">
            </el-table-column>             
             <el-table-column
            prop="stock_key_info_summary[0].ev_divide_ebit_last_year"
            label="过去一年的EV/EBIT"
            width="150"
            sortable
            :sort-method="customSortMethodZ">
            </el-table-column>    
             <el-table-column
            prop="stock_key_info_summary[0].ev_divide_ebit_avg"
            label="十年平均EV/EBIT"
            width="150"
            sortable
            :sort-method="customSortMethod1">
            </el-table-column>    
             <el-table-column
            prop="stock_key_info_summary[0].ev_divide_ebit_change_rate_last_year"
            label="过去一年的EV/EBIT变化率"
            width="150"
            sortable
            :sort-method="customSortMethod2">
            </el-table-column>              
             <el-table-column
            prop="stock_key_info_summary[0].ev_divide_fcff_last_year"
            label="过去一年的EV/FCFF"
            width="150"
            sortable
            :sort-method="customSortMethod3">
            </el-table-column> 
             <el-table-column
            prop="stock_key_info_summary[0].ev_divide_fcff_avg"
            label="十年平均EV/FCFF"
            width="150"
            sortable
            :sort-method="customSortMethod4">
            </el-table-column> 
             <el-table-column
            prop="stock_key_info_summary[0].ev_divide_fcff_change_rate_last_year"
            label="过去一年的EV/FCFF变化率"
            width="150"
            sortable
            :sort-method="customSortMethod5">
            </el-table-column>             
             <el-table-column
            prop="stock_key_info_summary[0].mv_divide_discount_10y_FCFE"
            label="市值除以按30%年化增长的FCFE的十年折现值"
            width="150"
            sortable
            :sort-method="customSortMethod6">
            </el-table-column>  
             <el-table-column
            prop="stock_key_info_summary[0].mv_divide_segment_aggregate_market_value"
            label="市值除以分部估值法的汇总值"
            width="150"
            sortable
            :sort-method="customSortMethod7">
            </el-table-column>              
        </el-table>

    </div>
 </div>
</template>

<script type="text/ecmascript-6">
import {Form, FormItem,Autocomplete,Button,Table,TableColumn} from "element-ui"
export default {
 name: "Indicator_Component",
 data() {
   return {
     industry: [],  
     symbol:'', 
     analysisResult:[],
     company:[]    
   }
 },
 methods:{
   querySearch(queryString, cb) {
     var industry = this.industry
     var results = queryString ? industry.filter(inputsymbol=>(inputsymbol.index_code.indexOf(queryString) === 0 || inputsymbol.index_name.indexOf(queryString) != -1)) : industry;
      // 调用 callback 返回建议列表的数据
     cb(results)
   },    
   handleClick(){
     if(this.symbol != ''){
      this.analysisResult = []
      this.company = []
      var index = this.industry.filter(inputsymbol=>inputsymbol.index_code.indexOf(this.symbol) === 0)
      var industry_component = index[0].industry_component
      for(var i=0;i<Object.keys(industry_component).length;i++){
        this.company.splice(100,0,industry_component[i].company_code)
      }
      var company = JSON.stringify({companys:this.company.sort()})
      let params = new URLSearchParams()
      params.append('company',company)      
      this.$http.industry_stock_info_detail(params).then(res=>{
        this.analysisResult = res.data
        console.log(res.data)
      }).catch(err=>{
        console.log(err)
      }) 

     }
   },
   handleSelect(item){
     this.symbol= item.index_code
   },
   toNumber(row,column,cellValue){
    return cellValue == null ? 0 : parseFloat(cellValue).toFixed(2)
   },
   customSortMethodA(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].PE_TTM===null?'0.00':a.stock_key_info_summary[0].PE_TTM).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].PE_TTM===null?'0.00':b.stock_key_info_summary[0].PE_TTM).toFixed(2);
     return numA - numB
   },
   customSortMethodB(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].stock_PE_TTM_devide_industry_PE===null?'0.00':a.stock_key_info_summary[0].stock_PE_TTM_devide_industry_PE).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].stock_PE_TTM_devide_industry_PE===null?'0.00':b.stock_key_info_summary[0].stock_PE_TTM_devide_industry_PE).toFixed(2);
     return numA - numB
   },
   customSortMethodC(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].roe_last_year===null?'0.00':a.stock_key_info_summary[0].roe_last_year).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].roe_last_year===null?'0.00':b.stock_key_info_summary[0].roe_last_year).toFixed(2);
     return numA - numB
   },
   customSortMethodD(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].ten_year_roe_avg===null?'0.00':a.stock_key_info_summary[0].ten_year_roe_avg).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].ten_year_roe_avg===null?'0.00':b.stock_key_info_summary[0].ten_year_roe_avg).toFixed(2);
     return numA - numB
   },  
   customSortMethodE(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].revenue_last_year===null?'0.00':a.stock_key_info_summary[0].revenue_last_year).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].revenue_last_year===null?'0.00':b.stock_key_info_summary[0].revenue_last_year).toFixed(2);
     return numA - numB
   },     
   customSortMethodF(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].revenue_industry_ratio===null?'0.00':a.stock_key_info_summary[0].revenue_industry_ratio).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].revenue_industry_ratio===null?'0.00':b.stock_key_info_summary[0].revenue_industry_ratio).toFixed(2);
     return numA - numB
   }, 
   customSortMethodG(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].revenue_industry_ratio_annual_growth===null?'0.00':a.stock_key_info_summary[0].revenue_industry_ratio_annual_growth).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].revenue_industry_ratio_annual_growth===null?'0.00':b.stock_key_info_summary[0].revenue_industry_ratio_annual_growth).toFixed(2);
     return numA - numB
   },
   customSortMethodH(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].ten_year_revenue_avg===null?'0.00':a.stock_key_info_summary[0].ten_year_revenue_avg).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].ten_year_revenue_avg===null?'0.00':b.stock_key_info_summary[0].ten_year_revenue_avg).toFixed(2);
     return numA - numB
   },  
   customSortMethodI(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].ten_year_revenue_YOY===null?'0.00':a.stock_key_info_summary[0].ten_year_revenue_YOY).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].ten_year_revenue_YOY===null?'0.00':b.stock_key_info_summary[0].ten_year_revenue_YOY).toFixed(2);
     return numA - numB
   },
   customSortMethodJ(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].gross_margin_annual_growth===null?'0.00':a.stock_key_info_summary[0].gross_margin_annual_growth).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].gross_margin_annual_growth===null?'0.00':b.stock_key_info_summary[0].gross_margin_annual_growth).toFixed(2);
     return numA - numB
   }, 
   customSortMethod7(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].mv_divide_segment_aggregate_market_value===null?'0.00':a.stock_key_info_summary[0].mv_divide_segment_aggregate_market_value).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].mv_divide_segment_aggregate_market_value===null?'0.00':b.stock_key_info_summary[0].mv_divide_segment_aggregate_market_value).toFixed(2);
     return numA - numB
   }, 
   customSortMethod6(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].mv_divide_discount_10y_FCFE===null?'0.00':a.stock_key_info_summary[0].mv_divide_discount_10y_FCFE).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].mv_divide_discount_10y_FCFE===null?'0.00':b.stock_key_info_summary[0].mv_divide_discount_10y_FCFE).toFixed(2);
     return numA - numB
   }, 
   customSortMethod5(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].ev_divide_fcff_change_rate_last_year===null?'0.00':a.stock_key_info_summary[0].ev_divide_fcff_change_rate_last_year).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].ev_divide_fcff_change_rate_last_year===null?'0.00':b.stock_key_info_summary[0].ev_divide_fcff_change_rate_last_year).toFixed(2);
     return numA - numB
   }, 
   customSortMethod4(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].ev_divide_fcff_avg===null?'0.00':a.stock_key_info_summary[0].ev_divide_fcff_avg).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].ev_divide_fcff_avg===null?'0.00':b.stock_key_info_summary[0].ev_divide_fcff_avg).toFixed(2);
     return numA - numB
   }, 
   customSortMethod3(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].ev_divide_fcff_last_year===null?'0.00':a.stock_key_info_summary[0].ev_divide_fcff_last_year).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].ev_divide_fcff_last_year===null?'0.00':b.stock_key_info_summary[0].ev_divide_fcff_last_year).toFixed(2);
     return numA - numB
   }, 
   customSortMethod2(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].ev_divide_ebit_change_rate_last_year===null?'0.00':a.stock_key_info_summary[0].ev_divide_ebit_change_rate_last_year).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].ev_divide_ebit_change_rate_last_year===null?'0.00':b.stock_key_info_summary[0].ev_divide_ebit_change_rate_last_year).toFixed(2);
     return numA - numB
   }, 
   customSortMethod1(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].ev_divide_ebit_avg===null?'0.00':a.stock_key_info_summary[0].ev_divide_ebit_avg).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].ev_divide_ebit_avg===null?'0.00':b.stock_key_info_summary[0].ev_divide_ebit_avg).toFixed(2);
     return numA - numB
   }, 
   customSortMethodZ(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].ev_divide_ebit_last_year===null?'0.00':a.stock_key_info_summary[0].ev_divide_ebit_last_year).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].ev_divide_ebit_last_year===null?'0.00':b.stock_key_info_summary[0].ev_divide_ebit_last_year).toFixed(2);
     return numA - numB
   }, 
   customSortMethodY(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].net_current_assets_per_share_YOY===null?'0.00':a.stock_key_info_summary[0].net_current_assets_per_share_YOY).toFixed(2)
     var numB = parseFloat(b.stock_key_info_summary[0].net_current_assets_per_share_YOY===null?'0.00':b.stock_key_info_summary[0].net_current_assets_per_share_YOY).toFixed(2)
     return numA - numB
   },    
   customSortMethodX(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].net_current_assets_per_share_last_year===null?'0.00':a.stock_key_info_summary[0].net_current_assets_per_share_last_year).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].net_current_assets_per_share_last_year===null?'0.00':b.stock_key_info_summary[0].net_current_assets_per_share_last_year).toFixed(2);
     return numA - numB
   },
   customSortMethodW(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].total_capital_turnover_avg===null?'0.00':a.stock_key_info_summary[0].total_capital_turnover_avg).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].total_capital_turnover_avg===null?'0.00':b.stock_key_info_summary[0].total_capital_turnover_avg).toFixed(2);
     return numA - numB
   },
   customSortMethodV(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].total_capital_turnover_last_year===null?'0.00':a.stock_key_info_summary[0].total_capital_turnover_last_year).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].total_capital_turnover_last_year===null?'0.00':b.stock_key_info_summary[0].total_capital_turnover_last_year).toFixed(2);
     return numA - numB
   },
   customSortMethodU(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].equity_multiplier_avg===null?'0.00':a.stock_key_info_summary[0].equity_multiplier_avg).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].equity_multiplier_avg===null?'0.00':b.stock_key_info_summary[0].equity_multiplier_avg).toFixed(2);
     return numA - numB
   },   
   customSortMethodT(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].equity_multiplier_last_year===null?'0.00':a.stock_key_info_summary[0].equity_multiplier_last_year).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].equity_multiplier_last_year===null?'0.00':b.stock_key_info_summary[0].equity_multiplier_last_year).toFixed(2);
     return numA - numB
   }, 
   customSortMethodS(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].cash_flow_cycle===null?'0.00':a.stock_key_info_summary[0].cash_flow_cycle).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].cash_flow_cycle===null?'0.00':b.stock_key_info_summary[0].cash_flow_cycle).toFixed(2);
     return numA - numB
   },       
   customSortMethodR(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].capital_expenditure_change_rate_last_year===null?'0.00':a.stock_key_info_summary[0].capital_expenditure_change_rate_last_year).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].capital_expenditure_change_rate_last_year===null?'0.00':b.stock_key_info_summary[0].capital_expenditure_change_rate_last_year).toFixed(2);
     return numA - numB
   },     
   customSortMethodQ(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].intangible_assets_ratio===null?'0.00':a.stock_key_info_summary[0].intangible_assets_ratio).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].intangible_assets_ratio===null?'0.00':b.stock_key_info_summary[0].intangible_assets_ratio).toFixed(2);
     return numA - numB
   },     
   customSortMethodP(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].account_receivable_ratio===null?'0.00':a.stock_key_info_summary[0].account_receivable_ratio).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].account_receivable_ratio===null?'0.00':b.stock_key_info_summary[0].account_receivable_ratio).toFixed(2);
     return numA - numB
   },    
   customSortMethodO(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].capital_expenditure_ratio===null?'0.00':a.stock_key_info_summary[0].capital_expenditure_ratio).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].capital_expenditure_ratio===null?'0.00':b.stock_key_info_summary[0].capital_expenditure_ratio).toFixed(2);
     return numA - numB
   },      
   customSortMethodN(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].deposit_received_ratio===null?'0.00':a.stock_key_info_summary[0].deposit_received_ratio).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].deposit_received_ratio===null?'0.00':b.stock_key_info_summary[0].deposit_received_ratio).toFixed(2);
     return numA - numB
   },     
   customSortMethodM(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].account_receivable_ratio===null?'0.00':a.stock_key_info_summary[0].account_receivable_ratio).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].account_receivable_ratio===null?'0.00':b.stock_key_info_summary[0].account_receivable_ratio).toFixed(2);
     return numA - numB
   },     
   customSortMethodL(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].profit_margin_avg===null?'0.00':a.stock_key_info_summary[0].profit_margin_avg).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].profit_margin_avg===null?'0.00':b.stock_key_info_summary[0].profit_margin_avg).toFixed(2);
     return numA - numB
   },     
   customSortMethodK(a,b){
     var numA = parseFloat(a.stock_key_info_summary[0].profit_margin_last_year===null?'0.00':a.stock_key_info_summary[0].profit_margin_last_year).toFixed(2);
     var numB = parseFloat(b.stock_key_info_summary[0].profit_margin_last_year===null?'0.00':b.stock_key_info_summary[0].profit_margin_last_year).toFixed(2);
     return numA - numB
   }  
 },
 components:{
    [Autocomplete.name]: Autocomplete,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Table.name]:Table,
    [TableColumn.name]:TableColumn    
 },
 mounted(){
   this.$http.searchIndustry().then(res=>{
     this.industry = res.data
   }).catch(err=>{
        console.log(err)
   })    
 } 
}
</script>

<style scoped lang='scss'>
.container{
  margin: 20px;
  margin-left: 30px;
  font-size: 16px;
}
</style>