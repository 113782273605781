<template>
 <div class="container" v-if="Object.keys(rankingData).length > 0">
   <ol class="">
     <li>行业内的公司股价离最高点的跌幅比例排行榜</li>
       <ul style="list-style-type:disc" class="ranking">
         <li>跌30%的公司的比例排序</li>
            <div>
              <el-table
                  :data="rankingData.bsd30"
                  style="width: 551px"
                  border>
                  <el-table-column
                    prop="id"
                    label="序号"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="industry_code"
                    label="行业代码"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="industry_name"
                    label="行业名称"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="decline30"
                    label="跌30%的公司比例（%）"
                    width="200">
                  </el-table-column>
             
              </el-table>
            </div>    
         <li>跌50%的公司的比例排序</li>
            <div>
              <el-table
                  :data="rankingData.bsd50"
                  style="width: 551px"
                  border>
                  <el-table-column
                    prop="id"
                    label="序号"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="industry_code"
                    label="行业代码"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="industry_name"
                    label="行业名称"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="decline50"
                    label="跌50%的公司比例（%）"
                    width="200">
                  </el-table-column>   
              </el-table>
            </div>                                  
         <li>跌70%的公司的比例排序</li>
            <div>
              <el-table
                  :data="rankingData.bsd70"
                  style="width: 551px"
                  border>
                  <el-table-column
                    prop="id"
                    label="序号"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="industry_code"
                    label="行业代码"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="industry_name"
                    label="行业名称"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="decline70"
                    label="跌70%的公司比例（%）"
                    width="200">
                  </el-table-column>   
              </el-table>
            </div>    
       </ul>
     <li>行业估值排行榜</li>  
       <ul style="list-style-type:disc" class="ranking">
         <li>当前PE对比平均PE排序</li>
            <div>
              <el-table
                  :data="rankingData.icpe"
                  style="width: 721px"
                  border>
                  <el-table-column
                    prop="id"
                    label="序号"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="index_code"
                    label="行业代码"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="index_name"
                    label="行业名称"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="curr_PE"
                    label="当前PE"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="avg_PE"
                    label="平均PE"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="PE_ratio"
                    label="当前PE/平均PE（%）"
                    width="170">
                  </el-table-column>                              
              </el-table>
            </div>    
         <li>当前PB对比平均PB排序</li>
            <div>
              <el-table
                  :data="rankingData.icpb"
                  style="width: 721px"
                  border>
                  <el-table-column
                    prop="id"
                    label="序号"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="index_code"
                    label="行业代码"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="index_name"
                    label="行业名称"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="curr_PB"
                    label="当前PB"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="avg_PB"
                    label="平均PB"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="PB_ratio"
                    label="当前PB/平均PB（%）"
                    width="170">
                  </el-table-column>                              
              </el-table>
            </div>                                  
       </ul>     
   </ol>
 </div>
</template>

<script type="text/ecmascript-6">
import {Table,TableColumn} from 'element-ui'
export default {
 name: "Indicator_Ranking_List",
 data() {
   return {
    rankingData:{}
   }
 },
 components:{
   [Table.name]:Table,
   [TableColumn.name]:TableColumn        
 },
 mounted(){
   this.$http.indicator_ranking_list().then(res=>{
     this.rankingData = res.data
     console.log(res.data);
    }).catch(err=>{
        console.log(err)
    })     
 } 
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 20px;
    margin-left: 30px;
    font-size: 16px;
}
.ranking{
  margin: 20px;
}
</style>