<template>
 <div class="">
   <div v-if="hasResult">
     <ol class="container">
        <li>行业下属的三个龙头公司每季度的总营收对比所有公司总营收</li>
        <div>
          <el-select v-model="RValue" placeholder="请选择" size="mini">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>              
        <div class="proCharts" ref="revenueChart" ></div>
        <li>行业下属的三个龙头公司的平均ROE对比行业ROE</li>
        <div>
          <el-select v-model="ROEValue" placeholder="请选择" size="mini">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>            
        <div class="proCharts" ref="ROEChart" ></div>        
    </ol>   
   </div>
 </div>
</template>

<script type="text/ecmascript-6">
import {Select,Option} from 'element-ui'
export default {
 name: "Concentration_Ratio",
 data() {
   return {
     analysisResult:{},
     hasResult:false,
     RValue:"1",
     ROEValue:"1",
     options: [
       {
        value: '1', 
        label: '季度'
       }, 
       {
        value: '2',
        label: '年度'
        }
     ]          
   }
 },
 methods:{
   revenueChart(){
     var xdata = [],ydata=[],zdata=[],adata=[]
      for(var i=0;i<Object.keys(this.analysisResult.business_financial_indicator).length;i++){
        if(this.RValue == "1"){
          xdata.splice(0,0,this.analysisResult.business_financial_indicator[i].time.slice(0,7))
          ydata.splice(0,0,this.analysisResult.business_financial_indicator[i].revenue_top_three)
          zdata.splice(0,0,this.analysisResult.business_financial_indicator[i].revenue_top_three_proportion)
          adata.splice(0,0,this.analysisResult.business_financial_indicator[i].revenue_top_three_proportion_YOY)
        }
        else{
          if(this.analysisResult.business_financial_indicator[i].time.slice(5,7) == "12"){
            xdata.splice(0,0,this.analysisResult.business_financial_indicator[i].time.slice(0,7))
            ydata.splice(0,0,this.analysisResult.business_financial_indicator[i].revenue_top_three)
            zdata.splice(0,0,this.analysisResult.business_financial_indicator[i].revenue_top_three_proportion)
            adata.splice(0,0,this.analysisResult.business_financial_indicator[i].revenue_top_three_proportion_YOY)            
          }
        }
      }                       
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "值",
              position: "left"
             },
            {
              type: 'value',
              name: "同比（%）",
              position: "right"
             }             
          ],  
          series: [
            {
              name: "三个龙头营收",
              data: ydata,
              yAxisIndex: 0,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "三大龙头营收占比",
              data: zdata,
              yAxisIndex: 1,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },            
            {
              name: "三大龙头营收占比同比",
              data: adata,
              yAxisIndex: 1,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }     
          ]
        }
      let myChart =this.$echarts.init(this.$refs.revenueChart);
      myChart.setOption(option)
   }, 
   ROEChart(){
     var xdata = [],ydata=[],zdata=[]
      for(var i=0;i<Object.keys(this.analysisResult.business_financial_indicator).length;i++){
        if(this.ROEValue == "1"){
          xdata.splice(0,0,this.analysisResult.business_financial_indicator[i].time.slice(0,7))
          ydata.splice(0,0,this.analysisResult.business_financial_indicator[i].avg_ROE_minus_non_recurring_gains_top_three)
          zdata.splice(0,0,this.analysisResult.business_financial_indicator[i].avg_ROE_minus_non_recurring_gains_top_three_YOY)
        }
        else{
          if(this.analysisResult.business_financial_indicator[i].time.slice(5,7) == "12"){
            xdata.splice(0,0,this.analysisResult.business_financial_indicator[i].time.slice(0,7))
            ydata.splice(0,0,this.analysisResult.business_financial_indicator[i].avg_ROE_minus_non_recurring_gains_top_three)
            zdata.splice(0,0,this.analysisResult.business_financial_indicator[i].avg_ROE_minus_non_recurring_gains_top_three_YOY)            
          }
        }
      }              
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "值",
              position: "left"
             },
            {
              type: 'value',
              name: "同比（%）",
              position: "right"
             }             
          ],  
          series: [
            {
              name: "三个龙头平均ROE",
              data: ydata,
              yAxisIndex: 0,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "三个龙头平均ROE同比",
              data: zdata,
              yAxisIndex: 1,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }       
          ]
        }
      let myChart =this.$echarts.init(this.$refs.ROEChart);
      myChart.setOption(option)
   }            
 },  
 updated(){
   this.$nextTick(()=>{
     if(Object.keys(this.analysisResult).length>0){
       this.revenueChart()
       this.ROEChart()
     } 
   })
 },    
 components:{
   [Select.name]:Select,
   [Option.name]:Option     
 },
 mounted(){
   this.analysisResult = this.$store.state.bi_result
   if(this.analysisResult.business_financial_indicator && Object.keys(this.analysisResult.business_financial_indicator).length > 0){
     this.hasResult = true
   }
   else{
    this.hasResult = false
   }
 } 
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 20px;
    margin-left: 30px;
    font-size: 16px;
}
.proCharts{
  width: 100%;
  height: 500px;
  // background: rgb(14, 51, 129);
}
</style>