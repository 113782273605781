<template>
 <div class="">
    <ol class="container">
        <li>公司是否具备持久性竞争优势，是否具有消费垄断能力或者品牌产品？或者公司只是商品类企业或价格竞争型企业？</li>
        <li>公司如何做到消费垄断或品牌粘性？</li>
        <li>公司从事的范围是什么，是否仅投资其专业领域内的企业？</li>
        <li>公司可以根据通货膨胀自由调整产品和服务的价格吗？</li>
    </ol>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "Four_Qualitative_Questions",
 data() {
   return {}
 },
 components:{}
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 10px;
    margin-left: 30px;
    font-size: 16px;
}
</style>