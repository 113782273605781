<template>
 <div class="">
   <ol class="container" v-if="Object.keys(analysisResult).length > 0">
     <li>资本开支</li>
     <div>
       <ul style="list-style-type:disc" class="detail">
         <li>公司过去十年的资本开支</li>
         <div class="proCharts" ref="capitalExpenditureChart" ></div> 
         <li>公司过去十年的资本开支的变化率</li>
         <div class="proCharts" ref="capitalExpenditureChangeRateChart" ></div>      
       </ul>
     </div> 
     <li>每股净运营资产</li>
     <div>
       <ul style="list-style-type:disc" class="detail">
         <li>公司过去一年的每股净运营资产：{{analysisResult.ten_year_other_data[0].net_current_assets_per_share}}</li>
         <li>公司过去十年的每股净运营资产</li>
         <div class="proCharts" ref="netCurrentAssetsPerShareChart" ></div>  
         <li v-if="analysisResult.stock_key_info_summary[0].net_current_assets_per_share_YOY">公司过去十年的每股净运营资产的年化增长率为：{{analysisResult.stock_key_info_summary[0].net_current_assets_per_share_YOY}}%</li>          
       </ul>
     </div>
     <li>EV/EBIT</li>
     <div>
       <ul style="list-style-type:disc" class="detail">
         <li>公司过去十年的EV/EBIT</li>
         <div class="proCharts" ref="evDivideEbitChart" ></div>   
         <li>公司过去十年的EV/EBIT的变化率</li>
         <div class="proCharts" ref="evDivideEbitChangeRateChart" ></div>    
         <li>公司过去十年的平均EV/EBIT为：{{analysisResult.stock_key_info_summary[0].ev_divide_ebit_avg}}</li>        
       </ul>     
     </div>
     <li>EV/FCFF</li>
     <div>
       <ul style="list-style-type:disc" class="detail">
         <li>公司过去十年的EV/FCFF</li>
         <div class="proCharts" ref="evDivideFcffChart" ></div>   
         <li>公司过去十年的EV/FCFF的变化率</li>
         <div class="proCharts" ref="evDivideFcffChangeRatioChart" ></div>  
         <li>公司过去十年的平均EV/FCFF为：{{analysisResult.stock_key_info_summary[0].ev_divide_fcff_avg}}</li>          
       </ul>     
     </div>
     <li>行业数据</li>   
     <div>
       <ul style="list-style-type:disc" class="detail">
         <li>公司过去一年ROE是{{analysisResult.stock_key_info_summary[0].roe_last_year}}，十年平均ROE是{{analysisResult.stock_key_info_summary[0].ten_year_roe_avg}}，行业当前ROE是{{analysisResult.stock_key_info_summary[0].industry_roe_curr}}</li>
         <li>公司PE(TTM)是{{analysisResult.stock_key_info_summary[0].PE_TTM}}，行业PE是{{analysisResult.stock_key_info_summary[0].industry_PE}}，公司PE/行业PE是{{analysisResult.stock_key_info_summary[0].stock_PE_TTM_devide_industry_PE}}</li>
         <li>公司营收和行业营收的关系</li>
         <div class="proCharts" ref="revenueIndustryRatioChart" ></div> 
         <li>公司营收占行业营收的年化增长率为：{{analysisResult.stock_key_info_summary[0].revenue_industry_ratio_annual_growth}}%</li>  
       </ul>     
    </div>  
   </ol>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "Other_Key_Data",
  data() {
    return {
      analysisResult:{}
    }
  },
  methods:{
    capitalExpenditureChart(){
      var xdata = [],ydata=[]
      for(var i=0;i<Object.keys(this.analysisResult.ten_year_other_data).length;i++){
      xdata.splice(0,0,this.analysisResult.ten_year_other_data[i].time.slice(0,4))
      }
      for(i=0;i<Object.keys(this.analysisResult.ten_year_other_data).length;i++){
      ydata.splice(0,0,this.analysisResult.ten_year_other_data[i].capital_expenditure)
      }     
      var option =  {
          legend: {
          // orient: 'vertical',
          top: 10,
          },
          tooltip: {},
          xAxis: {
          type: 'category',
          data: xdata,
          name:"时间",
          axisTick: {
              alignWithLabel: true
          },
          },
          yAxis: {
          type: 'value',
          name: "资本开支"
          },
          series: [
              {
              name: "十年资本开支趋势图",
              data: ydata,
              type: 'line',
              label: {
              show: true,
              position: 'bottom',
              textStyle: {
                  fontSize: 10
              }
              }
              }
          ]
      }
      let myChart =this.$echarts.init(this.$refs.capitalExpenditureChart);
      myChart.setOption(option)
    },
    capitalExpenditureChangeRateChart(){
      var xdata = [],ydata=[]
      for(var i=0;i<Object.keys(this.analysisResult.ten_year_other_data).length;i++){
      xdata.splice(0,0,this.analysisResult.ten_year_other_data[i].time.slice(0,4))
      }
      for(i=0;i<Object.keys(this.analysisResult.ten_year_other_data).length;i++){
      ydata.splice(0,0,this.analysisResult.ten_year_other_data[i].capital_expenditure_change_rate)
      }     
      var option =  {
          legend: {
          // orient: 'vertical',
          top: 10,
          },
          tooltip: {},
          xAxis: {
          type: 'category',
          data: xdata,
          name:"时间",
          axisTick: {
              alignWithLabel: true
          },
          },
          yAxis: {
          type: 'value',
          name: "资本开支变化率"
          },
          series: [
              {
              name: "十年资本变化率趋势图",
              data: ydata,
              type: 'line',
              label: {
              show: true,
              position: 'bottom',
              textStyle: {
                  fontSize: 10
              }
              }
              }
          ]
      }
      let myChart =this.$echarts.init(this.$refs.capitalExpenditureChangeRateChart);
      myChart.setOption(option)
    }, 
    netCurrentAssetsPerShareChart(){
      var xdata = [],ydata=[]
      for(var i=0;i<Object.keys(this.analysisResult.ten_year_other_data).length;i++){
      xdata.splice(0,0,this.analysisResult.ten_year_other_data[i].time.slice(0,4))
      }
      for(i=0;i<Object.keys(this.analysisResult.ten_year_other_data).length;i++){
      ydata.splice(0,0,this.analysisResult.ten_year_other_data[i].net_current_assets_per_share)
      }     
      var option =  {
          legend: {
          // orient: 'vertical',
          top: 10,
          },
          tooltip: {},
          xAxis: {
          type: 'category',
          data: xdata,
          name:"时间",
          axisTick: {
              alignWithLabel: true
          },
          },
          yAxis: {
          type: 'value',
          name: "每股净运营资产"
          },
          series: [
              {
              name: "十年净运营资产趋势图",
              data: ydata,
              type: 'line',
              label: {
              show: true,
              position: 'bottom',
              textStyle: {
                  fontSize: 10
              }
              }
              }
          ]
      }
      let myChart =this.$echarts.init(this.$refs.netCurrentAssetsPerShareChart);
      myChart.setOption(option)
    },    
    evDivideEbitChart(){
      var xdata = [],ydata=[]
      for(var i=0;i<Object.keys(this.analysisResult.ten_year_other_data).length;i++){
      xdata.splice(0,0,this.analysisResult.ten_year_other_data[i].time.slice(0,4))
      }
      for(i=0;i<Object.keys(this.analysisResult.ten_year_other_data).length;i++){
      ydata.splice(0,0,this.analysisResult.ten_year_other_data[i].ev_divide_ebit)
      }     
      var option =  {
          legend: {
          // orient: 'vertical',
          top: 10,
          },
          tooltip: {},
          xAxis: {
          type: 'category',
          data: xdata,
          name:"时间",
          axisTick: {
              alignWithLabel: true
          },
          },
          yAxis: {
          type: 'value',
          name: "EV/EBIT"
          },
          series: [
              {
              name: "十年EV/EBIT趋势图",
              data: ydata,
              type: 'line',
              label: {
              show: true,
              position: 'bottom',
              textStyle: {
                  fontSize: 10
              }
              }
              }
          ]
      }
      let myChart =this.$echarts.init(this.$refs.evDivideEbitChart);
      myChart.setOption(option)
    },    
    evDivideEbitChangeRateChart(){
      var xdata = [],ydata=[]
      for(var i=0;i<Object.keys(this.analysisResult.ten_year_other_data).length;i++){
      xdata.splice(0,0,this.analysisResult.ten_year_other_data[i].time.slice(0,4))
      }
      for(i=0;i<Object.keys(this.analysisResult.ten_year_other_data).length;i++){
      ydata.splice(0,0,this.analysisResult.ten_year_other_data[i].ev_divide_ebit_change_rate)
      }     
      var option =  {
          legend: {
          // orient: 'vertical',
          top: 10,
          },
          tooltip: {},
          xAxis: {
          type: 'category',
          data: xdata,
          name:"时间",
          axisTick: {
              alignWithLabel: true
          },
          },
          yAxis: {
          type: 'value',
          name: "EV/EBIT变化率"
          },
          series: [
              {
              name: "十年EV/EBIT变化率趋势图",
              data: ydata,
              type: 'line',
              label: {
              show: true,
              position: 'bottom',
              textStyle: {
                  fontSize: 10
              }
              }
              }
          ]
      }
      let myChart =this.$echarts.init(this.$refs.evDivideEbitChangeRateChart);
      myChart.setOption(option)
    },
    evDivideFcffChart(){
      var xdata = [],ydata=[]
      for(var i=0;i<Object.keys(this.analysisResult.ten_year_other_data).length;i++){
      xdata.splice(0,0,this.analysisResult.ten_year_other_data[i].time.slice(0,4))
      }
      for(i=0;i<Object.keys(this.analysisResult.ten_year_other_data).length;i++){
      ydata.splice(0,0,this.analysisResult.ten_year_other_data[i].ev_divide_fcff)
      }     
      var option =  {
          legend: {
          // orient: 'vertical',
          top: 10,
          },
          tooltip: {},
          xAxis: {
          type: 'category',
          data: xdata,
          name:"时间",
          axisTick: {
              alignWithLabel: true
          },
          },
          yAxis: {
          type: 'value',
          name: "EV/FCFF"
          },
          series: [
              {
              name: "十年EV/FCFF趋势图",
              data: ydata,
              type: 'line',
              label: {
              show: true,
              position: 'bottom',
              textStyle: {
                  fontSize: 10
              }
              }
              }
          ]
      }
      let myChart =this.$echarts.init(this.$refs.evDivideFcffChart);
      myChart.setOption(option)
    },
    evDivideFcffChangeRatioChart(){
      var xdata = [],ydata=[]
      for(var i=0;i<Object.keys(this.analysisResult.ten_year_other_data).length;i++){
      xdata.splice(0,0,this.analysisResult.ten_year_other_data[i].time.slice(0,4))
      }
      for(i=0;i<Object.keys(this.analysisResult.ten_year_other_data).length;i++){
      ydata.splice(0,0,this.analysisResult.ten_year_other_data[i].ev_divide_fcff_change_ratio)
      }     
      var option =  {
          legend: {
          // orient: 'vertical',
          top: 10,
          },
          tooltip: {},
          xAxis: {
          type: 'category',
          data: xdata,
          name:"时间",
          axisTick: {
              alignWithLabel: true
          },
          },
          yAxis: {
          type: 'value',
          name: "EV/FCFF变化率"
          },
          series: [
              {
              name: "十年EV/FCFF变化率趋势图",
              data: ydata,
              type: 'line',
              label: {
              show: true,
              position: 'bottom',
              textStyle: {
                  fontSize: 10
              }
              }
              }
          ]
      }
      let myChart =this.$echarts.init(this.$refs.evDivideFcffChangeRatioChart);
      myChart.setOption(option)
    },
    revenueIndustryRatioChart(){
      var xdata = [],ydata=[]
      for(var i=0;i<Object.keys(this.analysisResult.stock_revenue_industry).length;i++){
      xdata.splice(0,0,this.analysisResult.stock_revenue_industry[i].time.slice(0,4))
      }
      for(i=0;i<Object.keys(this.analysisResult.stock_revenue_industry).length;i++){
      ydata.splice(0,0,this.analysisResult.stock_revenue_industry[i].revenue_industry_ratio)
      }     
      var option =  {
          legend: {
          // orient: 'vertical',
          top: 10,
          },
          tooltip: {},
          xAxis: {
          type: 'category',
          data: xdata,
          name:"时间",
          axisTick: {
              alignWithLabel: true
          },
          },
          yAxis: {
          type: 'value',
          name: "公司营收占行业比例"
          },
          series: [
              {
              name: "十年营收占行业比例变化趋势图",
              data: ydata,
              type: 'line',
              label: {
              show: true,
              position: 'bottom',
              textStyle: {
                  fontSize: 10
              }
              }
              }
          ]
      }
      let myChart =this.$echarts.init(this.$refs.revenueIndustryRatioChart);
      myChart.setOption(option)
    }
  },
  components:{},
  updated(){
    this.$nextTick(()=>{
      if(Object.keys(this.analysisResult.stock_key_info_summary).length>0){
        this.capitalExpenditureChart()
        this.capitalExpenditureChangeRateChart()
        this.netCurrentAssetsPerShareChart()
        this.evDivideEbitChart()
        this.evDivideEbitChangeRateChart()
        this.evDivideFcffChart()
        this.evDivideFcffChangeRatioChart()
        this.revenueIndustryRatioChart()
      }
    })
  },
  mounted(){
    this.analysisResult = this.$store.state.ffa_result  
  }
}
</script>

<style scoped lang='scss'>
.container li{
  margin: 10px;
  margin-left: 30px;
  font-size: 16px;
}
.detail{
  margin: 20px;    
}
.proCharts{
  width: 50%;
  height: 500px;
  // background: rgb(14, 51, 129);
}
</style>