<template>
 <div>
  <div class="navigator">
    <span><router-link :to="{name:'comparability_data'}" class="one">可比性数据</router-link></span>
    <span><router-link :to="{name:'three_indicators_of_DuPont_analysis'}" class="two">杜邦分析的三个指标</router-link></span>
    <span><router-link :to="{name:'other_key_data'}" class="three">其他关键数据</router-link></span>
  </div>
  <div>
   <router-view></router-view>
  </div>
 </div>

</template>

<script type="text/ecmascript-6">
export default {
 name: "Good_Company",
 data() {
   return {}
 }
}
</script>

<style scoped lang='scss'>
.navigator{
  width: 360px;
  margin: 5px 5px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  .one{
    color: coral;
  }
  .two{
    color: chartreuse;
  }
  .three{
    color: deepskyblue;
  }
}
</style>