<template>
 <div class="container" v-if="Object.keys(analysisResult).length > 0 && Object.keys(otherResult).length > 0">
   <ol>
     <li>公司每一年的估值、市场利率、行业估值叠加图，观察当前利率对应的估值在历史上的情况。</li>
     <div class="proCharts" ref="otherChart" ></div>  
     <li>公司最近5年的营业收入、净利润、经营杠杆和财务杠杆</li>
     <div class="proCharts" ref="leverChart" ></div> 

   </ol>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "Other",
 data() {
   return {
     analysisResult:{},
     otherResult:{}
   }
 },
 updated(){
   if(Object.keys(this.analysisResult.stock_key_info_summary).length>0){
      this.otherChart()
    }
   if(Object.keys(this.otherResult.five_year_info).length>0){
      this.leverChart()
   } 
 },  
 methods:{
   otherChart(){
     var xdata = [],adata=[],bdata=[],cdata=[],ddata=[]
      for(var i=0;i<Object.keys(this.analysisResult.industry_stock_bond).length;i++){
        xdata.splice(100,0,this.analysisResult.industry_stock_bond[i].time.slice(0,4))
      }
      for(i=0;i<Object.keys(this.analysisResult.industry_stock_bond).length;i++){
        adata.splice(100,0,this.analysisResult.industry_stock_bond[i].ten_year_yield)
      }
      for(i=0;i<Object.keys(this.analysisResult.industry_stock_bond).length;i++){
        bdata.splice(100,0,this.analysisResult.industry_stock_bond[i].company_pe)
      }
      for(i=0;i<Object.keys(this.analysisResult.industry_stock_bond).length;i++){
        cdata.splice(100,0,this.analysisResult.industry_stock_bond[i].industry_pe)
      }
      for(i=0;i<Object.keys(this.analysisResult.industry_stock_bond).length;i++){
        ddata.splice(100,0,this.analysisResult.industry_stock_bond[i].company_pe_divide_industry_pe)
      }
                      
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name:"PE",
              position: "left"
             },
             {
               type: "value",
               name: "利率（%）和比值",
               position: "right"
             }
          ],  
          series: [
            {
              name: "无风险利率",
              data: adata,
              yAxisIndex: 1,
              type: 'line',
            },
            {
              name: "公司PE",
              data: bdata,
              yAxisIndex: 0,
              type: 'line',
            },
            {
              name: "行业PE",
              data: cdata,
              yAxisIndex: 0,
              type: 'line',
            },
            {
              name: "公司PE/行业PE",
              data: ddata,
              yAxisIndex: 1,
              type: 'line'
            }                            
          ]
        }
      let myChart =this.$echarts.init(this.$refs.otherChart);
      myChart.setOption(option)      
   },
   leverChart(){
     var xdata = [],adata=[],bdata=[],cdata=[],ddata=[]
      for(var i=0;i<Object.keys(this.otherResult.five_year_info).length-1;i++){
        xdata.splice(0,0,this.otherResult.five_year_info[i].time.slice(0,4))
      }
      for(i=0;i<Object.keys(this.otherResult.five_year_info).length-1;i++){
        adata.splice(0,0,this.otherResult.five_year_info[i].revenue)
      }
      for(i=0;i<Object.keys(this.otherResult.five_year_info).length-1;i++){
        bdata.splice(0,0,this.otherResult.five_year_info[i].profit)
      }
      for(i=0;i<Object.keys(this.otherResult.five_year_info).length-1;i++){
        cdata.splice(0,0,this.otherResult.five_year_info[i].operating_leverage)
      }
      for(i=0;i<Object.keys(this.otherResult.five_year_info).length-1;i++){
        ddata.splice(0,0,this.otherResult.five_year_info[i].financial_leverage)
      }
                      
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name:"值",
              position: "left"
             },
             {
               type: "value",
               name: "比率",
               position: "right"
             }
          ],  
          series: [
            {
              name: "营收",
              data: adata,
              yAxisIndex: 0,
              type: 'line',
            },
            {
              name: "净利润",
              data: bdata,
              yAxisIndex: 0,
              type: 'line',
            },
            {
              name: "经营杠杆",
              data: cdata,
              yAxisIndex: 1,
              type: 'line',
            },
            {
              name: "财务杠杆",
              data: ddata,
              yAxisIndex: 1,
              type: 'line'
            }                            
          ]
        }
      let myChart =this.$echarts.init(this.$refs.leverChart);
      myChart.setOption(option)      
  },    
 },
 components:{},
 mounted(){
   this.analysisResult = this.$store.state.ffa_result
   let params = new URLSearchParams()
   let symbol = this.$store.state.searchsymbol
   params.append('symbol', symbol)
   this.$http.reverse_detail(params).then(res=>{
     this.otherResult = res.data
   }).catch(err=>{
     console.log(err)
   })   
 } 
}
</script>

<style scoped lang='scss'>
.container{
  span{
    font-size: 16px;
  }
  p{
    font-family: "宋体";
    margin: 10px;
  }
  li{
    margin: 20px;
    margin-left: 30px;
    font-size: 16px;
  }  
  .title{
    font-family: "微软雅黑";
    margin: 10px 10px;
  }
}
.proCharts{
  width: 100%;
  height: 500px;
  // background: rgb(14, 51, 129);
}
</style>