<template>
 <div class="">
   <ol class="container" v-if="Object.keys(reverseDetail).length > 0">
     <li>公司综合信息</li>
        <el-table
            :data="reverseDetail.last_season_info"
            style="width: 2071px"
            border>
            <el-table-column
              prop="time"
              label="时间"
              width="150"
              :formatter="timeFormat">
            </el-table-column>
            <el-table-column
              prop="info"
              label="代码"
              width="100">
            </el-table-column>
            <el-table-column
              prop="revenue_TTM"
              label="盈收（TTM）"
              width="200">
            </el-table-column>
            <el-table-column
              prop="total_assets"
              label="总资产"
              width="200">
            </el-table-column>
            <el-table-column
              prop="cash"
              label="现金"
              width="200">
            </el-table-column>            
            <el-table-column
              prop="interest_bearing_liability"
              label="带息负债"
              width="200">
            </el-table-column>
            <el-table-column
              prop="EBITDA_TTM"
              label="EBITDA（TTM）"
              width="200">
            </el-table-column>
            <el-table-column
              prop="DEBT_divide_EBITDA_TTM"
              label="带息负债除以EBITDA（TTM）"
              width="220">
            </el-table-column>
            <el-table-column
              prop="CFO"
              label="CFO（最近季度）"
              width="200">
            </el-table-column>    
            <el-table-column
              prop="last_year_repurchase_number"
              label="过去一年回购的股票数量"
              width="200">
            </el-table-column>  
             <el-table-column
              prop="last_year_repurchase_money"
              label="过去一年回购的股票金额"
              width="200">
            </el-table-column>                                                                     
        </el-table>
     <li>公司最近5年的营业收入、净利润、经营杠杆和财务杠杆</li>
     <div class="proCharts" ref="leverChart" ></div>
     <li>公司最近10条事件</li>
        <el-table
            :data="reverseDetail.news"
            style="width: 2051px"
            border>
            <el-table-column
              prop="index"
              label="索引"
              width="50">
            </el-table-column> 
            <el-table-column
              prop="publish_time"
              label="时间"
              width="200">
            </el-table-column>
            <el-table-column
              prop="title"
              label="主题"
              width="500">
            </el-table-column>
            <el-table-column
              prop="author"
              label="作者"
              width="200">
            </el-table-column>
            <el-table-column
              prop="content"
              label="内容"
              width="1100">
            </el-table-column>  
        </el-table>              
   </ol>  
 </div>
</template>

<script type="text/ecmascript-6">
import {Table,TableColumn} from 'element-ui'
export default {
 name: "Reverse_Detail",
 data() {
   return {
     reverseDetail:{}
   }
 },
 components:{
   [Table.name]:Table,
   [TableColumn.name]:TableColumn      
 },
 methods:{
   timeFormat(row, column, cellValue) {
     return cellValue.slice(0,4)
   },
   lever_chart(){
     var xdata = [],adata=[],bdata=[],cdata=[],ddata=[]
      for(var i=0;i<Object.keys(this.reverseDetail.five_year_info).length-1;i++){
        xdata.splice(0,0,this.reverseDetail.five_year_info[i].time.slice(0,4))
      }
      for(i=0;i<Object.keys(this.reverseDetail.five_year_info).length-1;i++){
        adata.splice(0,0,this.reverseDetail.five_year_info[i].revenue)
      }
      for(i=0;i<Object.keys(this.reverseDetail.five_year_info).length-1;i++){
        bdata.splice(0,0,this.reverseDetail.five_year_info[i].profit)
      }
      for(i=0;i<Object.keys(this.reverseDetail.five_year_info).length-1;i++){
        cdata.splice(0,0,this.reverseDetail.five_year_info[i].operating_leverage)
      }
      for(i=0;i<Object.keys(this.reverseDetail.five_year_info).length-1;i++){
        ddata.splice(0,0,this.reverseDetail.five_year_info[i].financial_leverage)
      }
                      
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name:"值",
              position: "left"
             },
             {
               type: "value",
               name: "比率",
               position: "right"
             }
          ],  
          series: [
            {
              name: "营收",
              data: adata,
              yAxisIndex: 0,
              type: 'line',
            },
            {
              name: "净利润",
              data: bdata,
              yAxisIndex: 0,
              type: 'line',
            },
            {
              name: "经营杠杆",
              data: cdata,
              yAxisIndex: 1,
              type: 'line',
            },
            {
              name: "财务杠杆",
              data: ddata,
              yAxisIndex: 1,
              type: 'line'
            }                            
          ]
        }
      let myChart =this.$echarts.init(this.$refs.leverChart);
      myChart.setOption(option)      
  },   
 },
 updated(){
   this.$nextTick(()=>{
     if(this.reverseDetail.five_year_info.length > 0){
       this.lever_chart()
     }
   })    
 },
 mounted(){
   let params = new URLSearchParams()
   let symbol = this.$store.state.reverse_symbol
   params.append('symbol', symbol)
   this.$http.reverse_detail(params).then(res=>{
     this.reverseDetail = res.data
   }).catch(err=>{
     console.log(err)
   })
 }
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 20px;
    margin-left: 30px;
    font-size: 16px;
}
.proCharts{
  width: 100%;
  height: 500px;
  // background: rgb(14, 51, 129);
}
</style>