<template>
 <div class="">
    <el-table
        :data="terms"
        style="width: 1751px"
        border>
        <el-table-column
        prop="id"
        label="序号"
        width="50"
        >
            <template slot-scope="scope">{{scope.$index + 1}}</template>
        </el-table-column>
        <el-table-column
        prop="term"
        label="术语"
        width="300">
        </el-table-column>
        <el-table-column
        prop="name"
        label="英文简称"
        width="400">
        </el-table-column>
        <el-table-column
        prop="explain"
        label="说明"
        width="1000">
        </el-table-column>

    </el-table>
 </div>
</template>

<script type="text/ecmascript-6">
import {Table,TableColumn} from 'element-ui'
export default {
 name: "Financial_Terminology",
 data() {
   return {
     terms:[
      {
        term:"有息负债",
        name:"liability with interest",
        explain:"企业需要支付利息的债务。这类债务包括银行贷款、公司债券等，需要定期支付利息，并且债务到期时需要支付本金，是负债总额的一部分，与无息负债（应付账款、其他应付账款等）相对，后者不需要支付利息。带息负债会影响企业的财务成本和现金流，因此在财务管理中需要仔细考虑其影响。"
      },
      {
        term:"息税前利润（营业利润）",
        name:"earning before interest and taxes（EBIT）",
        explain:"是指在计算企业净收益过程中，扣除借款利息和所得税之前的营业收益。"
      },
      {
        term:"息税折旧摊销前利润",
        name:"earning before interest, taxes, depreciation and amortization（EBITDA）",
        explain:"用于衡量公司在扣除利息、税收、折旧和摊销之前的盈利能力。EBITDA排出了利息、税收、折旧和摊销等非经营性的因素，反映了公司经营活动产生的现金流量，通常与公司的现金流密切相关。由于不同行业的税率、资本结构和折旧政策可能不同，EBITDA可以作为一个统一的标准来比较不同公司的盈利能力。"
      },
      {
        term:"归母净利润",
        name:"net profit attributable to parent company shareholders",
        explain:"归属于母公司所有者的净利润，是指在公司合并财务报表中，扣除了少数股东权益后的净利润部分。它反映了公司在一定会计期内，归属于母公司股东的实际盈利情况。"
      },
      {
        term:"股东权益回报率（净资产收益率）",
        name:"ROE（return on stockholders' equity）",
        explain:"是指一个企业的税后净利润与股东权益的比率。它可用于衡量股东投资的收益情况。"
      },
      {
        term:"全面摊薄净资产收益率",
        name:"ROE（return on equity）",
        explain:"是指一个企业的税后净利润除以年初与年底股东权益平均值所得的比率。"
      },
      {
        term:"留存收益",
        name:"retain earnings/income/profit",
        explain:"是指企业为了扩厂、增资而予以保留的未分配给股东的利润。也称未分配利润。"
      },
      {
        term:"留存收益率",
        name:"retention rate",
        explain:"是指计入公司准备金的税后利润率，其与股利支付率相对应。"
      },
      {
        term:"总资产回报率",
        name:"ROA",
        explain:"是指以企业一定时期的净利润除以年初与年底资产总额的平均值所得的比率。它用以衡量平均每1元资产所赚得的税后净利，是衡量一个企业盈利水平的常用指标。"
      },
      {
        term:"已用有形资产收益率",
        name:"ROTCE（return on tangible capital employed）",
        explain:"是指一个衡量公司使用其有形资产产生利润能力的财务指标。它通常用来评估公司在不依赖无形资产（如专利、商誉等）的情况下，其有形资产（如设备、建筑物、库存等）的盈利效率。ROTCE等于税后营业利润除以有形资产总额。"
      },
      {
        term:"企业价值",
        name:"EV（enterprise value）",
        explain:"是指通过考察企业的销售额和收益绩效，以及与其所处行业和市场相关的策略和政策等，来衡量企业继续经营的能力。它等于公司负债的市场价值与普通股、优先股市场价值之和，减去现金和投资的价值。"
      },
      {
        term:"公司自由现金流",
        name:"FCFF（free cash flow to the firm）",
        explain:"是指在支付了经营费用（包括税收）之后，向普通股与优先股股东、债权人支付现金之前的全部现金流。"
      },
      {
        term:"股权自由现金流",
        name:"FCFE（free cash flow to equity)",
        explain:"是指公司股东所拥有的除去经营费用、本息偿还和为保持预定现金流增长率所需的全部资本性支出后的剩余现金流。"
      },
      {
        term:"复合收益率",
        name:"CAGR（compound annual growth rate）",
        explain:"是一种衡量投资回报率的指标，它表示投资在特定时间段内的平均年增长率。"
      },
      {
        term:"无形资产",
        name:"intangible asset",
        explain:"是指企业长期使用但是没有实物形态的资产。例如：特许经营权、专利权、商标权、版权及商誉等。无形资产占比是指无形资产占总资产的比例。"
      },
      {
        term:"固定资产",
        name:"fixed asset",
        explain:"是指使用期限较长、使用价值较高、并且在使用中保持原有实物形态的资产。例如厂房、土地、机器设备等。固定资产占比是指固定资产占总资产的比例。"
      },
      {
        term:"资本开支",
        name:"CAPEX（capital expenditure）",
        explain:"是指企业为构建厂房，购买设备等固定资产而发生的支出，其特点是资金量大、周期长、风险大。资本支出占比是指资本支出占主营收入的比例。"
      },
      {
        term:"应收账款",
        name:"accounts receivable",
        explain:"是指由于商品和服务的交付以及在一般商业活动中扩展信用而造成的顾客拖欠公司的款项，或指企业向客户售出商品或服务但尚未从客户处收回的货款。"
      },
      {
        term:"预收账款",
        name:"advances",
        explain:"是指可以在规定付款期以前的任何时间进行支付，其中可以包括合同未完全履行前的付款和预付工资等。"
      },
      {
        term:"现金周转周期",
        name:"cash turnover",
        explain:"是指存货或应收账款等资产在一定时间段内的平均周转次数。这里的计算方式为：现金周转周期 = 应收账款天数 + 库存天数 - 应付账款天数"
      },
      {
        term:"加权扣非ROE",
        name:"",
        explain:"是指加权平均净资产收益率，扣除非经常性损益后的指标。计算公式为：扣除非经常性损益后的净利润除以期初和期末净资产的平均值。"
      },
      {
        term:"销售利润率（净利率）",
        name:"profit margin（net profit margin）",
        explain:"是指利润与销售价格之间的比率关系。通常以百分比表示。"
      },
      {
        term:"总资产周转率",
        name:"asset turnover rate",
        explain:"是指一个企业一年的销售总额与该年度平均总资产的比率，能反映企业资产使用效率的情况。该指标越大，说明资产的使用效率越高。"
      },
      {
        term:"权益乘数",
        name:"equity multiplier",
        explain:"也称为财务杠杆比率，是衡量公司财务杠杆程度的一个重要指标。它表示公司资产与股东权益（净资产）之间的比率，反映了公司使用债务相对于自有资本的程度。"
      },
      {
        term:"净运营资本（净运营资产）",
        name:"net working capital",
        explain:"是一个衡量公司短期财务健康程度的指标。它反映了公司用于日常运营的资金需求，净运营资本通常定义为公司流动资产减去流动负债。净运营资本的变化可以提供公司运营效率的信息。例如，公司的净运营资本随着时间的推移而减少，这可能表明公司正在更有效地管理其流动性和负债，或者可能正在经历销售增长。相反，如果净运营资本增加，这可能表明公司需要更多的资金来支持其运营，或者可能存在存货积压或应收账款回收缓慢的问题。"
      },
      {
        term:"企业价值与息税前利润之比",
        name:"EV/EBIT",
        explain:"是一个衡量公司价值的财务指标，常用于评估并购目标或比较不同公司的估值水平，反映了投资者为每单位息税前利润支付的价格。它特别适用于比较那些财务结构不同的公司，因为传统的市盈率可能收到公司财务杠杆的影响。"
      },
      {
        term:"企业价值与息税折旧摊销前利润之比",
        name:"EV/EBITDA",
        explain:"是一个衡量公司价值的财务指标，它反映了投资者为每单位息税折旧摊销前利润支付的价格，排除了财务杠杆、折旧和摊销等因素的影响，使得不同公司的比较更为公平。本指标特别适用于资本密集型行业。EV/EBITDA通常用于需要排出非现金支出影响的情况，而EV/EBIT更侧重于公司的运营盈利能力。"
      },
      {
        term:"企业价值与公司自由现金流之比",
        name:"EV/FCFF",
        explain:"是一个衡量公司价值的财务指标，它反映了投资者为每单位自由现金流支付的价格。公司自由现金流是指公司在支付了所有运营成本和资本支出后剩余的现金流量，这是公司可以自由分配给股东、债权人或再投资于公司的现金。EV/FCFF比率的优势在于它提供了一个更为全面的公司价值衡量，因为它考虑了公司的资本支出需求和自由现金流的生成能力。这个指标特别适用于评估那些资本支出较大的公司，因为这些公司的EBITDA可能较高，但是自由现金流较低。"
      },
      {
        term:"公司市值于股权自由现金流之比",
        name:"P/FCF（MV/FCFE）",
        explain:"是一个衡量股权价值的财务指标，用于评估公司股票的估值。股权自由现金流是指公司在支付了所有运营成本、资本支出和债务还本付息之后，可供股东使用的现金流量。MV/FCFE比率的优势在于它专注于股东能够实际获得的现金流量，这使得它特别适合评估那些具有不同资本结构和融资策略的公司的股权价值。"
      },
      {
        term:"经营杠杆",
        name:"operating leverage",
        explain:"是指在企业经营成本中对固定成本的运用，放大了销售额变动对经营利润的影响。它等于经营利润的变动百分比除以销售额的变动百分比。"
      },
      {
        term:"财务杠杆",
        name:"financial leverage",
        explain:"是指企业可以利用负债来调节权益资本收益。即当债务的利率低于公司的收益率时，可以使用长期债务筹借资金来增加公司的净收益。它等于资产除以净资产，或等于EBIT/（EBIT-I），其中I表示利息费用。"
      },
      {
        term:"财务比率",
        name:"financial ratio",
        explain:"是指资产负债表、利润表和现金流量表各项目之间的比率关系。通常可用比例、分数或百分比表示，用于衡量和评价公司的财务状况。"
      },
      {
        term:"现金流折现法",
        name:"discounted cash flow（DCF） analysis",
        explain:"是指通过对项目所产生的预期现金流用折现率折现后得出现值来对投资项目进行评估的方法。"
      }

     ]
   }
 },
 components:{
   [Table.name]:Table,
   [TableColumn.name]:TableColumn     
 }
}
</script>

<style scoped lang='scss'>
</style>