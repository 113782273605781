<template>
 <div class="">
    <article>
      <section>
        <p class="paragraph">对一家成熟型公司的未来前景的评估首先是基于其过往的历史。在对未来情况做预测之前，先是对历史的财务数据进行分析。分析的方法来自《华尔街证券分析：股票分析与公司估值》一书，使用上市企业财务报表作为原始素材，以及4个基本工具：绝对数量变化、增长变化的百分比、归一化后的百分比和财务比率。这些信息我们使用了3年的历史数据，并应用在<b>“财务特征分析”</b>的工具中，以及用来与同行业公司的同类数据进行比较。<b>这里的分析工具主要提供了同行业不同公司之间的共同比分析(财务数据归一化后的百分比率)比较。</b></p>
        <p class="paragraph"><b>1、绝对数量变化</b></p>
        <p class="paragraph">指标包括：</p>
        <p class="paragraph">1.利润表数据</p>
        <p class="paragraph">（1）净销售收入的变化</p>
        <p class="paragraph">（2）销货成本的变化</p>
        <p class="paragraph">（3）销售费用、一般费用及管理费用的变化</p>
        <p class="paragraph">（4）研发费用的变化</p>
        <p class="paragraph">（5）折旧的变化</p>
        <p class="paragraph">（6）息税前利润（EBIT）的变化</p>
        <p class="paragraph">（7）EBITDA的变化</p>
        <p class="paragraph">（8）债务利息（利息费用）的变化</p>
        <p class="paragraph">（9）税前利润（EBT）的变化</p>
        <p class="paragraph">（10）所得税准备的变化</p>
        <p class="paragraph">（11）净利润的变化</p>
        <p class="paragraph">（12）每股净利润的变化</p>
        <p class="paragraph">2.资产负债表数据</p>
        <p class="paragraph">（1）现金的变化</p>
        <p class="paragraph">（2）净营运资本的变化</p>
        <p class="paragraph">（3）总资产的变化</p>
        <p class="paragraph">（4）长期负债，减本年到期部分的变化</p>  
        <p class="paragraph">（5）股东权益的变化</p>
        <p class="paragraph">（6）股本的变化</p>          
        <p class="paragraph">3.现金流量表数据</p>
        <p class="paragraph">（1）经营活动产生的净现金</p>
        <p class="paragraph">（2）投资活动使用的净现金</p>
        <p class="paragraph">（3）经营现金流 （1+2）</p>
        <p class="paragraph">（4）融资活动产生的净现金</p>  
        <p class="paragraph">（5）净现金增加（减少）</p>
        <br>
        <p class="paragraph"><b>2、增长变化的百分比</b></p>
        <p class="paragraph">以百分比变化表示的财务数据更有助于分析。指标包括：</p>
        <p class="paragraph">1.利润表数据</p>
        <p class="paragraph">（1）净销售收入的百分比变化</p>
        <p class="paragraph">（2）销货成本的百分比变化</p>
        <p class="paragraph">（3）销售费用、一般费用及管理费用的百分比变化</p>
        <p class="paragraph">（4）研发费用的百分比变化</p>
        <p class="paragraph">（5）折旧的百分比变化</p>
        <p class="paragraph">（6）息税前利润（EBIT）的百分比变化</p>
        <p class="paragraph">（7）EBITDA的百分比变化</p>
        <p class="paragraph">（8）债务利息（利息费用）的百分比变化</p>
        <p class="paragraph">（9）税前利润（EBT）的百分比变化</p>
        <p class="paragraph">（10）所得税准备的百分比变化</p>
        <p class="paragraph">（11）净利润的百分比变化</p>
        <p class="paragraph">（12）每股净利润的百分比变化</p>  
        <p class="paragraph">2.资产负债表数据</p>
        <p class="paragraph">（1）现金的百分比变化</p>
        <p class="paragraph">（2）净营运资本的百分比变化</p>
        <p class="paragraph">（3）总资产的百分比变化</p>
        <p class="paragraph">（4）长期负债，减本年到期部分的百分比变化</p>  
        <p class="paragraph">（5）股东权益的百分比变化</p>
        <p class="paragraph">（6）股本的百分比变化</p>                 
        <br>
        <p class="paragraph"><b>3、归一化后的百分比</b></p>
        <p class="paragraph">财务报分析的一种流行工具是共同比报表，也称共同尺度报表或百分比报表。在这种报表中，利润表及资产负债表项目被表示为相对于销售收入或总资产的百分比。指标包括：</p>
        <p class="paragraph">1.利润表数据</p>
        <p class="paragraph">（1）销售收入定为100%</p>
        <p class="paragraph">（2）销货成本的占销售收入百分比</p>
        <p class="paragraph">（3）销售费用、一般费用及管理费用的占销售收入百分比</p>
        <p class="paragraph">（4）研发费用的占销售收入百分比</p>
        <p class="paragraph">（5）折旧的占销售收入百分比</p>
        <p class="paragraph">（6）息税前利润（EBIT）的占销售收入百分比</p>
        <p class="paragraph">（7）EBITDA的占销售收入百分比</p>
        <p class="paragraph">（8）债务利息的占销售收入百分比</p>
        <p class="paragraph">（9）税前利润的占销售收入百分比</p>
        <p class="paragraph">（10）所得税准备的占销售收入百分比</p>
        <p class="paragraph">（11）净利润的占销售收入百分比</p>
        <p class="paragraph">2.资产负债表数据</p>
        <p class="paragraph">（1）总资产定为100%</p>
        <p class="paragraph">（2）现金的占总资产百分比</p>
        <p class="paragraph">（3）净营运资本的占总资产百分比</p> 
        <p class="paragraph">（4）长期负债，减本年到期部分的占总资产百分比</p>
        <p class="paragraph">（5）股东权益的占总资产百分比</p>               
        <br>
        <p class="paragraph"><b>4、财务比率</b></p>
        <p class="paragraph">财务比率分析将利润表、资产负债表及现金流量表的项目相互联系起来，提供了评价公司现状及预测未来表现的一些线索。比率指标包括：</p>
        <p class="paragraph">1.盈利能力比率</p>
        <p class="paragraph">（1）净利润/平均股东权益（%）</p>
        <p class="paragraph">（2）EBITDA/平均总资产（%）</p>
        <p class="paragraph">2.周转能力比率</p>
        <p class="paragraph">（1）销售额/平均总资产</p>
        <p class="paragraph">（2）平均存货/销货成本</p>
        <p class="paragraph">3.信贷比率</p>
        <p class="paragraph">（1）流动资产/流动负债</p>
        <p class="paragraph">（2）总负债/股东权益</p>
        <p class="paragraph">（3）EBIT/利息</p>
        <p class="paragraph">4.增长比率</p>
        <p class="paragraph">（1）销售增速%</p>
        <p class="paragraph">（2）EBIT增速%</p>
        <p class="paragraph">（3）EBITDA增速%</p>
        <p class="paragraph">（4）税前利润增速%</p>
        <p class="paragraph">（5）净利润增速%</p>  
        <br>            
        <p class="paragraph">大多数行业的财务比率长期来看都表现得很一致，当财务比率分析中出现不合拍的结果时，比率失调或极度差异的原因就成为进一步研究该公司的焦点，会计学教授约翰.迈尔在50年前提出的四步分析法如今仍在使用。四步调查财务比率的变化：</p>
        <p class="paragraph">（1）将相关公司的财务比率与行业标准及历史情况做比较</p> 
        <p class="paragraph">（2）检查比率的历史趋势</p> 
        <p class="paragraph">（3）分析比率中各个组成部分的表现</p> 
        <p class="paragraph">（4）关注影响各成分变量的经营背景发生了什么定性变化</p> 
        <p class="paragraph">关于可供比较的行业数据，我们从行业周期数据中取出的指标包括：行业名、行业代码、平均负债率、平均ROE、总营收、总扣非净利润等。</p>                         

     </section>
    </article>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "CA_Summary",
 data() {
   return {}
 },
 components:{}
}
</script>

<style scoped lang='scss'>
.paragraph{
  text-indent: 2em;
  margin:5px 5px;
}
</style>