<template>
 <div class="">
   <ol class="container" v-if="Object.keys(analysisResult).length > 0">
     <li>判断宏观场景</li>
     <div class="proCharts" ref="currencyandCreditChart"></div>  
     <li>关键经济指标</li>
     <div class="proCharts" ref="PPIChart" ></div> 
     <li>FED估值模型</li>
     <div class="proCharts" ref="FEDModelChart" ></div> 
   </ol>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "Financial_and_Business_Cycles",
 data() {
   return {
     analysisResult:{}
   }
 },
 updated(){
   this.$nextTick(()=>{
     if(Object.keys(this.analysisResult).length>0){
       this.currencyandCreditChart()
       this.PPIChart()
       this.FEDModelChart()
     } 
   })
 },
 methods:{
   currencyandCreditChart(){
     var xdata = [],ydata=[],zdata=[]
      for(var i=0;i<Object.keys(this.analysisResult.financial_business_cycles).length;i++){
        xdata.splice(0,0,this.analysisResult.financial_business_cycles[i].time)
      }
      for(i=0;i<Object.keys(this.analysisResult.financial_business_cycles).length;i++){
        ydata.splice(0,0,this.analysisResult.financial_business_cycles[i].M2_YOY)
      }
      for(i=0;i<Object.keys(this.analysisResult.financial_business_cycles).length;i++){
        zdata.splice(0,0,this.analysisResult.financial_business_cycles[i].incremental_scale_of_social_financing_YOY)
      }          
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "M2同比（%）",
              position: "left"
             },
            {
              type: 'value',
              name: "社会融资规模同比（%）",
              position: "right"
             }             
          ],  
          series: [
            {
              name: "M2同比",
              data: ydata,
              yAxisIndex: 0,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "社会融资规模同比",
              data: zdata,
              yAxisIndex: 1,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }
          ]
        }
      let myChart =this.$echarts.init(this.$refs.currencyandCreditChart);
      myChart.setOption(option)
   },
   PPIChart(){
     var xdata = [],ydata=[]
      for(var i=0;i<Object.keys(this.analysisResult.financial_business_cycles).length;i++){
        xdata.splice(0,0,this.analysisResult.financial_business_cycles[i].time)
      }
      for(i=0;i<Object.keys(this.analysisResult.financial_business_cycles).length;i++){
        ydata.splice(0,0,this.analysisResult.financial_business_cycles[i].PPI_annual_rate)
      }
        
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "%",
              position: "left"
             },
          ],  
          series: [
            {
              name: "PPI年率",
              data: ydata,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }
          ]
        }
      let myChart =this.$echarts.init(this.$refs.PPIChart);
      myChart.setOption(option)
   },
   FEDModelChart(){
     var xdata = [],ydata=[]
      for(var i=0;i<Object.keys(this.analysisResult.fed_model).length;i++){
        xdata.splice(0,0,this.analysisResult.fed_model[i].time.slice(0,10))
      }
      for(i=0;i<Object.keys(this.analysisResult.fed_model).length;i++){
        ydata.splice(0,0,this.analysisResult.fed_model[i].stock_bond_spread.toFixed(4))
      }
       
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "%",
              position: "left",         
             },
      
          ],  
          series: [
            {
              name: "股债利差",
              data: ydata,
              yAxisIndex: 0,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              },
              markLine:{
                symbol: ['none','none'],
                data: [ 
                  {
                    yAxis: this.analysisResult.fed_model[0].avg_spread.toFixed(4),
                    name:"均值",
                    // label:{
                    //     normal:{
                    //         formatter:function(params){
                    //             return "均值：" + params.value
                    //         }
                    //     }
                    // }
                  },
                  {
                    yAxis: this.analysisResult.fed_model[0].avg_spread_add_std_spread.toFixed(4),
                    name:"均值加一倍标准差"
                    // label:{
                    //     normal:{
                    //         formatter:function(params){
                    //             return "均值加一倍标准差：" + params.value
                    //         }
                    //     }
                    // }                    
                  },
                  {
                    yAxis: this.analysisResult.fed_model[0].avg_spread_add_double_std_spread.toFixed(4),
                    name:"均值加两倍标准差"
                    // label:{
                    //     normal:{
                    //         formatter:function(params){
                    //             return "均值加两倍标准差：" + params.value
                    //         }
                    //     }
                    // }                    
                  },  
                  {
                    yAxis: this.analysisResult.fed_model[0].avg_spread_minus_std_spread.toFixed(4),
                    name:"均值减一倍标准差"
                    // label:{
                    //     normal:{
                    //         formatter:function(params){
                    //             return "均值减一倍标准差：" + params.value
                    //         }
                    //     }
                    // }                    
                  }, 
                  {
                    yAxis: this.analysisResult.fed_model[0].avg_spread_minus_double_std_spread.toFixed(4),
                    name:"均值减两倍标准差"
                    // label:{
                    //     normal:{
                    //         formatter:function(params){
                    //             return "均值减两倍标准差：" + params.value
                    //         }
                    //     }
                    // }                    
                  }                                                                        
                ]
              }              
            },

          ]
        }
      let myChart =this.$echarts.init(this.$refs.FEDModelChart);
      myChart.setOption(option)
   },      
 },
 components:{},
 mounted(){
   this.$http.financial_business_cycle_detail().then(res=>{
     this.analysisResult = res.data
    }).catch(err=>{
        console.log(err)
    })         
 }
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 10px;
    margin-left: 30px;
    font-size: 16px;
}
.proCharts{
  width: 100%;
  height: 500px;
  // background: rgb(14, 51, 129);
}
</style>