<template>
 <div class="">
    <el-table
        :data="books"
        style="width: 1651px"
        border>
        <el-table-column
        prop="id"
        label="序号"
        width="50"
        >
            <template slot-scope="scope">{{scope.$index + 1}}</template>
        </el-table-column>
        <el-table-column
        prop="bookName"
        label="书名"
        width="400">
        </el-table-column>
        <el-table-column
        prop="arthor"
        label="作者"
        width="400">
        </el-table-column>
        <el-table-column
        prop="press"
        label="出版社"
        width="400">
        </el-table-column>
        <el-table-column
        label="封面"
        width="400">
        <template slot-scope="scope">
           <img :src="scope.row.avatar" style="width:200px;height:200px" alt="">
        </template>
        </el-table-column>    
    </el-table>
 </div>
</template>

<script type="text/ecmascript-6">
import {Table,TableColumn} from 'element-ui'
export default {
 name: "Books",
 data() {
   return {
     books:[
        {
         bookName: "2024 Cfa Program Curriculum Level II",
         arthor: "CFA Institute",
         press: "Wiley",
         avatar: require('@/assets/books/CFA.jpg')              
        },        
        {
         bookName: "新巴菲特法则:震荡市中的大师策略",
         arthor: "（美）玛丽·巴菲特,大卫·克拉克",
         press: "广东省出版集团，广东经济出版社",
         avatar: require('@/assets/books/新巴菲特法则.jpg')              
        },
        {
         bookName: "股市进阶之道：一个散户的自我修养",
         arthor: "李杰",
         press: "中国铁道出版社",
         avatar: require('@/assets/books/股市进阶之道.jpg')  
        },
        {
         bookName: "华尔街证券分析：股票分析与公司估值",
         arthor: "（美）杰弗里 C. 胡克(Jeffrey C. Hooke)、林东、刘潇然",
         press: "机械工业出版社",
         avatar: require('@/assets/books/华尔街证券分析.jpg')
        },
        {
         bookName: "逆向投资：邓普顿的长赢投资法",
         arthor: "(美)劳伦·C.邓普顿,(美)斯科特·菲利普斯",
         press: "中信出版社",
         avatar: require('@/assets/books/逆向投资.jpg')
        },
        {
         bookName: "周期：投资机会、风险、态度与市场周期",
         arthor: "[美]霍华德.马克斯,刘建位",
         press: "中信出版集团,中信出版社",
         avatar: require('@/assets/books/周期.jpg')
        },  
        {
         bookName: "追寻价值之路：1990～2023年中国股市行情复盘",
         arthor: "燕翔",
         press: "经济科学出版社",
         avatar: require('@/assets/books/价值之路.jpg')
        },
        {
         bookName: "美股70年：1948～2018年美国股市行情复盘",
         arthor: "燕翔，战迪，许茹纯，朱成成",
         press: "经济科学出版社",
         avatar: require('@/assets/books/美股70年.jpg')
        },
        {
         bookName: "巴菲特的估值逻辑：20个投资案例深入复盘",
         arthor: "[美]陆晔飞",
         press: "机械工业出版社",
         avatar: require('@/assets/books/估值逻辑.jpg')
        },
        {
         bookName: "逆向投资策略",
         arthor: "[美]大卫·德雷曼 郑磊 译",
         press: "机械工业出版社",
         avatar: require('@/assets/books/逆向投资策略.jpg')
        },
        {
         bookName: "股市天才：发现股市利润的秘密隐藏之地",
         arthor: "[美]乔尔·格林布拉特",
         press: "中国青年出版社",
         avatar: require('@/assets/books/股市天才.jpg')
        },
        {
         bookName: "憨夺型投资者",
         arthor: "[美]莫尼斯·帕伯莱",
         press: "机械工业出版社",
         avatar: require('@/assets/books/憨夺型投资者.jpg')
        }, 
        {
         bookName: "如何找到100倍回报的股票：基于365只100倍股的研究结果",
         arthor: "（美）克里斯托弗·迈耶，王汀汀 译",
         press: "中国青年出版社",
         avatar: require('@/assets/books/100倍股.jpg')
        },                                                                                       
     ]
   }
 },
 components:{
   [Table.name]:Table,
   [TableColumn.name]:TableColumn      
 }
}
</script>

<style scoped lang='scss'>
</style>