<template>
 <div class="">
    <!-- <div v-if="Object.keys(analysisResult).length>0"> -->
    <div v-if="hasResult">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="重要指标" name="first"></el-tab-pane>
        <el-tab-pane label="龙头集中度" name="second"></el-tab-pane>
        <el-tab-pane label="跌幅占比" name="third"></el-tab-pane>
        <el-tab-pane label="周期位置" name="fourth"></el-tab-pane>
        <el-tab-pane label="超额收益" name="fifth"></el-tab-pane>

      </el-tabs>        
      <router-view></router-view>
    </div>  
    <div v-if="searchSymbol == undefined || searchSymbol == null || searchSymbol ==''">
      <p>请选择行业！</p>
    </div>
 </div>
</template>

<script type="text/ecmascript-6">
import {Tabs,TabPane} from 'element-ui'
export default {
 name: "Indicator_Result",
 data() {
   return {
     activeName:"first",
     analysisResult:{},
     searchSymbol:'',
     hasResult:false       
   }
 },
 methods: {
   handleClick(tab) {
     if(tab.index == 0){
       this.$router.push({name:'important_indicators'})           
     }
     else if(tab.index == 1){
       this.$router.push({name:'concentration_ratio'})  
     }
     else if(tab.index == 2){
       this.$router.push({name:'proportion_of_decline'})
     }
     else if(tab.index == 3){
       this.$router.push({name:'periodic_position'})
     }
     else{
       this.$router.push({name:'excess_return'})
     }
   }
 }, 
 components:{
   [Tabs.name]:Tabs,
   [TabPane.name]:TabPane  
 },
 mounted(){
   this.searchSymbol = this.$store.state.searchIndustry
   if(this.searchSymbol){
     let params = new URLSearchParams()
     params.append('symbol',this.searchSymbol)
     this.$http.industry_indicator_detail(params).then(res=>{
       this.analysisResult = res.data
       this.$store.state.bi_result = res.data
       this.hasResult = true
       }).catch(err=>{
         console.log(err)
         this.analysisResult = {}
       })
   }
   else{
     this.hasResult = false
   }  
 } 
}
</script>

<style scoped lang='scss'>
</style>