<template>
 <div>
  <div class="navigator">
    <span><router-link :to="{name:'four_qualitative_questions'}" class="four">定性的4个问题</router-link></span>
    <span><router-link :to="{name:'six_quantitative_questions'}" class="six">定量的6个问题</router-link></span>
    <span><router-link :to="{name:'qualitative_and_quantitative_questions'}" class="one">定性和定量的1个问题</router-link></span>
   
   
   
  </div>
  <div>
   <router-view></router-view>
  </div>
 </div>

</template>

<script type="text/ecmascript-6">
export default {
 name: "What_to_Buy",
 data() {
   return {}
 }
}
</script>

<style scoped lang='scss'>
.navigator{
  width: 400px;
  margin: 5px 5px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  .four{
    color: coral;
  }
  .six{
    color: chartreuse;
  }
  .one{
    color: deepskyblue;
  }
}
</style>