<template>
 <div class="">
    <div v-if="hasResult"> 
      <ol class="container">
        <li>行业指数对比市场（中证500指数）的累计超额收益率，以及和十年期国债利率的比较</li>
        <div class="proCharts" ref="ERChart" ></div> 
        <p>注意：累计超额收益率从起始日100开始，累计计算行业指数对比中证500指数起始日的超额收益率，高于100为累计正超额收益，低于100为累计负超额收益。</p>                            
      </ol>
    </div>     
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "Excess_Return",
 data() {
   return {
     analysisResult:{},
     hasResult:false    
   }
 },
 methods:{
   ERChart(){
     var xdata = [],ydata=[],zdata=[]
      for(var i=0;i<Object.keys(this.analysisResult.business_indicator_excess_return).length;i++){
        xdata.splice(0,0,this.analysisResult.business_indicator_excess_return[i].time.slice(0,7))
      }
      for(i=0;i<Object.keys(this.analysisResult.business_indicator_excess_return).length;i++){
        ydata.splice(0,0,this.analysisResult.business_indicator_excess_return[i].accumulated_excess_return)
      }
      for(i=0;i<Object.keys(this.analysisResult.business_indicator_excess_return).length;i++){
        zdata.splice(0,0,this.analysisResult.business_indicator_excess_return[i].bond)
      }   
           
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "值",
              position: "left"
             },
            {
              type: 'value',
              name: "%",
              position: "right"
             }             
          ],  
          series: [
            {
              name: "累计超额收益率",
              data: ydata,
              yAxisIndex: 1,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "十年期国债利率",
              data: zdata,
              yAxisIndex: 0,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }         
          ]
        }
      let myChart =this.$echarts.init(this.$refs.ERChart);
      myChart.setOption(option)
   },     
 },
 updated(){
   this.$nextTick(()=>{
     if(Object.keys(this.analysisResult).length>0){
       this.ERChart()

     } 
   })
 },  
 components:{},
 mounted(){
   this.analysisResult = this.$store.state.bi_result
   if(this.analysisResult.business_indicator_excess_return && Object.keys(this.analysisResult.business_indicator_excess_return).length > 0){
     this.hasResult = true
   }
   else{
    this.hasResult = false
   }
 } 
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 20px;
    margin-left: 30px;
    font-size: 16px;
}
.proCharts{
  width: 100%;
  height: 500px;
  // background: rgb(14, 51, 129);
}
</style>