<template>
 <div class="">
    <article>
      <header class="header">
        <h2>猎手之道：取势、明道、优术</h2>
        <h1><time datetime="2024-3-3">发布日期：2024年3月3日</time></h1>
      </header>
      <section>
        <p class="paragraph">从事股票市场投资十多年了，从什么都不懂跟风乱投的懵懂时期，到参加CFA培训系统学习的打基础阶段，再到接触量化投资、机器学习等业界炙手可热的前沿领域，逐渐对打造一个属于自己的“投资系统”或者“投资框架”，形成对自己投资行为有效规范和指导的感觉越来越强烈和紧迫。</p>
        <p class="paragraph">其实很早就听人说，炒股要有自己的投资系统或者交易系统，定好止盈止损点，并且守好纪律遵照规则去操作，才能在七输二平一胜的市场激烈竞争中存活下来。没有投资系统，就像盲人摸象，每个人只看到市场的局部妄加揣测，主观片面的判断导致一叶障目，很容易做出错误的投资决策。但是，面对错综复杂的投资市场和浩如烟海的市场信息，怎么才能打造一个相对完善有效的投资系统或者投资框架，更全面更客观地去指导我们的投资行为呢？</p>
        <p class="paragraph">“取势、明道、优术”取自中国古代道家哲学，是中国人的传统智慧思想，可以说也是一种指导人生和事业的应用方法论。根据这套哲学思想的指引，我们建设了一个自上而下的“投资框架”，提出一个思维模式和一套工具箱，希望起到抛砖引玉的作用，给各位投资人一个参考和研究的样板。</p>
        <p class="paragraph">取势，即顺势而为，在投资市场里就是要对宏观经济变量有所认识，能够对经济运行的方向和趋势做出基本的判断。简单来说，就是要密切跟踪一些宏观经济的指标变化，了解历史上市场周期转换的规律和市场管理者调控手段的影响。顺势而上则事半功倍，逆势而动则事倍功半。我们一切投资行为需要首先判断宏观趋势，长看趋势，做对趋势，这是能不能投资成功的首要条件和上层建筑。</p>
        <p class="paragraph">明道，就是要明确自己的战略和方向，就是选择，尤其是选择不做什么。在投资市场里对应两点，第一是了解行业发展的趋势，第二是具备研读财报的能力，两者的目的都是为了筛选和过滤。行业发展有其自然的规律和运转的周期，我们需要从行业的各项指标中做出判断，行业目前处在哪一个运行周期，行业在历史的发展中处于低谷还是高峰。在行业中竞争的公司也有其发展的规律，是处于成长期还是衰退期，还是已经步入稳定发展的平稳期？这需要我们具备娴熟的阅读财报的能力，并提炼出关键的财务指标，判断公司在市场竞争中未来发展的方向。建设投资系统，跟踪行业趋势，可以帮助我们分辨出不适宜投资的行业和公司，投资人只需要筛选和过滤，避免浪费大量的精力和踩坑。</p>
        <p class="paragraph">优术，术就是具体的方法论，对应到投资市场就是具体的投资策略。我们通过投资系统判断宏观经济运行的方向，筛选出适合投资的行业和公司，下一步就是要制定具体的投资策略。凡事预则立，不预则废。不断提升自己的能力，探索和积累实用的策略，积淀适合于自己的经验，持续更新所需要的信息技术知识技能，是每个成功投资人必须经历的过程。设计一套可以取之即用的投资策略，指引自己的投资行为，严格遵守投资纪律，并在不断变化的市场环境中跟踪、回溯和优化，才有可能在激烈的市场竞争中有所收获。</p>
        <p style="text-align:center;margin-top:20px;margin-bottom:20px"><img src="../assets/金字塔.png" alt="" ></p>
        <p class="paragraph">综上所述，我们提出一个思维模式，即充分利用丰富多样的宏观经济指标、行业周期信息和企业财务数据，尝试构建了一个自上而下的高频跟踪模型和框架，能够月度跟踪市场运行特征、行业运作规律和企业盈亏数据，并在日频给出企业估值变化的预测信号。</p>
        <p class="paragraph">在这个思维模式的指引下，我们收集网上公开信息和数据，建设了一套数据接口，并在数据接口的基础上提炼出一套投资策略工具箱。工具箱从价值投资、逆向投资、周期投资和量化投资等方向探索了若干实用的投资策略和投资标的筛选的方法，而且还在不断优化、迭代和增加中，希望能为各位投资人提供一种思路，帮助大家找到适合自己的投资系统。</p>
        <p style="text-align:center;margin-top:20px;margin-bottom:20px"><img src="../assets/系统框架.png" alt="" width="951px" height="400px"></p>
        <p class="paragraph">总而言之，一个完整的投资系统虽然不能保证投资人一定能从市场获利，但是至少能比市场上的其他对手知道得更多，投资行为有的放矢，投资成功了知道原因，投资失败了继续优化，那么肯定大大提高了长期投资的成功率。</p>
        <p class="paragraph">除此以外，有没有成功投资的技巧或者方向呢？我们通过大量的阅读和网上的探索，引用和总结了成功投资的三个可能的线索和方向，以供参考。</p>
        <p class="paragraph">1、值得长期投资的企业应“有矛有盾”。矛就是企业的成长性，未来可能大幅增长但目前还未体现盈利的增值业务；盾就是企业立足市场之本，是企业稳定经营，已经得到市场认可的主要业务。</p>
        <p class="paragraph">2、逆向策略可能在这种情况下最有效：在某个大事件或者黑天鹅的影响下，出现对手盘“恐慌性出逃”，发生流动性坍塌，即“典型赔率不对称下出现的两元事件性投资机会”。</p>
        <p class="paragraph">3、观察市场上什么在赚大钱。然后看其持续性，并分析上下游有无低估的投资标的。</p>
        <p class="paragraph">最后，有没有一种标准，可以判断自己投资水平是高还是低呢？剔除没有任何系统的盲目投资，我们认为投资能力大概分为三个等级：</p>
        <p class="paragraph">第一级，有一个投资系统或者框架，具有系统思维和筛选目标的能力，简单来说就是“筛选能力”；</p>
        <p class="paragraph">第二级，筛选出投资标的后，能够分析其生意模式是什么（盈利模式），并且能发现未来盈利增长爆发的可能性（催化剂）。简单来说就是“知道企业现在怎么赚钱，未来盈利怎么增长”；</p>
        <p class="paragraph">第三级，长期跟踪，不断证实和证伪第二级的分析。</p>
        <p class="paragraph">建设一个有效的投资系统只是第一步，而且还需要不断的迭代和优化。这就像武侠小说里的练功升级，既需要师父带进门(成功投资人的历史经验和经典案例)，获得秘笈（经典的投资书籍和基础的投资知识），还需要不懈的坚持和努力，才有可能在长期的实践和历练中成长起来。</p>
        <p class="paragraph">希望本网站能为各位投资人的成长之路提供一些借鉴和参考。</p>
     </section>
    </article>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "Index",
 data() {
   return {}
 },
 components:{}
}
</script>

<style scoped lang='scss'>
.header{
  text-align: center;
  margin-bottom: 20px;
}
.paragraph{
  text-indent: 2em;
}
</style>