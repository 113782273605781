<template>
 <div class="">
    <article>
      <section>
        <p class="paragraph">财务特征分析的理论启发自李杰老师的《股市进阶之道：一个散户的自我修养》，并在李老师提出的“好生意，好公司，好价格”的原则基础上做了一些扩展和补充。财务特征分析主要就是通过行业信息和财报数据，对一个上市企业的内在价值、经营特征和估值假设等方面做一个定量的分析，总结其财务上的特征并推演是否值得投资。</p>
        <p class="paragraph"><b>第一个步是“好生意”</b>，即分析企业的生意模式是否优秀，是否存在适合投资的内在价值。这里使用了李老师的“DCF三要素”的思维体系，并引入了一些财务指标以做鉴别。</p>
        <p class="paragraph"><b>1、经营存续期</b></p>
        <p class="paragraph">企业的经营存续期必须要长才可能产生长期的复利。我们通过上市时间、所在行业（是否消费、能源和医药）、无形资产占比、资本开支占比、固定资产占比等指标进行判断，重点甄别出“强周期性+重资产+低差异化”的行业和公司，对此类公司的投资要格外慎重。</p>
        <p class="paragraph"><b>2、现金创造力</b></p>
        <p class="paragraph">企业的经营存续期内的最佳经营结果应该是产生大量的自由现金，企业的内在价值就是这些自由现金的折现。我们通过三个方面来进行判断：</p>
        <p class="paragraph">（1）投资环节的资本需求</p>
        <p class="paragraph">指标：固定资产占总资产占比、资本开支/销售收入</p>
        <p class="paragraph">（2）销售模式中的现金含量</p>
        <p class="paragraph">指标：应收账款/营业收入、预收账款/营业收入、前5客户收入占比</p>
        <p class="paragraph">（3）日常运营环节的资金结构</p>
        <p class="paragraph">指标：企业的现金周转周期=存货周转天数+应收账款周转天数-应付账款拖延天数</p>
        <p class="paragraph"><b>3、经营周期定位</b></p>
        <p class="paragraph">每一个企业都有其生命周期，一个典型发展周期包括了初创期、成长期、成熟期和衰退期。企业不同的发展阶段有不同的财务和现金流特征，我们需要对企业所处的生命周期进行定位。这里主要采用的分析指标是企业十年营收和十年ROE的变化趋势。</p>
        <br>
        <p class="paragraph"><b>第二步是“好公司”</b>，判断一个公司是否为好公司，主要通过识别这个企业的经营特性，即经营特性分析。这里我们使用了对净资产收益率的结构分析（杜邦分析法）的<b>三个指标：销售净利润率、总资产周转率和权益乘数</b>，并分析这三个指标十年的变化趋势。高利润率是指净利率25%以上，高周转率是指1以上；低利润是指10%以下，低周转率是指0.5以下。介于这之间都属于中等水准。</p>
        <p class="paragraph">根据这三个指标，可以将企业的经营特征分为三大类：高利润低周转、低利润高周转和杠杆型。通过对经营的财务特征进行分类，我们就可以更清晰地理解一个企业，然后可以更深入地思考这个企业运营的关键点，警惕企业的经营策略与其经营特征不一致的情况。</p>
        <p class="paragraph">我们在上述基础上还补充了企业所在行业的估值和净利率、十年净运营资产、十年EV/EBIT、十年EV/FCF、十年资本开支及上述数据的变化率，进一步揭示企业经营的财务特征。</p>
        <br>
        <p class="paragraph"><b>第三步是“好价格”</b>，一个拥有好的生意模式并经营稳健的企业如果卖价昂贵、估值很高，那么就不是投资的好时机。我们提出三种估值假设：</p>
        <p class="paragraph"><b>1、资本开支的敏感性分析（企业是持续型还是成长型？）</b></p>
        <p class="paragraph">用0.25、0.5、0.75分别估算，加回自由现金流再进行估值。</p>
        <p class="paragraph">计算方法：利用过去一年资本开支和过去一年自由现金流，敏感性分析后重算P/FCF。</p>
        <p class="paragraph"><b>2、分部估值法</b></p>
        <p class="paragraph">分专业分部的行业历史平均估值乘以当前分部利润再汇总。</p>
        <p class="paragraph">计算方法：利用各分部的收入和利润、每个专业对应行业的过去十年的平均PE，计算总市值，比较当前市值。</p>
        <p class="paragraph"><b>3、DCF极值估值法</b></p>
        <p class="paragraph">利用过去一年的自由现金流，假设后续连续10年每年30%的增速，折现率用当前无风险利率的1倍，计算出未来10年预计的现金流，再折现回来，看FCF/P（P=市值）的比率。这是一种极值假设，用来反推企业当前估值是否合理。</p>
        <p class="paragraph">上述内容主要是在定量的角度去分析一个上市企业经营的财务特征，但是企业的经营特征不仅仅是财务角度的，还有业务角度的，同样的财务经营特征，在业务特征上可能相差极大。拿到一个企业的时候，集中火力去思考体现这个企业关键运营水平的“点”在哪里，这个生意未来发展的关键逻辑和观测指标是企业研究环节最为重要的功课。所谓对一个生意的理解，除了对商业价值的理解之外，其实很大程度上就取决于对关键运营点的认识。因此，我们使用的“财务特征分析”只是帮助投资人从企业财务特征的角度去阅读财报，分析大致的规律，无法代替后续的企业业务特征分析，这需要投资人自行进行思考、理解和持续关注。</p>

     </section>
    </article>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "FFA_Summary",
 data() {
   return {}
 },
 components:{}
}
</script>

<style scoped lang='scss'>
.paragraph{
  text-indent: 2em;
  margin:5px 5px;
}
</style>