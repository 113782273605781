<template>
 <div class="container" v-if="Object.keys(analysisResult).length > 0">
   <ol>
     <li>绝对数量变化</li> 
       <div>
         <table class="abs_table" border="1">
           <thead>
             <tr>
               <th>时间</th>  
               <th v-for='item in analysisResult.last_three_year_abs' :key='item.time' class="content">{{item.time.substring(0,4)}}</th> 
             </tr>
           </thead>
           <tbody>
             <tr>
               <td colspan="4"><b>利润表数据（单位：百万元）</b></td>
             </tr>
             <tr>
               <td>净销售收入的变化</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.revenue_abs_chg}}</td> 
             </tr>
             <tr>
               <td>销货成本的变化</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.cogs_abs_chg}}</td> 
             </tr>
              <tr>
               <td>销售费用、一般费用及管理费用的变化</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.sga_abs_chg}}</td> 
             </tr>
              <tr>
               <td>研发费用的变化</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.rd_abs_chg}}</td> 
             </tr>
              <tr>
               <td>折旧的变化</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.dep_abs_chg}}</td> 
             </tr>                         
              <tr>
               <td>息税前利润（EBIT）的变化</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.ebit_abs_chg}}</td> 
             </tr> 
             <tr>
               <td>EBITDA的变化</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.ebitda_abs_chg}}</td> 
             </tr>
             <tr>
               <td>债务利息（利息费用）的变化</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.int_abs_chg}}</td> 
             </tr>
             <tr>
               <td>税前利润（EBT）的变化</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.ebt_abs_chg}}</td> 
             </tr> 
             <tr>
               <td>所得税的变化</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.tax_abs_chg}}</td> 
             </tr>
             <tr>
               <td>净利润的变化</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.net_income_abs_chg}}</td> 
             </tr> 
             <tr>
               <td>每股净利润的变化</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.eps_abs_chg}}</td> 
             </tr> 
             <tr>
               <td colspan="4"><b>资产负债表数据（单位：百万元或百万股）</b></td>
             </tr>
             <tr>
               <td>现金的变化</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.cash_abs_chg}}</td> 
             </tr> 
             <tr>
               <td>净营运资本的变化</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.net_working_capital_abs_chg}}</td> 
             </tr> 
             <tr>
               <td>总资产的变化</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.total_asset_abs_chg}}</td> 
             </tr> 
             <tr>
               <td>长期负债，减本年到期部分的变化</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.long_term_debt_minus_the_portion_this_year_abs_chg}}</td> 
             </tr> 
             <tr>
               <td>股东权益的变化</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.equity_abs_chg}}</td> 
             </tr> 
             <tr>
               <td>股本的变化</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.total_share_abs_chg}}</td> 
             </tr> 
             <tr>
               <td colspan="4"><b>现金流量表数据（单位：百万元）</b></td>
             </tr>                                                        
             <tr>
               <td>经营活动产生的净现金</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.net_cash_from_operation}}</td>
             </tr>
             <tr>
               <td>投资活动使用的净现金</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.net_cash_from_investment}}</td>
             </tr> 
             <tr>
               <td>经营现金流 （1+2）</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.operating_cash_flow}}</td>
             </tr> 
             <tr>
               <td>融资活动产生的净现金</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.net_cash_from_financing}}</td>
             </tr> 
             <tr>
               <td>净现金增加（减少）</td>
               <td v-for="item in analysisResult.last_three_year_abs" :key="item.time" class="content">{{item.net_cash}}</td>
             </tr>                                                                                                                                                                                                                  
           </tbody>
         </table>       
       </div> 
     <li>增长变化的百分比</li> 
       <div>
         <table class="abs_table" border="1">
           <thead>
             <tr>
               <th>时间</th>  
               <th v-for='item in analysisResult.last_three_year_pct' :key='item.time' class="content">{{item.time.substring(0,4)}}</th> 
             </tr>
           </thead>
           <tbody>
             <tr>
               <td colspan="4"><b>利润表数据（单位：%）</b></td>
             </tr>
             <tr>
               <td>净销售收入的百分比变化</td>
               <td v-for="item in analysisResult.last_three_year_pct" :key="item.time" class="content">{{item.revenue_pct_chg}}</td> 
             </tr>
             <tr>
               <td>销货成本的百分比变化</td>
               <td v-for="item in analysisResult.last_three_year_pct" :key="item.time" class="content">{{item.cogs_pct_chg}}</td> 
             </tr>
              <tr>
               <td>销售费用、一般费用及管理费用的百分比变化</td>
               <td v-for="item in analysisResult.last_three_year_pct" :key="item.time" class="content">{{item.sga_pct_chg}}</td> 
             </tr>
              <tr>
               <td>研发费用的百分比变化</td>
               <td v-for="item in analysisResult.last_three_year_pct" :key="item.time" class="content">{{item.rd_pct_chg}}</td> 
             </tr>
              <tr>
               <td>折旧的百分比变化</td>
               <td v-for="item in analysisResult.last_three_year_pct" :key="item.time" class="content">{{item.dep_pct_chg}}</td> 
             </tr>                         
              <tr>
               <td>息税前利润（EBIT）的百分比变化</td>
               <td v-for="item in analysisResult.last_three_year_pct" :key="item.time" class="content">{{item.ebit_pct_chg}}</td> 
             </tr> 
             <tr>
               <td>EBITDA的百分比变化</td>
               <td v-for="item in analysisResult.last_three_year_pct" :key="item.time" class="content">{{item.ebitda_pct_chg}}</td> 
             </tr>
             <tr>
               <td>债务利息的百分比变化</td>
               <td v-for="item in analysisResult.last_three_year_pct" :key="item.time" class="content">{{item.int_pct_chg}}</td> 
             </tr>
             <tr>
               <td>税前利润的百分比变化</td>
               <td v-for="item in analysisResult.last_three_year_pct" :key="item.time" class="content">{{item.ebt_pct_chg}}</td> 
             </tr> 
             <tr>
               <td>所得税的百分比变化</td>
               <td v-for="item in analysisResult.last_three_year_pct" :key="item.time" class="content">{{item.tax_pct_chg}}</td> 
             </tr>
             <tr>
               <td>净利润的百分比变化</td>
               <td v-for="item in analysisResult.last_three_year_pct" :key="item.time" class="content">{{item.net_income_pct_chg}}</td> 
             </tr> 
             <tr>
               <td>每股净利润的百分比变化</td>
               <td v-for="item in analysisResult.last_three_year_pct" :key="item.time" class="content">{{item.eps_pct_chg}}</td> 
             </tr> 
             <tr>
               <td colspan="4"><b>资产负债表数据（单位：%）</b></td>
             </tr>
             <tr>
               <td>现金的百分比变化</td>
               <td v-for="item in analysisResult.last_three_year_pct" :key="item.time" class="content">{{item.cash_pct_chg}}</td> 
             </tr> 
             <tr>
               <td>净营运资本的百分比变化</td>
               <td v-for="item in analysisResult.last_three_year_pct" :key="item.time" class="content">{{item.net_working_capital_pct_chg}}</td> 
             </tr> 
             <tr>
               <td>总资产的百分比变化</td>
               <td v-for="item in analysisResult.last_three_year_pct" :key="item.time" class="content">{{item.total_asset_pct_chg}}</td> 
             </tr> 
             <tr>
               <td>长期负债，减本年到期部分的百分比变化</td>
               <td v-for="item in analysisResult.last_three_year_pct" :key="item.time" class="content">{{item.long_term_debt_minus_the_portion_this_year_pct_chg}}</td> 
             </tr> 
             <tr>
               <td>股东权益的百分比变化</td>
               <td v-for="item in analysisResult.last_three_year_pct" :key="item.time" class="content">{{item.equity_pct_chg}}</td> 
             </tr> 
             <tr>
               <td>股本的百分比变化</td>
               <td v-for="item in analysisResult.last_three_year_pct" :key="item.time" class="content">{{item.total_share_pct_chg}}</td> 
             </tr> 
           </tbody>
         </table>       
       </div> 
     <li>归一化后的百分比</li>
       <div>
         <table class="abs_table" border="1">
           <thead>
             <tr>
               <th>时间</th>  
               <th v-for='item in analysisResult.last_three_year_common' :key='item.time' class="content">{{item.time.substring(0,4)}}</th> 
             </tr>
           </thead>
           <tbody>
             <tr>
               <td colspan="4"><b>利润表数据（单位：%）</b></td>
             </tr>
             <tr>
               <td>净销售收入 100%</td>
               <td v-for="item in analysisResult.last_three_year_common" :key="item.time" class="content">100</td> 
             </tr>
             <tr>
               <td>销货成本的百分比</td>
               <td v-for="item in analysisResult.last_three_year_common" :key="item.time" class="content">{{item.cogs_pct}}</td> 
             </tr>
              <tr>
               <td>销售费用、一般费用及管理费用的百分比</td>
               <td v-for="item in analysisResult.last_three_year_common" :key="item.time" class="content">{{item.sga_pct}}</td> 
             </tr>
              <tr>
               <td>研发费用的百分比</td>
               <td v-for="item in analysisResult.last_three_year_common" :key="item.time" class="content">{{item.rd_pct}}</td> 
             </tr>
              <tr>
               <td>折旧的百分比</td>
               <td v-for="item in analysisResult.last_three_year_common" :key="item.time" class="content">{{item.dep_pct}}</td> 
             </tr>                         
              <tr>
               <td>息税前利润（EBIT）的百分比</td>
               <td v-for="item in analysisResult.last_three_year_common" :key="item.time" class="content">{{item.ebit_pct}}</td> 
             </tr> 
             <tr>
               <td>EBITDA的百分比</td>
               <td v-for="item in analysisResult.last_three_year_common" :key="item.time" class="content">{{item.ebitda_pct}}</td> 
             </tr>
             <tr>
               <td>债务利息的百分比</td>
               <td v-for="item in analysisResult.last_three_year_common" :key="item.time" class="content">{{item.int_pct}}</td> 
             </tr>
             <tr>
               <td>税前利润的百分比</td>
               <td v-for="item in analysisResult.last_three_year_common" :key="item.time" class="content">{{item.ebt_pct}}</td> 
             </tr> 
             <tr>
               <td>所得税的百分比</td>
               <td v-for="item in analysisResult.last_three_year_common" :key="item.time" class="content">{{item.tax_pct}}</td> 
             </tr>
             <tr>
               <td>净利润的百分比</td>
               <td v-for="item in analysisResult.last_three_year_common" :key="item.time" class="content">{{item.net_income_pct}}</td> 
             </tr> 
             <tr>
               <td colspan="4"><b>资产负债表数据（单位：%）</b></td>
             </tr>
             <tr>
               <td>现金的百分比</td>
               <td v-for="item in analysisResult.last_three_year_common" :key="item.time" class="content">{{item.cash_pct}}</td> 
             </tr> 
             <tr>
               <td>净营运资本的百分比</td>
               <td v-for="item in analysisResult.last_three_year_common" :key="item.time" class="content">{{item.net_working_capital_pct}}</td> 
             </tr> 
             <tr>
               <td>总资产100%</td>
               <td v-for="item in analysisResult.last_three_year_common" :key="item.time" class="content">100</td> 
             </tr> 
             <tr>
               <td>长期负债，减本年到期部分的百分比</td>
               <td v-for="item in analysisResult.last_three_year_common" :key="item.time" class="content">{{item.long_term_debt_minus_the_portion_this_year_pct}}</td> 
             </tr> 
             <tr>
               <td>股东权益的百分比</td>
               <td v-for="item in analysisResult.last_three_year_common" :key="item.time" class="content">{{item.equity_pct}}</td> 
             </tr> 
           </tbody>
         </table>       
       </div> 
     <li>财务比率</li>
       <div>
         <table class="abs_table" border="1">
           <thead>
             <tr>
               <th>时间</th>  
               <th v-for='item in analysisResult.last_three_year_financial' :key='item.time' class="content">{{item.time.substring(0,4)}}</th> 
             </tr>
           </thead>
           <tbody>
             <tr>
               <td colspan="4"><b>盈利能力比率（单位：%）</b></td>
             </tr>
             <tr>
               <td>净利润/平均股东权益</td>
               <td v-for="item in analysisResult.last_three_year_financial" :key="item.time" class="content">{{item.net_income_divide_avg_equity}}</td> 
             </tr>
             <tr>
               <td>EBITDA/平均总资产</td>
               <td v-for="item in analysisResult.last_three_year_financial" :key="item.time" class="content">{{item.ebitda_divide_avg_total_asset}}</td> 
             </tr>
             <tr>
               <td colspan="4"><b>周转能力比率（单位：%）</b></td>
             </tr>             
              <tr>
               <td>销售额/平均总资产</td>
               <td v-for="item in analysisResult.last_three_year_financial" :key="item.time" class="content">{{item.revenue_divide_avg_total_asset}}</td> 
             </tr>
              <tr>
               <td>平均存货/销货成本</td>
               <td v-for="item in analysisResult.last_three_year_financial" :key="item.time" class="content">{{item.avg_inventory_divide_cogs}}</td> 
             </tr>
             <tr>
               <td colspan="4"><b>信贷比率（单位：%）</b></td>
             </tr>                         
              <tr>
               <td>流动资产/流动负债</td>
               <td v-for="item in analysisResult.last_three_year_financial" :key="item.time" class="content">{{item.curr_asset_divide_curr_debt}}</td> 
             </tr> 
             <tr>
               <td>总负债/股东权益</td>
               <td v-for="item in analysisResult.last_three_year_financial" :key="item.time" class="content">{{item.debt_divide_equity}}</td> 
             </tr>
             <tr>
               <td>EBIT/利息</td>
               <td v-for="item in analysisResult.last_three_year_financial" :key="item.time" class="content">{{item.ebit_divide_interest}}</td> 
             </tr>
             <tr>
               <td colspan="4"><b>增长比率（单位：%）</b></td>
             </tr> 
             <tr>
               <td>销售收入年化增速</td>
               <td class="content" colspan="3">{{analysisResult.last_three_year_financial[0].revenue_annual_growth}}</td> 
             </tr>
             <tr>
               <td>EBIT年化增速</td>
               <td class="content" colspan="3">{{analysisResult.last_three_year_financial[0].ebit_annual_growth}}</td> 
             </tr> 
             <tr>
               <td>EBITDA年化增速</td>
               <td class="content" colspan="3">{{analysisResult.last_three_year_financial[0].ebitda_annual_growth}}</td> 
             </tr> 
             <tr>
               <td>税前利润年化增速</td>
               <td class="content" colspan="3">{{analysisResult.last_three_year_financial[0].ebt_annual_growth}}</td> 
             </tr> 
             <tr>
               <td>净利润年化增速</td>
               <td class="content" colspan="3">{{analysisResult.last_three_year_financial[0].net_income_annual_growth}}</td> 
             </tr> 
           </tbody>
         </table>       
       </div> 
   </ol>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "Comparability_Data",
 data() {
   return {
     analysisResult:{}
   }
 },
 methods:{
   timeFormat(row, column, cellValue) {
     return cellValue.slice(0,4)
   },  
 },

 components:{},
 mounted(){
   this.searchSymbol = this.$store.state.searchsymbol
   let params = new URLSearchParams()
   params.append('symbol',this.searchSymbol)
   this.$http.comparability_analysis(params).then(res=>{
     this.analysisResult = res.data
     console.log(res.data);
     }).catch(err=>{
       console.log(err)
       this.analysisResult = {}
     })       
 }
}
</script>

<style scoped lang='scss'>
.container li{
  margin: 20px;
  margin-left: 30px;
  font-size: 16px;
}
.abs_table{
  margin-left: 30px;
  .content{
    padding-left: 100px;
    padding-right: 100px;
  }
}
</style>