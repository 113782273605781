<template>
 <div class="">
    <article>
      <section>
        <p class="paragraph">周期就是金融行业的潮汐，因为资金的流入流出而潮起潮落，随之带动资产价格的波动。资金的流入流出受宏观政策、市场偏好、行业周期和投资者情绪多个因素影响。我们参考了霍德华马斯克的《周期》，还有《追寻价值之路》、《美股70年》等书籍，以及网上的一些成功投资人的文章，归纳出一套股票市场周期分析的方法提供给投资者参考。周期分析包括金融与商业周期、市场周期、行业周期和公司自身的周期。</p>
        <p class="paragraph"><b>第一个是金融和商业周期。</b></p>
        <p class="paragraph">1、判断宏观场景。使用“货币”和“信用”来定义宏观场景。用两个宏观变量来设定两种状态，一共有4个组合，分别是：</p>
        <p class="paragraph">（1）宽信用+宽货币</p>
        <p class="paragraph">（2）宽信用+紧货币</p>
        <p class="paragraph">（3）紧信用+宽货币</p>
        <p class="paragraph">（4）紧信用+紧货币</p>
        <p class="paragraph">信用指标采用的是社会融资总量的同比增速；货币指标采用的是M2的同比增速。</p>
        <p class="paragraph">可以跟踪和分析各行业在历史不同宏观场景下的表现，判断行业在当前宏观场景下的业绩变化趋势。</p>
        <p class="paragraph">2、关键经济指标</p>
        <p class="paragraph">对股票市场而言，在所有宏观经济指标中，最重要的指标可能是PPI，因为PPI是与企业营收还有利润关联度最高的宏观经济指标，PPI同比负增长，意味着很多企业可能营收也会负增长。我们采用了PPI年率来跟踪这个关键指标。</p>
        <p class="paragraph">3、FED估值模型</p>
        <p class="paragraph">为了跟踪货币对股票市场的影响，我们引入了经典的FED估值模型，将股票的收益率和十年期国债收益率进行比较，利用两者之间的差值或者比值折算成股票投资仓位。</p>
        <p class="paragraph">获取中证500 10年每日的估值换算为收益率，以及十年期国债10年每日的利率，计算每日的股债利差的最高点、最低点、平均值和标准差，并划分5个区间：</p>
        <p class="paragraph">1、大于平均值+2倍标准差：股票投资仓位100%</p>
        <p class="paragraph">2、平均值+标准差至平均值+2倍标准差：股票投资仓位80%</p>
        <p class="paragraph">3、平均值-标准差至平均值+标准差：股票投资仓位50%</p>
        <p class="paragraph">4、平均值–2倍标准差至平均值-标准差：股票投资仓位20%</p>
        <p class="paragraph">5、小于平均值–2倍标准差：股票投资仓位0%</p>
        <br>
        <p class="paragraph"><b>第二个是市场周期。</b></p>
        <p class="paragraph">我们通过计算中证500估值处于什么历史位置，判断股票市场的周期。包括计算：</p>
        <p class="paragraph">1、中证500 10年每个季度的估值，计算最高点、最低点、平均值和标准差</p>
        <p class="paragraph">2、中证500 当前估值和处在的历史分位点</p>
        <br>
        <p class="paragraph"><b>第三个是行业周期。</b></p>
        <p class="paragraph">1、自上而下的跟踪模型</p>
        <p class="paragraph">利用丰富的宏观经济和行业数据，构建一个能够月度跟踪上市公司整体盈利变动的高频模型，从而得到领先的盈利预测信息。例如，使用国家统计局的工业企业利润数据来跟踪工业类上市公司利润情况（工业上市公司利润占全部A股公司约32%），用M2和金融机构信贷收支表数据跟踪商业银行资产规模变化、用利率数据判断商业银行净息差变化（商业银行利润占全部A股公司约42%），用商品房销售面积和施工面积来跟踪房地产和建筑业上市公司利润变化（两者占比约10%）等。我们估计全部A股上市公司利润总量的90%可以通过自上而下的宏观经济数据来跟踪。</p>
        <p class="paragraph">分行业的跟踪指标包括：</p>
        <p class="paragraph">1.金融（占全部上市公司净利润约49%）</p>
        <p class="paragraph">（1）银行：货币规模和利率指标等</p>
        <p class="paragraph">货币供应量、十年期国债</p>
        <p class="paragraph">（2）保险、券商：证券成交金额、保费收入等</p>
        <p class="paragraph">2.非金融（占全部上市公司净利润约51%）</p>
        <p class="paragraph">（1）工业：统计局工业企业利润数据等</p>
        <p class="paragraph">工业增加值增长</p>
        <p class="paragraph">（2） 地产、建筑：商品房销售面积、施工面积等</p>
        <p class="paragraph">房价指数</p>
        <p class="paragraph">（3）交运：客运货运量、运输价格指数等</p>    
        <p class="paragraph">客货运输量、航运运价、公路物流运价</p>
        <p class="paragraph">(4)其他</p>
        <p class="paragraph">自下而上的分析师一致预期</p>
        <p class="paragraph">基于央行或国家统计局公布的高频微观数据，我们可以对各细分行业利润增速进行月度跟踪并汇总，从而对A股上市公司归属母公司所有者利润增速进行判断。</p>
        <p class="paragraph">2、行业指标的变化趋势</p>
        <p class="paragraph">我们通过跟踪和分析申万二级行业的以下指标，判断行业所处的周期位置：</p>
        <p class="paragraph">1.重要指标</p>              
        <p class="paragraph">包括申万二级行业各行业下属包含的公司10年以来各季度总营收、总扣非净利润、平均负债、总资本开支、平均ROE及各项同比增速</p>
        <p class="paragraph">2.龙头集中度</p>    
        <p class="paragraph">申万二级行业各行业下属的三个龙头公司每季度的总营收对比所有公司总营收，三个龙头公司的平均ROE对比行业ROE</p>
        <p class="paragraph">3.跌幅占比</p>
        <p class="paragraph">计算申万二级行业各行业里当前股价离高点跌30%、50%、70%的公司所占百分比</p>
        <p class="paragraph">4.周期位置</p>
        <p class="paragraph">我们设定一个判断标准：</p>
        <p class="paragraph">（1）周期顶。一个申万二级行业过去10年的连续三个季度总利润同比为负，则为该行业一个周期的顶部，记录其开始季度时间；</p>
        <p class="paragraph">（2）周期底。一个申万二级行业过去10年的连续三个季度总利润同比为正，则为该行业一个周期的底部，记录其开始季度时间；</p>   
        <p class="paragraph">（3）计算方法。取出该时间段，计算该行业在这个时间段内所有季度的平均利润和平均估值。</p>              
        <p class="paragraph">（4）比较。该行业当前的指标，或该行业内待分析公司当前的指标，和上述平均指标进行比较。</p>
        <p class="paragraph">5.超额收益</p>    
        <p class="paragraph">各指数对比市场（中证500）的历史超额收益，画出趋势曲线，并与国债利率对比。</p>
        <br>
        <p class="paragraph"><b>第四个是公司自身的周期。</b></p>
        <p class="paragraph">采用我们其他工具，例如“财务特征分析”、“逆向分析”、“可比性分析”和“边缘企业和杠杆收购分析”等进行自下而上的指标分析，并与行业周期指标进行对比。</p>
        <p class="paragraph">另外，“漂亮50”蓝筹公司有两个重要特征：</p>
        <p class="paragraph">1、行业特征：所在行业产业集中度提升</p>
        <p class="paragraph">2、公司特征：公司ROE显著高于同行企业</p>
        <p class="paragraph">“漂亮50”是一个在行业产业集中度提高过程中，优质龙头企业（高ROE）价值重估的逻辑故事。</p>   




      </section>
    </article>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "Cycle_Summary",
 data() {
   return {}
 },
 components:{}
}
</script>

<style scoped lang='scss'>
.paragraph{
  text-indent: 2em;
  margin:5px 5px;
}
</style>