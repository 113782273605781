<template>
 <div class="">
   <div v-if="hasResult">
     <ol class="container">
        <li>行业里当前股价离高点跌30%、50%、70%的公司所占百分比</li>
            <el-table
                :data="analysisResult.business_indicator_stock_decline"
                style="width: 801px"
                border>
                <el-table-column
                prop="industry_code"
                label="行业代码"
                width="100">
                </el-table-column>
                <el-table-column
                prop="industry_name"
                label="行业名称"
                width="100">
                </el-table-column>
                <el-table-column
                prop="decline30"
                label="跌30%的公司占比（%）"
                width="200">
                </el-table-column>
                <el-table-column
                prop="decline50"
                label="跌50%的公司占比（%）"
                width="200">
                </el-table-column>            
                <el-table-column
                prop="decline70"
                label="跌70%的公司占比（%）"
                width="200">
                </el-table-column> 
                                                                   
            </el-table>
     </ol>
   </div>        
 </div>
</template>

<script type="text/ecmascript-6">
import {Table,TableColumn} from 'element-ui'
export default {
 name: "Proportion_of_Decline",
 data() {
   return {
     analysisResult:{},
     hasResult:false    
   }
 },
 components:{
   [Table.name]:Table,
   [TableColumn.name]:TableColumn    
 },
 mounted(){
   this.analysisResult = this.$store.state.bi_result
   if(this.analysisResult.business_indicator_stock_decline && Object.keys(this.analysisResult.business_indicator_stock_decline).length > 0){
     this.hasResult = true
   }
   else{
    this.hasResult = false
   }
 } 
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 20px;
    margin-left: 30px;
    font-size: 16px;
}
</style>