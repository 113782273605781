<template>
 <div class="">
   <ol class="container" v-if="Object.keys(analysisResult).length > 0">
     <li>投资环节的资本需求</li>
     <div>
        <p>（1）固定资产/总资产</p>
        <ul style="list-style-type:disc" class="detail">
            <li>公司过去一年的固定资产占比：{{analysisResult.stock_key_info_summary[0].fixed_assets_ratio}}</li>
            <li>公司过去十年的固定资产占比</li>
            <div class="proCharts" ref="fixedAssetsRatioChart" ></div>            
        </ul>
        <p>（2）资本开支/销售收入</p>
        <ul style="list-style-type:disc" class="detail">
            <li>公司过去一年的资本开支占比：{{analysisResult.stock_key_info_summary[0].capital_expenditure_ratio}}</li>
            <li>公司过去十年的资本开支占比</li>
            <div class="proCharts" ref="capitalExpenditureRatioChart"></div>
        </ul>                    
     </div>
     <li>销售模式中的现金含量</li>
     <div>
        <p>（1）应收账款/营业收入</p>
        <ul style="list-style-type:disc" class="detail">
            <li>公司过去一年的应收账款占比：{{analysisResult.stock_key_info_summary[0].account_receivable_ratio}}</li>
            <li>公司过去十年的应收账款占比</li>
            <div class="proCharts" ref="accountReceivableRatioChart" ></div>            
        </ul>
        <p>（2）预收账款/营业收入</p>
        <ul style="list-style-type:disc" class="detail">
            <li>公司过去一年的预收账款占比：{{analysisResult.stock_key_info_summary[0].deposit_received_ratio}}</li>
            <li>公司过去十年的预收账款占比</li>
            <div class="proCharts" ref="depositReceivedRatioChart" ></div>            
        </ul>                   
     </div>
     <div>
        <p>（3）前五客户收入占比</p>
        <p class="top_five">目前系统尚未能自动获取该部分数据，需要投资人自行翻阅财报。</p>
     </div>
     <li>日常运营环节的资金结构</li>
     <div>
       <ul style="list-style-type:disc" class="detail">
           <li>公司过去一年的现金周转周期（天）：{{analysisResult.stock_key_info_summary[0].cash_flow_cycle}}</li>
           <li>公司过去十年的现金周转周期（天）</li>
           <div class="proCharts" ref="cashFlowCycleChart" ></div>            
       </ul>     
     </div>

   </ol>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "Cash_Creativity",
 data() {
   return {
    analysisResult:{}    
   }
 },
 methods:{
   fixedAssetsRatioChart(){
    var xdata = [],ydata=[]
    for(var i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
    xdata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].time.slice(0,4))
    }
    for(i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
    ydata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].fixed_assets_ratio)
    }     
    var option =  {
        legend: {
        // orient: 'vertical',
        top: 10,
        },
        tooltip: {},
        xAxis: {
        type: 'category',
        data: xdata,
        name:"时间",
        axisTick: {
            alignWithLabel: true
        },
        },
        yAxis: {
        type: 'value',
        name: "固定资产占比"
        },
        series: [
            {
            name: "十年固定资产占比趋势图",
            data: ydata,
            type: 'line',
            label: {
            show: true,
            position: 'bottom',
            textStyle: {
                fontSize: 10
            }
            }
            }
        ]
    }
    let myChart =this.$echarts.init(this.$refs.fixedAssetsRatioChart);
    myChart.setOption(option)
   },
   capitalExpenditureRatioChart(){
    var xdata = [],ydata=[]
    for(var i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
    xdata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].time.slice(0,4))
    }
    for(i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
    ydata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].capital_expenditure_ratio)
    }     
    var option =  {
        legend: {
        // orient: 'vertical',
        top: 10,
        },
        tooltip: {},
        xAxis: {
        type: 'category',
        data: xdata,
        name:"时间",
        axisTick: {
            alignWithLabel: true
        },
        },
        yAxis: {
        type: 'value',
        name: "资本开支占比"
        },
        series: [
            {
            name: "十年资本开支占比趋势图",
            data: ydata,
            type: 'line',
            label: {
            show: true,
            position: 'bottom',
            textStyle: {
                fontSize: 10
            }
            }
            }
        ]
    }
    let myChart =this.$echarts.init(this.$refs.capitalExpenditureRatioChart);
    myChart.setOption(option)
   },
   accountReceivableRatioChart(){
    var xdata = [],ydata=[]
    for(var i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
    xdata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].time.slice(0,4))
    }
    for(i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
    ydata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].account_receivable_ratio)
    }     
    var option =  {
        legend: {
        // orient: 'vertical',
        top: 10,
        },
        tooltip: {},
        xAxis: {
        type: 'category',
        data: xdata,
        name:"时间",
        axisTick: {
            alignWithLabel: true
        },
        },
        yAxis: {
        type: 'value',
        name: "应收账款占比"
        },
        series: [
            {
            name: "十年应收账款占比趋势图",
            data: ydata,
            type: 'line',
            label: {
            show: true,
            position: 'bottom',
            textStyle: {
                fontSize: 10
            }
            }
            }
        ]
    }
    let myChart =this.$echarts.init(this.$refs.accountReceivableRatioChart);
    myChart.setOption(option)
   },
   depositReceivedRatioChart(){
    var xdata = [],ydata=[]
    for(var i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
    xdata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].time.slice(0,4))
    }
    for(i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
    ydata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].deposit_received_ratio)
    }     
    var option =  {
        legend: {
        // orient: 'vertical',
        top: 10,
        },
        tooltip: {},
        xAxis: {
        type: 'category',
        data: xdata,
        name:"时间",
        axisTick: {
            alignWithLabel: true
        },
        },
        yAxis: {
        type: 'value',
        name: "预收账款占比"
        },
        series: [
            {
            name: "十年预收账款占比趋势图",
            data: ydata,
            type: 'line',
            label: {
            show: true,
            position: 'bottom',
            textStyle: {
                fontSize: 10
            }
            }
            }
        ]
    }
    let myChart =this.$echarts.init(this.$refs.depositReceivedRatioChart);
    myChart.setOption(option)
   },
   cashFlowCycleChart(){
    var xdata = [],ydata=[]
    for(var i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
    xdata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].time.slice(0,4))
    }
    for(i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
    ydata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].cash_flow_cycle)
    }     
    var option =  {
        legend: {
        // orient: 'vertical',
        top: 10,
        },
        tooltip: {},
        xAxis: {
        type: 'category',
        data: xdata,
        name:"时间",
        axisTick: {
            alignWithLabel: true
        },
        },
        yAxis: {
        type: 'value',
        name: "现金周转周期"
        },
        series: [
            {
            name: "十年现金周转周期趋势图",
            data: ydata,
            type: 'line',
            label: {
            show: true,
            position: 'bottom',
            textStyle: {
                fontSize: 10
            }
            }
            }
        ]
    }
    let myChart =this.$echarts.init(this.$refs.cashFlowCycleChart);
    myChart.setOption(option)
   },           
 }, 
 components:{},
 mounted(){
   this.analysisResult = this.$store.state.ffa_result
 },
 updated(){
   if(Object.keys(this.analysisResult.stock_key_info_summary).length>0){
     this.fixedAssetsRatioChart()
     this.capitalExpenditureRatioChart()  
     this.accountReceivableRatioChart()
     this.depositReceivedRatioChart()
     this.cashFlowCycleChart()        
   }
 },    
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 10px;
    margin-left: 30px;
    font-size: 16px;
}
.detail{
  margin: 20px;    
}
.proCharts{
  width: 50%;
  height: 500px;
  // background: rgb(14, 51, 129);
}
.top_five{
    margin: 10px;
    margin-left: 40px;
    font-family: '宋体';
}
</style>