<template>
 <div class="">
   <p class="title"><b>高利润率是指净利率25%以上，高周转率是指1以上；低利润是指10%以下，低周转率是指0.5以下。介于这之间都属于中等水准。根据这三个指标，可以将企业的经营特征分为三大类：高利润低周转、低利润高周转和杠杆型。</b></p>
   <ol class="container" v-if="Object.keys(analysisResult).length > 0">
     <li>销售净利率</li>
     <div>
       <ul style="list-style-type:disc" class="detail">
         <li>公司过去一年的销售净利率：{{analysisResult.ten_year_business_character[0].profit_margin}}</li>
         <li>公司过去十年的销售净利率</li>
         <div class="proCharts" ref="profitMarginChart" ></div>
         <li>公司过去十年的平均销售净利率为：{{analysisResult.stock_key_info_summary[0].profit_margin_avg}}</li>            
       </ul>
     </div> 
     <li>总资产周转率</li>
     <div>
       <ul style="list-style-type:disc" class="detail">
         <li>公司过去一年的总资产周转率：{{analysisResult.ten_year_business_character[0].total_capital_turnover}}</li>
         <li>公司过去十年的总资产周转率</li>
         <div class="proCharts" ref="totalCapitalTurnoverChart" ></div>  
         <li>公司过去十年的平均总资产周转率为：{{analysisResult.stock_key_info_summary[0].total_capital_turnover_avg}}</li>          
       </ul>
     </div>
     <li>权益乘数</li>
     <div>
       <ul style="list-style-type:disc" class="detail">
         <li>公司过去一年的权益乘数：{{analysisResult.ten_year_business_character[0].equity_multiplier}}</li>
         <li>公司过去十年的权益乘数</li>
         <div class="proCharts" ref="equityMultiplierChart" ></div>  
         <li>公司过去十年的平均权益乘数为：{{analysisResult.stock_key_info_summary[0].equity_multiplier_avg}}</li>          
       </ul>     
     </div>
   </ol>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "Three_Indicators_of_DuPont_Analysis",
  data() {
    return {
      analysisResult:{}
    }
  },
  methods:{
    profitMarginChart(){
      var xdata = [],ydata=[]
      for(var i=0;i<Object.keys(this.analysisResult.ten_year_business_character).length;i++){
      xdata.splice(0,0,this.analysisResult.ten_year_business_character[i].time.slice(0,4))
      }
      for(i=0;i<Object.keys(this.analysisResult.ten_year_business_character).length;i++){
      ydata.splice(0,0,this.analysisResult.ten_year_business_character[i].profit_margin)
      }     
      var option =  {
          legend: {
          // orient: 'vertical',
          top: 10,
          },
          tooltip: {},
          xAxis: {
          type: 'category',
          data: xdata,
          name:"时间",
          axisTick: {
              alignWithLabel: true
          },
          },
          yAxis: {
          type: 'value',
          name: "销售净利率"
          },
          series: [
              {
              name: "十年销售净利率趋势图",
              data: ydata,
              type: 'line',
              label: {
              show: true,
              position: 'bottom',
              textStyle: {
                  fontSize: 10
              }
              }
              }
          ]
      }
      let myChart =this.$echarts.init(this.$refs.profitMarginChart);
      myChart.setOption(option)
    },
    totalCapitalTurnoverChart(){
      var xdata = [],ydata=[]
      for(var i=0;i<Object.keys(this.analysisResult.ten_year_business_character).length;i++){
      xdata.splice(0,0,this.analysisResult.ten_year_business_character[i].time.slice(0,4))
      }
      for(i=0;i<Object.keys(this.analysisResult.ten_year_business_character).length;i++){
      ydata.splice(0,0,this.analysisResult.ten_year_business_character[i].total_capital_turnover)
      }     
      var option =  {
          legend: {
          // orient: 'vertical',
          top: 10,
          },
          tooltip: {},
          xAxis: {
          type: 'category',
          data: xdata,
          name:"时间",
          axisTick: {
              alignWithLabel: true
          },
          },
          yAxis: {
          type: 'value',
          name: "总资产周转率"
          },
          series: [
              {
              name: "十年总资产周转率趋势图",
              data: ydata,
              type: 'line',
              label: {
              show: true,
              position: 'bottom',
              textStyle: {
                  fontSize: 10
              }
              }
              }
          ]
      }
      let myChart =this.$echarts.init(this.$refs.totalCapitalTurnoverChart);
      myChart.setOption(option)
    },
    equityMultiplierChart(){
      var xdata = [],ydata=[]
      for(var i=0;i<Object.keys(this.analysisResult.ten_year_business_character).length;i++){
      xdata.splice(0,0,this.analysisResult.ten_year_business_character[i].time.slice(0,4))
      }
      for(i=0;i<Object.keys(this.analysisResult.ten_year_business_character).length;i++){
      ydata.splice(0,0,this.analysisResult.ten_year_business_character[i].equity_multiplier)
      }     
      var option =  {
          legend: {
          // orient: 'vertical',
          top: 10,
          },
          tooltip: {},
          xAxis: {
          type: 'category',
          data: xdata,
          name:"时间",
          axisTick: {
              alignWithLabel: true
          },
          },
          yAxis: {
          type: 'value',
          name: "权益乘数"
          },
          series: [
            {
              name: "十年权益乘数趋势图",
              data: ydata,
              type: 'line',
              label: {
              show: true,
              position: 'bottom',
              textStyle: {
                  fontSize: 10
              }
              }
            }
          ]
      }
      let myChart =this.$echarts.init(this.$refs.equityMultiplierChart);
      myChart.setOption(option)
    }          
  },
  components:{},
  updated(){
    this.$nextTick(()=>{
      if(Object.keys(this.analysisResult.stock_key_info_summary).length>0){
        this.profitMarginChart()
        this.totalCapitalTurnoverChart()
        this.equityMultiplierChart()
      }
    })
  },
  mounted(){
    this.analysisResult = this.$store.state.ffa_result  
  }
}
</script>

<style scoped lang='scss'>
.container li{
  margin: 10px;
  margin-left: 30px;
  font-size: 16px;
}
.title{
  margin: 10px;
  margin-left: 10px;
}
.detail{
  margin: 20px;    
}
.proCharts{
  width: 50%;
  height: 500px;
  // background: rgb(14, 51, 129);
}
</style>