<template>
 <div class="">
    <article>
      <section>
        <p class="paragraph">这里的时间序列分析，并不是量化投资或者技术分析里的价格时间序列统计分析，而是一种“企业前景投资“的投资理论。该投资理论出自《巴菲特法则》这本书，作者是巴菲特老爷子的前媳妇玛丽。</p>
        <p class="paragraph">企业前景投资，根植于格雷厄姆的理念：当投资变得像经营企业一样时，那就是最明智的投资。巴菲特将其理念发扬光大，主要思想就是在价格具有商业价值时买入优质企业。这里面有两层含义，第一是“买什么”，第二是“以什么价格买”。如何确定哪家企业适合投资，以及什么价格是合理价格呢？巴菲特法则给出了一套方法论和数学工具，我们在此基础上进行了适当的扩充。</p>
        <br>
        <p class="paragraph"><b>首先是买什么。</b></p>
        <p class="paragraph">判别企业优秀与否，需要回答以下11个问题：</p>
        <p class="paragraph">1、公司是否具备持久性竞争优势，是否具有消费垄断能力或者品牌产品？或者公司只是商品类企业或价格竞争型企业？（定性）</p>
        <p class="paragraph">2、公司如何做到消费垄断或品牌粘性？（定性）</p>
        <p class="paragraph">3、公司从事的范围是什么，是否仅投资其专业领域内的企业？（定性）</p>
        <p class="paragraph">4、公司可以根据通货膨胀自由调整产品和服务的价格吗？（定性）</p>
        <p class="paragraph">5、公司经营需要大量的资本消耗，不断投入研发，或者不断更新厂房和设备吗？（定量和定性）</p>
        <p class="paragraph">6、公司是否有过重的债务负担？（定量）</p>
        <p class="paragraph">7、公司的盈利能力强吗？是否呈上升趋势？（定量）</p>
        <p class="paragraph">8、公司是否回购过股票？（定量）</p>
        <p class="paragraph">9、公司留存收益的利用是否增加了每股收益，从而增加了股东的价值？（定量）</p>
        <p class="paragraph">10、公司的股东权益回报率是否稳定，是否高于平均水平？（定量）</p>
        <p class="paragraph">11、公司的总资本回报率是否高于平均水平？（定量）</p>
        <p class="paragraph">以上既包括了定性分析的内容，也包括了需要阅读财报数据并进行计算、定量分析的内容。我们努力寻找的投资对象，是那些具有强劲经济动力，能够产生垄断性利润的企业。</p>
        <br>
        <p class="paragraph"><b>其次是以什么价格买。</b></p>
        <p class="paragraph">支付价格决定投资的收益率。巴菲特思想的两大原理是：</p>
        <p class="paragraph">1、你所支付的价格将决定你正在进行的投资收益率；</p>
        <p class="paragraph">2、为确定收益率，你必须合理地预见企业的未来收益。</p>
        <p class="paragraph">在巴菲特看来，一个企业具有商业价值，就是指在一个固定的期限内（5年、10年或更长），投资企业能带来最大的预期年复利收益率和最小的风险。我们采用了巴菲特法则的三个数学工具，重点是计算5至10年的投资年复利收益率。</p>
        <p class="paragraph"><b>第一个是初始收益率，以及相对于政府债券的价值。</b></p>
        <p class="paragraph">1、用公司当前每股利润除以无风险利率，得出相对政府长期债券价值；</p>
        <p class="paragraph">2、用公司当前每股利润除以当前每股市场价格，得到初始收益率a；</p>
        <p class="paragraph">3、计算公司过去5至10年的盈利年复利增长率b。</p>
        <p class="paragraph">购买这个公司的股票，相当于得到一个初始收益率为a,息票的预期年增长率为b的债券，可以和政府长期债券进行比较。</p>
        <p class="paragraph"><b>第二个是把公司股票看作一种权益/债券。</b></p>
        <p class="paragraph">1、计算过去5至10年公司的ROE；</p>
        <p class="paragraph">2、计算过去5至10年公司的平均分红率和留存收益率；</p>
        <p class="paragraph">3、计算未来5至10年后，公司每股权益价值和年复合增长率；</p>
        <p class="paragraph">4、计算未来5至10年后，公司的每股利润（每股权益价值乘以ROE）；</p>
        <p class="paragraph">5、计算过去5至10年公司的平均市盈率，并用这个市盈率乘以未来5至10年的公司每股利润，得出公司5至10年后的市场价值；</p>
        <p class="paragraph">6、用公司未来5至10年后的市场价值加上未来5至10年的股息，再根据公司当前市价，计算预期的年复利收益率。</p>
        <p class="paragraph"><b>第三个是使用历史每股收益增长来预计年复利收益率。</b></p>
        <p class="paragraph">1、计算过去5至10年的公司盈利年复利增长率；</p>
        <p class="paragraph">2、计算过去5至10年的公司股息率；</p>
        <p class="paragraph">3、用过去5至10年的盈利年复利增长率，计算未来5至10年的每股预期盈利；</p>
        <p class="paragraph">4、计算过去5至10年公司的平均市盈率，并用这个市盈率乘以未来5至10年的公司每股利润，得出公司5至10年后的市场价值；</p>
        <p class="paragraph">5、用公司未来5至10年后的市场价值加上未来5至10年的股息，再根据公司当前市价，计算预期的年复利收益率。</p>
        <p class="paragraph">通过上述三个数学工具，我们既可以计算投资的初始收益率，把公司股票当作有息票而且利息还在增长的债券，还可以计算出一个未来的预期年复利收益率区间。</p>
     </section>
    </article>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "TSA_Summary",
 data() {
   return {}
 },
 components:{}
}
</script>

<style scoped lang='scss'>
.paragraph{
  text-indent: 2em;
  margin:5px 5px;
}
</style>