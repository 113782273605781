<template>
 <div class="container" v-if="Object.keys(analysisResult).length > 0">
   <div>
       <span>
         <p><b>分专业分部的历史平均估值乘以当前分部利润再汇总。计算方法：利用各分部的收入和利润、每个专业对应行业的过去十年的平均PE，计算总市值，再比较当前市值。</b></p>
         <p><b>注意：该分析工具提取了企业财报中各个子行业或者子专业的名称，与申万二级行业名称进行自动匹配和对照。由于采用了Levenshtein Distance（编辑距离）来自动比较，可能会出现较大的偏差。如果行业或专业名称匹配错误，该工具分析的结果不能采用。</b></p>
       </span>
       <span>
         <p class="title">（1）企业的主营分部行业或分部专业的组成</p>
         <div class="industryTable">
           <el-table
                :data="analysisResult.business"
                style="width: 1451px"
                border>
                <el-table-column
                prop="index"
                label="序号"
                width="50">
                </el-table-column>
                <el-table-column
                prop="report_period"
                label="报告日期"
                width="100">
                </el-table-column>  
                <el-table-column
                prop="classification"
                label="主营分类"
                width="200">
                </el-table-column>                      
                <el-table-column
                prop="index_code"
                label="申万二级行业代码"
                width="200">
                </el-table-column>
                <el-table-column
                prop="index_name"
                label="申万二级行业名称"
                width="200">
                </el-table-column>
                <el-table-column
                prop="index_pe"
                label="申万二级行业十年平均PE"
                width="200">
                </el-table-column>
                <el-table-column
                prop="revenue"
                label="主营分类行业的营业收入"
                width="200">
                </el-table-column>
                <el-table-column
                prop="index_top_three_ten_year_profit_margin"
                label="子行业前三大权重公司的净利率平均值"
                width="300">
            </el-table-column>                                                                                          
           </el-table>    
         </div>
         <p class="title">（2）企业的主营分部行业汇总市值是：{{analysisResult.assessment_value_one[0].segment_aggregate_market_value}}，当前市值是：{{analysisResult.assessment_value_two[0].mv}}，当前市值/分部汇总市值是：<b><font color="red">{{analysisResult.assessment_value_two[0].mv_divide_segment_aggregate_market_value}}</font></b></p>
       </span>
   </div>
 </div>
</template>

<script type="text/ecmascript-6">
import {Table,TableColumn} from 'element-ui'
export default {
 name: "Segmented_Valuation_Method",
 data() {
   return {
     analysisResult:{}
   }
 },
 components:{
   [Table.name]:Table,
   [TableColumn.name]:TableColumn        
 },
 mounted(){
   this.analysisResult = this.$store.state.ffa_result
 }   
}
</script>

<style scoped lang='scss'>
.container{
  span{
    font-size: 16px;
  }
  p{
    font-family: "宋体";
    margin: 10px;
  }
  .title{
    font-family: "微软雅黑";
    margin: 10px 10px;
  }
  .industryTable{
    margin: 20px;
  }  
}
</style>