<template>
 <div class="">
   <ol class="container" v-if="Object.keys(analysisResult).length > 0">
     <li>工业</li>
     <div class="proCharts" ref="industryChart"></div>
     <li>地产、建筑</li>
     <div class="proCharts" ref="propertyChart" ></div> 
     <li>交运</li>
     <div class="proCharts" ref="transportationChart" ></div> 
   </ol>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "Not_Finance",
 data() {
   return {
    analysisResult:{}
   }
 },
 updated(){
   this.$nextTick(()=>{
     if(Object.keys(this.analysisResult).length>0){
       this.industryChart()
       this.propertyChart()
       this.transportationChart()

     } 
   })
 }, 
 methods:{
   industryChart(){
     var xdata = [],ydata=[]
      for(var i=0;i<Object.keys(this.analysisResult.business_cycle_not_finance).length;i++){
        xdata.splice(0,0,this.analysisResult.business_cycle_not_finance[i].time)
      }
      for(i=0;i<Object.keys(this.analysisResult.business_cycle_not_finance).length;i++){
        ydata.splice(0,0,this.analysisResult.business_cycle_not_finance[i].industrial_added_value_YOY)
      }
        
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "%",
              position: "left"
             },
          ],  
          series: [
            {
              name: "工业增加值同比",
              data: ydata,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }
          ]
        }
      let myChart =this.$echarts.init(this.$refs.industryChart);
      myChart.setOption(option)
   },
   propertyChart(){
     var xdata=[], ydata=[], zdata=[]
      for(var i=0;i<Object.keys(this.analysisResult.business_cycle_not_finance).length;i++){
        xdata.splice(0,0,this.analysisResult.business_cycle_not_finance[i].time)
      }
      for(i=0;i<Object.keys(this.analysisResult.business_cycle_not_finance).length;i++){
        ydata.splice(0,0,this.analysisResult.business_cycle_not_finance[i].new_building_price_index_YOY)
      }
      for(i=0;i<Object.keys(this.analysisResult.business_cycle_not_finance).length;i++){
        zdata.splice(0,0,this.analysisResult.business_cycle_not_finance[i].second_hand_building_price_index_YOY)
      }      
        
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "%",
              position: "left"
             },
          ],  
          series: [
            {
              name: "新房价格指数同比",
              data: ydata,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "二手房价格指数同比",
              data: zdata,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }            
          ]
        }
      let myChart =this.$echarts.init(this.$refs.propertyChart);
      myChart.setOption(option)
   },   
   transportationChart(){
     var xdata=[], ydata=[], zdata=[]
      for(var i=0;i<Object.keys(this.analysisResult.business_cycle_not_finance).length;i++){
        xdata.splice(0,0,this.analysisResult.business_cycle_not_finance[i].time)
      }
      for(i=0;i<Object.keys(this.analysisResult.business_cycle_not_finance).length;i++){
        ydata.splice(0,0,this.analysisResult.business_cycle_not_finance[i].BDI)
      }
      for(i=0;i<Object.keys(this.analysisResult.business_cycle_not_finance).length;i++){
        zdata.splice(0,0,this.analysisResult.business_cycle_not_finance[i].road_freight_rate_YOY_index)
      }      
        
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "值",
              position: "left"
             },
            {
              type: 'value',
              name: "%",
              position: "right"
             },             
          ],  
          series: [
            {
              name: "BDI",
              data: ydata,
              type: 'line',
              yAxisIndex: 0,             
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "路运指数同比",
              data: zdata,
              type: 'line',
              yAxisIndex: 1,              
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }            
          ]
        }
      let myChart =this.$echarts.init(this.$refs.transportationChart);
      myChart.setOption(option)
   },      
 },  
 components:{},
 mounted(){
   this.analysisResult = this.$store.state.bc_result
 } 
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 10px;
    margin-left: 30px;
    font-size: 16px;
}
.proCharts{
  width: 100%;
  height: 500px;
  // background: rgb(14, 51, 129);
}
</style>