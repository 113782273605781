<template>
 <div class="container" v-if="Object.keys(analysisResult).length > 0">
    <span>1.企业价值和现金流估值</span>
    <p>上一年度，企业价值/公司自由现金流 = <b>{{(analysisResult.last_year[0].EV/analysisResult.last_year[0].FCFF).toFixed(2)}}</b>，企业价值/息税前利润 = {{(analysisResult.last_year[0].EV/analysisResult.last_year[0].EBIT).toFixed(2)}}，企业价值/息税摊销前利润={{(analysisResult.last_year[0].EV/analysisResult.last_year[0].EBITDA).toFixed(2)}} </p>
    <span>2.初始收益率相对于政府债券的价值</span>
    <div>
      <p>（1）公司昨日的股价为{{analysisResult.last_day[0].price}}元，十年期国债利率为<b>{{toPercent(analysisResult.bond[0].TenYearYield/100)}}</b>。</p>
      <p>（2）如果把公司股票当作一种权益债券，初始收益率为<b>{{analysisResult.last_day[0].init_yield}}%</b>（PE(TTM)的倒数）。根据过去十年的数据，息票的预期年增长率为{{ten_year_eps_cgr}}；根据过去五年的数据，息票的预期年增长率为{{five_year_eps_cgr}}。</p>
    </div>
 
 </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "First_Math_Tool",
  data() {
    return {
      analysisResult:{},  
      ten_year_eps_cgr: 0,
      five_year_eps_cgr: 0
    }
  },
  methods:{
    ten_year_data_eps_cgr_compute(){
      if(Object.keys(this.analysisResult.ten_year_data).length>10){
        this.ten_year_eps_cgr = this.toPercent(Math.pow(this.analysisResult.ten_year_data[0].eps/this.analysisResult.ten_year_data[10].eps,1/10)-1)
      }
      else{
        this.ten_year_eps_cgr = this.toPercent(Math.pow(this.analysisResult.ten_year_data[0].eps/this.analysisResult.ten_year_data[Object.keys(this.analysisResult.ten_year_data).length-1].eps,1/(this.year-1))-1)   
      }
    },
    five_year_data_eps_cgr_compute(){
      if(Object.keys(this.analysisResult.ten_year_data).length>5){
        this.five_year_eps_cgr = this.toPercent(Math.pow(this.analysisResult.ten_year_data[0].eps/this.analysisResult.ten_year_data[5].eps,1/5)-1)
      }
      else{
        this.five_year_eps_cgr = this.toPercent(Math.pow(this.analysisResult.ten_year_data[0].eps/this.analysisResult.ten_year_data[Object.keys(this.analysisResult.ten_year_data).length-1].eps,1/(this.years-1))-1)       
      }
    },
    toPercent(point){
      var str=Number(point*100).toFixed(2);
      str+="%";
      return str;
    }       
  },
  components:{},
  mounted(){
    this.analysisResult = this.$store.state.tsa_result
  },
  updated(){
    if(Object.keys(this.analysisResult.last_year).length>0 && Object.keys(this.analysisResult.ten_year_data).length>0 && Object.keys(this.analysisResult.last_day).length>0){
      this.ten_year_data_eps_cgr_compute()
      this.five_year_data_eps_cgr_compute()
    }
  }  
}
</script>

<style scoped lang='scss'>
.container{
  span{
    font-size: 16px;
  }
  p{
    font-family: "宋体";
    margin: 10px;
  }
}
</style>