<template>
 <div class="" >
   <ol class="container" v-if="Object.keys(analysisResult).length > 0">
     <li>十年营业收入</li>
     <div class="proCharts" ref="revenueChart" ></div>  
     <p>十年平均营业收入为：{{analysisResult.stock_key_info_summary[0].ten_year_revenue_avg}}</p>   
     <p v-if="analysisResult.stock_key_info_summary[0].ten_year_revenue_YOY">十年营业收入的年化增长率为：{{analysisResult.stock_key_info_summary[0].ten_year_revenue_YOY}}%</p>  
     <li>十年ROE(加权扣非ROE)</li>
     <div class="proCharts" ref="ROEChart" ></div> 
     <p>十年平均ROE（加权扣非ROE）为：{{analysisResult.stock_key_info_summary[0].ten_year_roe_avg}}</p>       
     <li>十年毛利率</li>
     <div class="proCharts" ref="grossMarginChart" ></div>
     <p>十年毛利率年化增长率为：{{analysisResult.stock_key_info_summary[0].gross_margin_annual_growth}}%</p>
   </ol>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "Business_Cycle_Positioning",
 data() {
   return {
     analysisResult:{}        
   }
 },
 updated(){
   this.$nextTick(()=>{
     if(Object.keys(this.analysisResult.stock_key_info_summary).length>0){
       this.revenueChart()
       this.ROEChart()
       this.grossMarginChart()
     } 
   })
 },
 methods:{
   revenueChart(){
    var xdata = [],ydata=[]
    for(var i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
    xdata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].time.slice(0,4))
    }
    for(i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
    ydata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].revenue)
    }     
    var option =  {
        legend: {
        // orient: 'vertical',
        top: 10,
        },
        tooltip: {},
        xAxis: {
        type: 'category',
        data: xdata,
        name:"时间",
        axisTick: {
            alignWithLabel: true
        },
        },
        yAxis: {
        type: 'value',
        name: "营业收入"
        },
        series: [
            {
            name: "十年营业收入趋势图",
            data: ydata,
            type: 'line',
            label: {
            show: true,
            position: 'bottom',
            textStyle: {
                fontSize: 10
            }
            }
            }
        ]
    }
    let myChart =this.$echarts.init(this.$refs.revenueChart);
    myChart.setOption(option)
   }, 
   ROEChart(){
    var xdata = [],ydata=[]
    for(var i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
    xdata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].time.slice(0,4))
    }
    for(i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
    ydata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].weighted_minus_non_recurring_roe)
    }     
    var option =  {
        legend: {
        // orient: 'vertical',
        top: 10,
        },
        tooltip: {},
        xAxis: {
        type: 'category',
        data: xdata,
        name:"时间",
        axisTick: {
            alignWithLabel: true
        },
        },
        yAxis: {
        type: 'value',
        name: "ROE"
        },
        series: [
            {
            name: "十年ROE趋势图",
            data: ydata,
            type: 'line',
            label: {
            show: true,
            position: 'bottom',
            textStyle: {
                fontSize: 10
            }
            }
            }
        ]
    }
    let myChart =this.$echarts.init(this.$refs.ROEChart);
    myChart.setOption(option)
   },
   grossMarginChart(){
    var xdata = [],ydata=[]
    for(var i=0;i<Object.keys(this.analysisResult.revenue_income_valuation).length;i++){
    xdata.splice(0,0,this.analysisResult.revenue_income_valuation[i].time.slice(0,4))
    }
    for(i=0;i<Object.keys(this.analysisResult.revenue_income_valuation).length;i++){
    ydata.splice(0,0,this.analysisResult.revenue_income_valuation[i].gross_margin)
    }     
    var option =  {
        legend: {
        // orient: 'vertical',
        top: 10,
        },
        tooltip: {},
        xAxis: {
        type: 'category',
        data: xdata,
        name:"时间",
        axisTick: {
            alignWithLabel: true
        },
        },
        yAxis: {
        type: 'value',
        name: "毛利率"
        },
        series: [
            {
            name: "十年毛利率趋势图",
            data: ydata,
            type: 'line',
            label: {
            show: true,
            position: 'bottom',
            textStyle: {
                fontSize: 10
            }
            }
            }
        ]
    }
    let myChart =this.$echarts.init(this.$refs.grossMarginChart);
    myChart.setOption(option)
   },        
 },
 components:{},
 mounted(){
   this.analysisResult = this.$store.state.ffa_result
 } 
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 10px;
    margin-left: 30px;
    font-size: 16px;
}
.proCharts{
  width: 50%;
  height: 500px;
  // background: rgb(14, 51, 129);
}
.container p{
  margin: 10px 30px;
  font-family: "宋体";
}
</style>