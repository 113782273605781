<template>
    <div v-if="Object.keys(analysisResult).length > 0">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="金融" name="first"></el-tab-pane>
            <el-tab-pane label="非金融" name="second"></el-tab-pane>
        </el-tabs>        
        <router-view></router-view>
    </div>  


</template>

<script type="text/ecmascript-6">
import {Tabs,TabPane} from 'element-ui'
export default {
 name: "Tracking_Model",
 data() {
   return {
    activeName:"first",
    analysisResult:{},
   }
 },
 components:{
   [Tabs.name]:Tabs,
   [TabPane.name]:TabPane
 },
 methods: {
   handleClick(tab) {
     if(tab.index == 0){
       this.$router.push({name:'business_cycle_finance'})           
     }
     else{
       this.$router.push({name:'not_finance'})
     }
   }
 },
 mounted(){
   this.$http.business_cycle_detail().then(res=>{
     this.$store.state.bc_result = res.data
     this.analysisResult = res.data
     }).catch(err=>{
       console.log(err)
       this.analysisResult = {}
     })   
 }
}
</script>

<style scoped lang='scss'>
</style>