<template>
 <div class="container" v-if="Object.keys(analysisResult).length > 0">
   <div>
       <span>
         <p><b>利用过去一年的自由现金流，假设后续连续10年每年30%的增速，折现率用当前无风险利率的1倍，计算出未来10年预计的现金流，再折现回来，看FCF/P（P=市值）的比率。这是一种极值假设，用来反推企业当前估值是否合理。</b></p>
       </span>
       <span>
         <p class="title">（1）企业当前市值是：{{analysisResult.assessment_value_two[0].mv}}，无风险利率是：{{analysisResult.assessment_value_two[0].ten_year_yield.toFixed(4)}}%</p>
         <p class="title">（2）企业过去一年的FCFE（归属于股东的自由现金流）是：{{analysisResult.assessment_value_one[0].fcfe}}</p>
         <p class="title">（3）企业未来10年FCFE折现累计值是：{{analysisResult.assessment_value_two[0].discount_10y_FCFE}}</p>
         <p class="title">企业当前市值/未来10年FCFE折现累计值是：<b><font color="red">{{analysisResult.assessment_value_two[0].mv_divide_discount_10y_FCFE}}</font></b></p>
       </span>
   </div>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "Extreme_Value_Valuation_Method",
 data() {
   return {
     analysisResult:{}
   }
 },
 components:{},
 mounted(){
   this.analysisResult = this.$store.state.ffa_result
 }   
}
</script>

<style scoped lang='scss'>
.container{
  span{
    font-size: 16px;
  }
  p{
    font-family: "宋体";
    margin: 10px;
  }
  .title{
    font-family: "微软雅黑";
    margin: 10px 10px;
  }
}
</style>