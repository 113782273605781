<template>
 <div class="container" v-if="Object.keys(analysisResult).length > 0">
   <div>
       <span>
         <p><b>用0.25、0.5、0.75的资本开支分别加回自由现金流再进行估值。计算方法：利用过去一年资本开支和过去一年的自由现金流（FCFE），敏感性分析后重算市值现金流比率（P/FCFE）。</b></p>
         <p><b>注意：该分析工具仅适用于“类似亚马逊这种增长型资本开支（即投资未来的资本开支）占整体资本开支比例比较大的成长型公司”。</b></p>
         <p>利润本质上是一个会计周期内的盈余。什么是盈余？就是在这一段时间内，企业的收入减去企业所有的开支，所剩余的东西。那么问题就来了，如果企业开支很大，导致企业的盈余为0，但是开支主要是为了高速增长，那么这个企业到底赚钱没赚钱？</p>
         <p>我们认为，看一个公司会计周期内，到底赚钱没赚钱，净利润不重要，重要的是自由现金流。</p>
         <p>自由现金流就是一个企业在满足了短期生存压力和长期生存压力之后，企业可以自由分配的钱。所谓的短期生存压力其实就是运营资本的建立，长期生存压力主要指的是保持企业竞争力的维护性资本开支。企业的价值，就等于企业在生命周期内可以创造的自由现金流的折现值总和。</p>
         <p>自由现金流的一般公式： FCF = CFO - Capital Expense ，即自由现金流 = 经营性现金流净额 - 资本开支</p>
         <p>资本开支总共有两类：增长性资本开支和维护性资本开支。增长性资本开支指的是为了未来业绩增长而进行的投资，维护性资本开支指的是仅仅为了维护现有的竞争优势而进行的投资。所谓的自由现金流，实际上是不需要去考虑增长性资本开支的，增长性资本开支本质上是自由现金流的一个用途而已。</p>
         <p>而一般情况下，我们计算FCF的公式直接使用的是整个资本开支，而没有考虑将增长性资本开支和维护性资本开支分开，企业的财报也没有披露资本开支的实际组成。</p>
         <p>因此，我们将自由现金流公式更改为：FCF = CFO - Maintenance Capital Expense，即自由现金流 = 经营性现金流净额 - 维护性资本开支
         <p>然后通过对自由现金流的反向思考，折算回净利润。使用一个对资本开支的敏感分析工具，用0.25、0.5、0.75的资本开支（即假设的增长性资本开支）分别加回自由现金流再进行估值。</p>
       </span>
       <span>
         <p class="title">（1）企业的扣非净利润（TTM）是：{{analysisResult.assessment_value_two[0].net_profit_minus_non_recurring_gains_TTM}}，资本开支是：{{analysisResult.assessment_value_two[0].capital_expenditure_TTM}}，当前市值是：{{analysisResult.assessment_value_two[0].mv}}，PE（TTM）是：{{analysisResult.stock_key_info_summary[0].PE_TTM}}</p>
         <p class="title">（2）0.25资本开支加回自由现金流是：{{analysisResult.assessment_value_two[0].capital_expenditure_TTM_add_profit_025}}，0.25资本开支加回自由现金流的P/FCFE是：{{analysisResult.assessment_value_two[0].capital_expenditure_TTM_add_profit_PE_025}}</p>
         <p class="title">（3）0.5资本开支加回自由现金流是：{{analysisResult.assessment_value_two[0].capital_expenditure_TTM_add_profit_05}}，0.5资本开支加回自由现金流的P/FCFE是：{{analysisResult.assessment_value_two[0].capital_expenditure_TTM_add_profit_PE_05}}</p>
         <p class="title">（4）0.75资本开支加回自由现金流是：{{analysisResult.assessment_value_two[0].capital_expenditure_TTM_add_profit_075}}，0.75资本开支加回自由现金流的P/FCFE是：{{analysisResult.assessment_value_two[0].capital_expenditure_TTM_add_profit_PE_075}}</p>
       </span>
         <p class="title">因此，企业利用资本开支的敏感分析后，P/FCFE估值区间在<b><font color="red">{{analysisResult.assessment_value_two[0].capital_expenditure_TTM_add_profit_PE_075}}-{{analysisResult.assessment_value_two[0].capital_expenditure_TTM_add_profit_PE_025}}倍。</font></b></p>
  </div>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "Sensitivity_Analysis_of_Capital_Expenditure",
 data() {
   return {
     analysisResult:{}
   }
 },
 components:{},
 mounted(){
   this.analysisResult = this.$store.state.ffa_result
 }  
}
</script>

<style scoped lang='scss'>
.container{
  span{
    font-size: 16px;
  }
  p{
    font-family: "宋体";
    margin: 10px;
  }
  .title{
    font-family: "微软雅黑";
    margin: 10px 10px;
  }
}
</style>