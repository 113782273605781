<template>
 <div class="">
    <article>
      <section>
        <p class="paragraph">对一个投资标的进行分析，主要分为三个方面：财务特征、业务特征和技术特征。</p>
        <p class="paragraph">财务特征是指标的的财务数据表现出来的特征，比如一个上市公司的三大财务报表的关键数据、财务比率、历史趋势和估值预测等；业务特征是指标的的经营特性，比如一个上市公司的经营逻辑，商品和服务如何销售，利润怎么赚取，是否能维持收入的稳定增长和保持竞争地位，管理层是否为股东代言等；技术特征是指标的在投资市场的价格走势，成交量的变化，估值高低的量化分析等等。</p>
        <p class="paragraph">一般而言，要进行成功的投资，三个方面的分析都会涉及，而且互为佐证相得益彰。总体来说，财务特征分析占分析的比重为45%，业务特征分析占分析的比重为50%，而技术特征的分析占比较低为5%，起到锦上添花的作用。</p>
        <p style="text-align:center;margin-top:20px;margin-bottom:20px"><img src="../../assets/投资分析框架.png" alt="" width="800px" height="400px"></p>
        <p class="paragraph">本网站提供的量化投资，主要是用于辅助前述价值投资、逆向投资和周期投资里的分析工具，对这些工具分析结果进行技术特征的量化验证。本网站暂不提供量化平台网站的各种投资策略。</p>
        <p class="paragraph">目前提供的量化投资分析工具有三个：</p>
        <p class="paragraph"><b>1、ETF轮换策略</b></p>
        <p class="paragraph">标的：三个ETF</p>
        <p class="paragraph">上证50(510050)、创业板（159915）、国债（511010）</p>
        <p class="paragraph">策略：每天开盘前，从3个ETF中选出过去一个月涨幅最高者，和持仓ETF比较，如果不一致，调仓到涨幅高的标的。如果过去一个月，3个ETF都是下跌的，就空仓，否则永远满仓。</p>
        <p class="paragraph">目的：判断市场的热度</p>       
        <br>
        <p class="paragraph"><b>2、北向净流入</b></p>
        <p class="paragraph">标的：沪港通A股任意股票</p>
        <p class="paragraph">策略：</p>        
        <p class="paragraph">（1）60天持续净流入占总市值买入比例大于3%,总市值小于400亿，PE(TTM)大于0并且小于40</p>
        <p class="paragraph">（2）30天持续净流入占总市值买入比例大于2%,总市值小于400亿，PE(TTM)大于0并且小于30</p>
        <p class="paragraph">目的：筛选投资标的</p>
        <br>
        <p class="paragraph"><b>3、行业ETF指数三折</b></p>
        <p class="paragraph">标的：申万二级行业任意指数</p>
        <p class="paragraph">策略：行业指数从最高点价格打三折</p>
        <p class="paragraph">目的：逆向寻找行业投资标的，辅助行业内公司财务特征和业务特征的分析</p>   




      </section>
    </article>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "Quantitative_Summary",
 data() {
   return {}
 },
 components:{}
}
</script>

<style scoped lang='scss'>
.paragraph{
  text-indent: 2em;
  margin:5px 5px;
}
</style>