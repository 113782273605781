<template>
 <div class="">
   <ol class="container" v-if="Object.keys(analysisResult).length > 0">
     <li>银行：货币规模和利率指标</li>
     <div class="proCharts" ref="currencyChart"></div>
     <div class="proCharts" ref="interestChart"></div>       
     <li>保险</li>
     <div class="proCharts" ref="insuranceChart" ></div> 
     <li>券商</li>
     <div class="proCharts" ref="brokerChart" ></div> 
   </ol>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "Business_Cycle_Finance",
 data() {
   return {
     analysisResult:{}
   }
 },
 updated(){
   this.$nextTick(()=>{
     if(Object.keys(this.analysisResult).length>0){
       this.currencyChart()
       this.interestChart()
       this.insuranceChart()
       this.brokerChart()
     } 
   })
 }, 
 methods:{
   currencyChart(){
     var xdata = [],ydata=[]
      for(var i=0;i<Object.keys(this.analysisResult.business_cycle_finance).length;i++){
        xdata.splice(0,0,this.analysisResult.business_cycle_finance[i].time)
      }
      for(i=0;i<Object.keys(this.analysisResult.business_cycle_finance).length;i++){
        ydata.splice(0,0,this.analysisResult.business_cycle_finance[i].M2_YOY)
      }
        
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "%",
              position: "left"
             },
          ],  
          series: [
            {
              name: "M2同比",
              data: ydata,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }
          ]
        }
      let myChart =this.$echarts.init(this.$refs.currencyChart);
      myChart.setOption(option)
   },
   interestChart(){
     var xdata = [],ydata=[],zdata=[]
      for(var i=0;i<Object.keys(this.analysisResult.business_cycle_finance).length;i++){
        xdata.splice(0,0,this.analysisResult.business_cycle_finance[i].time)
      }
      for(i=0;i<Object.keys(this.analysisResult.business_cycle_finance).length;i++){
        ydata.splice(0,0,this.analysisResult.business_cycle_finance[i].bond)
      }
      for(i=0;i<Object.keys(this.analysisResult.business_cycle_finance).length;i++){
        zdata.splice(0,0,this.analysisResult.business_cycle_finance[i].interest_rate_YOY)
      }          
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "利率",
              position: "left"
             },
            {
              type: 'value',
              name: "利率同比（%）",
              position: "right"
             }             
          ],  
          series: [
            {
              name: "十年期国债利率",
              data: ydata,
              yAxisIndex: 0,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "利率同比",
              data: zdata,
              yAxisIndex: 1,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }
          ]
        }
      let myChart =this.$echarts.init(this.$refs.interestChart);
      myChart.setOption(option)
   },   
   insuranceChart(){
     var xdata = [],ydata=[],zdata=[]
      for(var i=0;i<Object.keys(this.analysisResult.business_cycle_finance).length;i++){
        xdata.splice(0,0,this.analysisResult.business_cycle_finance[i].time)
      }
      for(i=0;i<Object.keys(this.analysisResult.business_cycle_finance).length;i++){
        ydata.splice(0,0,this.analysisResult.business_cycle_finance[i].insurance_expense)
      }
      for(i=0;i<Object.keys(this.analysisResult.business_cycle_finance).length;i++){
        zdata.splice(0,0,this.analysisResult.business_cycle_finance[i].insurance_expense_YOY)
      }          
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "收入",
              position: "left"
             },
            {
              type: 'value',
              name: "同比（%）",
              position: "right"
             }             
          ],  
          series: [
            {
              name: "保费收入",
              data: ydata,
              yAxisIndex: 0,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "收入同比",
              data: zdata,
              yAxisIndex: 1,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }
          ]
        }
      let myChart =this.$echarts.init(this.$refs.insuranceChart);
      myChart.setOption(option)
   },  
   brokerChart(){
     var xdata = [],ydata=[],zdata=[]
      for(var i=0;i<Object.keys(this.analysisResult.business_cycle_finance).length;i++){
        xdata.splice(0,0,this.analysisResult.business_cycle_finance[i].time)
      }
      for(i=0;i<Object.keys(this.analysisResult.business_cycle_finance).length;i++){
        ydata.splice(0,0,this.analysisResult.business_cycle_finance[i].total_transaction_amount_of_securities)
      }
      for(i=0;i<Object.keys(this.analysisResult.business_cycle_finance).length;i++){
        zdata.splice(0,0,this.analysisResult.business_cycle_finance[i].transaction_amount_YOY)
      }          
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "成交金额",
              position: "left"
             },
            {
              type: 'value',
              name: "同比（%）",
              position: "right"
             }             
          ],  
          series: [
            {
              name: "证券成交金额",
              data: ydata,
              yAxisIndex: 0,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "成交同比",
              data: zdata,
              yAxisIndex: 1,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }
          ]
        }
      let myChart =this.$echarts.init(this.$refs.brokerChart);
      myChart.setOption(option)
   }          
 },
 components:{},
 mounted(){
   this.analysisResult = this.$store.state.bc_result
 }
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 10px;
    margin-left: 30px;
    font-size: 16px;
}
.proCharts{
  width: 100%;
  height: 500px;
  // background: rgb(14, 51, 129);
}
</style>