import VueRouter from "vue-router"
import Vue from "vue"
import Frame from "./components/Frame"
import Index from "./components/Index"
import Value from "./components/value/Value"
import TSA_Summary from "./components/value/time_series_analysis/TSA_Summary"
import TSA_Framework from '@/components/value/time_series_analysis/TSA_Framework'
import TSA_Ranking_List from '@/components/value/time_series_analysis/TSA_Ranking_List'
import TSA_Query from '@/components/value/time_series_analysis/TSA_Query'
// import Reverse from "./components/Reverse"
import TSA_Query_Result from '@/components/value/time_series_analysis/query/Query_Result'
import What_to_Buy from '@/components/value/time_series_analysis/query/What_to_Buy/What_to_Buy'
import What_Price_to_Buy from '@/components/value/time_series_analysis/query/What_Price_to_Buy/What_Price_to_Buy'
import Four_Qualitative_Questions from '@/components/value/time_series_analysis/query/What_to_Buy/Four_Qualitative_Questions'
import Six_Quantitative_Questions from '@/components/value/time_series_analysis/query/What_to_Buy/Six_Quantitative_Questions'
import Qualitative_and_Quantitative_Questions from '@/components/value/time_series_analysis/query/What_to_Buy/Qualitative_and_Quantitative_Questions'
import First_Math_Tool from '@/components/value/time_series_analysis/query/What_Price_to_Buy/First_Math_Tool'
import Second_Math_Tool from '@/components/value/time_series_analysis/query/What_Price_to_Buy/Second_Math_Tool'
import Third_Math_Tool from '@/components/value/time_series_analysis/query/What_Price_to_Buy/Third_Math_Tool'
import FFA_Summary from '@/components/value/financial_feature_analysis/FFA_Summary'
import FFA_Framework from '@/components/value/financial_feature_analysis/FFA_Framework'
import FFA_Query from '@/components/value/financial_feature_analysis/FFA_Query'
import FFA_Ranking_List from '@/components/value/financial_feature_analysis/FFA_Ranking_List'
import FFA_Query_Result from '@/components/value/financial_feature_analysis/query/Query_Result'
import Good_Business from '@/components/value/financial_feature_analysis/query/Good_Business/Good_Business'
import Duration_of_Operation from '@/components/value/financial_feature_analysis/query/Good_Business/Duration_of_Operation'
import Cash_Creativity from '@/components/value/financial_feature_analysis/query/Good_Business/Cash_Creativity'
import Business_Cycle_Positioning from '@/components/value/financial_feature_analysis/query/Good_Business/Business_Cycle_Positioning'
import Good_Company from '@/components/value/financial_feature_analysis/query/Good_Company/Good_Company'
import Comparability_Data from '@/components/value/financial_feature_analysis/query/Good_Company/Comparability_Data'
import Three_Indicators_of_DuPont_Analysis from '@/components/value/financial_feature_analysis/query/Good_Company/Three_Indicators_of_DuPont_Analysis'
import Other_Key_Data from '@/components/value/financial_feature_analysis/query/Good_Company/Other_Key_Data'
import Good_Price from '@/components/value/financial_feature_analysis/query/Good_Price/Good_Price'
import Sensitivity_Analysis_of_Capital_Expenditure from '@/components/value/financial_feature_analysis/query/Good_Price/Sensitivity_Analysis_of_Capital_Expenditure'
import Segmented_Valuation_Method from '@/components/value/financial_feature_analysis/query/Good_Price/Segmented_Valuation_Method'
import Extreme_Value_Valuation_Method from '@/components/value/financial_feature_analysis/query/Good_Price/Extreme_Value_Valuation_Method'
import Other from '@/components/value/financial_feature_analysis/query/Good_Price/Other'
import CA_Summary from '@/components/value/comparability_analysis/CA_Summary'
import CA_Framework from '@/components/value/comparability_analysis/CA_Framework'
import CA_Query from '@/components/value/comparability_analysis/CA_Query'
import EL_Summary from '@/components/value/analysis_of_edge_enterprises_and_leveraged_buyouts/EL_Summary'
import EL_Framework from '@/components/value/analysis_of_edge_enterprises_and_leveraged_buyouts/EL_Framework'
import EL_Ranking_List from '@/components/value/analysis_of_edge_enterprises_and_leveraged_buyouts/EL_Ranking_List'
import Reverse_Summary from '@/components/reverse/Reverse_Summary'
import Reverse_Framework from '@/components/reverse/Reverse_Framework'
import Reverse_Query from '@/components/reverse/Reverse_Query'
import Reverse_Detail from '@/components/reverse/Reverse_Detail'
import Cycle_Summary from '@/components/cycle/Cycle_Summary'
import Cycel_Framework from '@/components/cycle/Cycel_Framework'
import Financial_and_Business_Cycles from '@/components/cycle/Financial_and_Business_Cycles'
import Market_Cycle from '@/components/cycle/Market_Cycle'
import Tracking_Model from '@/components/cycle/industry_cycle/Tracking_Model'
import Business_Cycle_Finance from '@/components/cycle/industry_cycle/business_model/Finance'
import Not_Finance from '@/components/cycle/industry_cycle/business_model/Not_Finance'
import Industry_Indicator from '@/components/cycle/industry_cycle/Industry_Indicator'
import Indicator_Result from '@/components/cycle/industry_cycle/indicators/Indicator_Result'
import Important_Indicators from '@/components/cycle/industry_cycle/indicators/Important_Indicators'
import Concentration_Ratio from './components/cycle/industry_cycle/indicators/Concentration_Ratio.vue'
import Proportion_of_Decline from './components/cycle/industry_cycle/indicators/Proportion_of_Decline.vue'
import Periodic_Position from './components/cycle/industry_cycle/indicators/Periodic_Position.vue'
import Excess_Return from './components/cycle/industry_cycle/indicators/Excess_Return.vue'
import Indicator_Ranking_List from './components/cycle/industry_cycle/Indicator_Ranking_List.vue'
import Indicator_Component from './components/cycle/industry_cycle/Indicator_Component.vue'
import Quantitative_Summary from './components/Quantitative/Quantitative_Summary.vue'
import Quantitative_Result from './components/Quantitative/Quantitative_Result.vue'
import Books from './components/Books'
import QA from './components/Q&A.vue'
import Financial_Terminology from './components/Financial_terminology.vue'
// import { component } from "vue/types/umd"


Vue.use(VueRouter)

const router = new VueRouter({
  routes:[
    {
      path:"/",
      component: Frame,
      children:[
        {
          path: "",
          component: Index,
          name: "index"
        },
        {
          path: "value",
          component: Value,
          name: "value"
        },
        {
          path: "value/tsa/summary",
          component: TSA_Summary,
          name: "tsa_summary"
        },
        {
          path: "value/tsa/framework",
          component: TSA_Framework,
          name: "tsa_framework"
        },
        {
          path:"value/tsa/ranking_list",
          component: TSA_Ranking_List,
          name: "tsa_ranking_list"
        },
        {
          path: "value/tsa/query",
          component: TSA_Query,
          name: "tsa_query"
        },
        {
          path: "value/tsa/query/result",
          component: TSA_Query_Result,
          name: "tsa_query_result",
          children: [
            {
              path:"",
              component: What_to_Buy,
              name:"what_to_buy",
              children: [
                {
                  path:"",
                  component: Four_Qualitative_Questions,
                  name:"four_qualitative_questions"
                },
                {
                  path:"/quantitative_questions",
                  component: Six_Quantitative_Questions,
                  name:"six_quantitative_questions"
                },
                {
                  path:"/qualitative_and_quantitative_questions",
                  component: Qualitative_and_Quantitative_Questions,
                  name:"qualitative_and_quantitative_questions"
                }  
              ]
            },
            {
              path:"/price",
              component: What_Price_to_Buy,
              name:"what_price_to_buy",
              children: [
                {
                  path:"",
                  component: First_Math_Tool,
                  name:"first_math_tool"
                },
                {
                  path:"/second_math_tool",
                  component: Second_Math_Tool,
                  name:"second_math_tool"
                },
                {
                  path:"/third_math_tool",
                  component: Third_Math_Tool,
                  name:"third_math_tool"
                }
              ]
            }
          ]

        },
        {
          path:"value/ffa/summary",
          component:FFA_Summary,
          name:"ffa_summary"
        },
        {
          path:"value/ffa/framework",
          component:FFA_Framework,
          name:"ffa_framework"
        },
        {
          path:"value/ffa/query",
          component:FFA_Query,
          name:"ffa_query"
        },
        {
          path:"value/ffa/query/result",
          component: FFA_Query_Result,
          name:"ffa_query_result",
          children:[
            {
              path:"",
              component: Good_Business,
              name:"good_business",
              children:[
                {
                  path:"",
                  component: Duration_of_Operation,
                  name:"duration_of_operation"                  
                },
                {
                  path:"/cash_creativity",
                  component: Cash_Creativity,
                  name:"cash_creativity"
                },
                {
                  path:"/business_cycle_positioning",
                  component: Business_Cycle_Positioning,
                  name:"business_cycle_positioning"
                }
              ]
            },
            {
              path:"/company",
              component:Good_Company,
              name:"good_company",
              children:[
                {
                  path:"",
                  component:Comparability_Data,
                  name:"comparability_data"
                },
                {
                  path:"/three_indicators_of_DuPont_analysis",
                  component:Three_Indicators_of_DuPont_Analysis,
                  name:"three_indicators_of_DuPont_analysis"
                },
                {
                  path:"/other_key_data",
                  component:Other_Key_Data,
                  name:"other_key_data"
                }
              ]
            },
            {
              path:"/price",
              component:Good_Price,
              name:"good_price",
              children:[
                {
                  path:"",
                  component: Sensitivity_Analysis_of_Capital_Expenditure,
                  name:"sensitivity_analysis_of_capital_expenditure"
                },
                {
                  path:"/segmented_valuation_method",
                  component: Segmented_Valuation_Method,
                  name:"segmented_valuation_method"
                },
                {
                  path:"/extreme_value_valuation_method",
                  component: Extreme_Value_Valuation_Method,
                  name:"extreme_value_valuation_method"
                },
                {
                  path:"/other",
                  component:Other,
                  name:"other"
                }
              ]
            }
          ]
        },
        {
          path:"value/ffa/ranking_list",
          component: FFA_Ranking_List,
          name: "ffa_ranking_list"
        },  
        {
          path: "value/ca/summary",
          component: CA_Summary,
          name: "ca_summary"
        }, 
        {
          path: "value/ca/framework",
          component: CA_Framework,
          name: "ca_framework"          
        },
        {
          path: "value/ca/query",
          component: CA_Query,
          name: "ca_query"
        },
        {
          path: "value/el/summary",
          component: EL_Summary,
          name: "el_summary"
        },  
        {
          path: "value/el/framework",
          component: EL_Framework,
          name: "el_framework"
        },  
        {
          path:"value/el/ranking_list",
          component: EL_Ranking_List,
          name: "el_ranking_list"
        }, 
        {
          path:"reverse/summary",
          component: Reverse_Summary,
          name: "reverse_summary"
        }, 
        {
          path:"reverse/framework",
          component: Reverse_Framework,
          name: "reverse_framework"
        }, 
        {
          path:"reverse/query",
          component: Reverse_Query,
          name: "reverse_query"
        },  
        {
          path:"reverse/reverse_detail",
          component: Reverse_Detail,
          name: "reverse_detail"
        }, 
        {
          path:"cycle/summary",
          component: Cycle_Summary,
          name: "cycle_summary"
        },
        {
          path:"cycle/framework",
          component: Cycel_Framework,
          name: "cycle_framework"
        }, 
        {
          path:"cycle/financial_business_cycles",
          component: Financial_and_Business_Cycles,
          name: "financial_and_business_cycles"
        },
        {
          path:"cycle/market_cycle",
          component: Market_Cycle,
          name: "market_cycle"
        },
        {
          path:"cycle/tracking_model",
          component: Tracking_Model,
          name: "tracking_model",
          children: [
            {
              path:"",
              component: Business_Cycle_Finance,
              name:"business_cycle_finance",
            },
            {
              path:"/not_finance",
              component: Not_Finance,
              name: "not_finance"
            }
          ]    
        },   
        {
          path:"cycle/industry_indicator",
          component: Industry_Indicator,
          name: "industry_indicator"
        },
        {
          path:"cycle/industry_indicator/result",
          component: Indicator_Result,
          name: "indicator_result",
          children: [
            {
              path:"",
              component: Important_Indicators,
              name:"important_indicators",
            },
            {
              path:"/concentration_ratio",
              component: Concentration_Ratio,
              name:"concentration_ratio"
            },
            {
              path:"/proportion_of_decline",
              component: Proportion_of_Decline,
              name:"proportion_of_decline"
            }, 
            {
              path:"/periodic_position",
              component: Periodic_Position,
              name:"periodic_position"
            },
            {
              path:"/excess_return",
              component: Excess_Return,
              name: "excess_return"
            }           
          ]  
        },
        {
          path:"cycle/indicator_ranking_list",
          component: Indicator_Ranking_List,
          name: "indicator_ranking_list"
        },
        {
          path:"cycle/indicator_component",
          component: Indicator_Component,
          name: "indicator_component"
        },
        {
          path:"quantitative/summary",
          component: Quantitative_Summary,
          name: "quantitative_summary"
        },
        {
          path:"quantitative/result",
          component: Quantitative_Result,
          name: "quantitative_result"
        },
        {
          path: "books",
          component: Books,
          name: "books"
        },
        {
          path: "qa",
          component: QA,
          name: "qa"
        },
        {
          path: "financial_terminology",
          component: Financial_Terminology,
          name: "financial_terminology"
        }
      ]
    }
  ]
})

export default router