<template>
 <div class="container" v-if="Object.keys(analysisResult).length > 0">
   <p>公司经营需要大量的资本消耗，不断投入研发，或者不断更新厂房和设备吗？</p>
   <div class="question">
     <p>上一年度，公司的已用有形资本收益率（ROTCE）为<b>{{toPercent(analysisResult.last_year[0].ROTCE)}}</b>。</p>
   </div>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "Qualitative_and_Quantitative_Questions",
 data() {
   return {
     analysisResult:{}    
   }
 },
 methods:{
   toPercent(point){
    var str=Number(point*100).toFixed(2);
    str+="%";
    return str;
   }
 },
 components:{},
 mounted(){
   this.analysisResult = this.$store.state.tsa_result
 } 
}
</script>

<style scoped lang='scss'>
.container{
  margin: 20px;
  margin-left: 10px;
  font-size: 16px;  
}
.question{
  // margin-left:10px;
  p{
    font-family: "宋体";
    margin: 10px;
  }
}
</style>