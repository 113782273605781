<template>
 <div class="">
   <ol class="container" v-if="Object.keys(analysisResult).length > 0">
     <li>市场周期表（中证500）：每日估值</li>
     <div>
       <div class="proCharts" ref="marketCurrentPEChart"></div>  
       <div class="proCharts" ref="marketCurrentPBChart"></div>       
     </div>

     <li>市场周期表（中证500）：季度估值</li>
     <div>
       <div class="proCharts" ref="marketSeasonPEChart" ></div> 
       <div class="proCharts" ref="marketSeasonPBChart" ></div> 
     </div>

   </ol>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "Market_Cycle",
 data() {
   return {
    analysisResult:{}
   }
 },
 updated(){
   this.$nextTick(()=>{
     if(Object.keys(this.analysisResult).length>0){
       this.marketCurrentPEChart()
       this.marketCurrentPBChart()
       this.marketSeasonPEChart()
       this.marketSeasonPBChart()
     } 
   })
 }, 
 methods:{
   marketCurrentPEChart(){
     var xdata = [],ydata=[],zdata=[]
      for(var i=0;i<Object.keys(this.analysisResult.market_fst_cycle).length;i++){
        xdata.splice(0,0,this.analysisResult.market_fst_cycle[i].time.slice(0,10))
      }
      for(i=0;i<Object.keys(this.analysisResult.market_fst_cycle).length;i++){
        ydata.splice(0,0,this.analysisResult.market_fst_cycle[i].index_pe)
      }
      for(i=0;i<Object.keys(this.analysisResult.market_fst_cycle).length;i++){
        zdata.splice(0,0,this.analysisResult.market_fst_cycle[i].pe_quantile)
      }        
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "PE",
              position: "left"
             },
            {
              type: 'value',
              name: "分位点",
              position: "right"
             },             
          ],  
          series: [
            {
              name: "PE",
              data: ydata,
              type: 'line',
              yAxisIndex: 0,              
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "PE分位点",
              data: zdata,
              type: 'line',
              yAxisIndex: 1,              
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }            
          ]
        }
      let myChart =this.$echarts.init(this.$refs.marketCurrentPEChart);
      myChart.setOption(option)
   },
   marketCurrentPBChart(){
     var xdata = [],ydata=[],zdata=[]
      for(var i=0;i<Object.keys(this.analysisResult.market_fst_cycle).length;i++){
        xdata.splice(0,0,this.analysisResult.market_fst_cycle[i].time.slice(0,10))
      }
      for(i=0;i<Object.keys(this.analysisResult.market_fst_cycle).length;i++){
        ydata.splice(0,0,this.analysisResult.market_fst_cycle[i].index_pb)
      }
      for(i=0;i<Object.keys(this.analysisResult.market_fst_cycle).length;i++){
        zdata.splice(0,0,this.analysisResult.market_fst_cycle[i].pb_quantile)
      }        
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "PB",
              position: "left"
             },
            {
              type: 'value',
              name: "分位点",
              position: "right"
             },             
          ],  
          series: [
            {
              name: "PB",
              data: ydata,
              type: 'line',
              yAxisIndex: 0,              
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "PB分位点",
              data: zdata,
              type: 'line',
              yAxisIndex: 1,              
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }            
          ]
        }
      let myChart =this.$echarts.init(this.$refs.marketCurrentPBChart);
      myChart.setOption(option)
   }, 
   marketSeasonPEChart(){
     var xdata = [],ydata=[]
      for(var i=0;i<Object.keys(this.analysisResult.market_snd_cycle).length;i++){
        xdata.splice(0,0,this.analysisResult.market_snd_cycle[i].time.slice(0,7))
      }
      for(i=0;i<Object.keys(this.analysisResult.market_snd_cycle).length;i++){
        ydata.splice(0,0,this.analysisResult.market_snd_cycle[i].index_pe)
      }
       
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "PE",
              position: "left",         
             },
      
          ],  
          series: [
            {
              name: "PE",
              data: ydata,
              yAxisIndex: 0,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              },
              markLine:{
                symbol: ['none','none'],
                data: [ 
                  {
                    yAxis: this.analysisResult.market_snd_cycle[0].max_pe,
                    name:"最大值",
                    // label:{
                    //     normal:{
                    //         formatter:function(params){
                    //             return "均值：" + params.value
                    //         }
                    //     }
                    // }
                  },
                  {
                    yAxis: this.analysisResult.market_snd_cycle[0].min_pe,
                    name:"最小值"
                    // label:{
                    //     normal:{
                    //         formatter:function(params){
                    //             return "均值加一倍标准差：" + params.value
                    //         }
                    //     }
                    // }                    
                  },
                  {
                    yAxis: this.analysisResult.market_snd_cycle[0].avg_pe,
                    name:"均值"
                    // label:{
                    //     normal:{
                    //         formatter:function(params){
                    //             return "均值加两倍标准差：" + params.value
                    //         }
                    //     }
                    // }                    
                  },  
                  {
                    yAxis: this.analysisResult.market_snd_cycle[0].avg_pe+this.analysisResult.market_snd_cycle[0].std_pe,
                    name:"均值加标准差"
                    // label:{
                    //     normal:{
                    //         formatter:function(params){
                    //             return "均值减一倍标准差：" + params.value
                    //         }
                    //     }
                    // }                    
                  }, 
                  {
                    yAxis: this.analysisResult.market_snd_cycle[0].avg_pe-this.analysisResult.market_snd_cycle[0].std_pe,
                    name:"均值减标准差"
                    // label:{
                    //     normal:{
                    //         formatter:function(params){
                    //             return "均值减一倍标准差：" + params.value
                    //         }
                    //     }
                    // }                    
                  },                                                                      
                ]
              }              
            },

          ]
        }
      let myChart =this.$echarts.init(this.$refs.marketSeasonPEChart);
      myChart.setOption(option)
   },     
   marketSeasonPBChart(){
     var xdata = [],ydata=[]
      for(var i=0;i<Object.keys(this.analysisResult.market_snd_cycle).length;i++){
        xdata.splice(0,0,this.analysisResult.market_snd_cycle[i].time.slice(0,7))
      }
      for(i=0;i<Object.keys(this.analysisResult.market_snd_cycle).length;i++){
        ydata.splice(0,0,this.analysisResult.market_snd_cycle[i].index_pb)
      }
       
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "PB",
              position: "left",         
             },
      
          ],  
          series: [
            {
              name: "PB",
              data: ydata,
              yAxisIndex: 0,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              },
              markLine:{
                symbol: ['none','none'],
                data: [ 
                  {
                    yAxis: this.analysisResult.market_snd_cycle[0].max_pb,
                    name:"最大值",
                    // label:{
                    //     normal:{
                    //         formatter:function(params){
                    //             return "均值：" + params.value
                    //         }
                    //     }
                    // }
                  },
                  {
                    yAxis: this.analysisResult.market_snd_cycle[0].min_pb,
                    name:"最小值"
                    // label:{
                    //     normal:{
                    //         formatter:function(params){
                    //             return "均值加一倍标准差：" + params.value
                    //         }
                    //     }
                    // }                    
                  },
                  {
                    yAxis: this.analysisResult.market_snd_cycle[0].avg_pb,
                    name:"均值"
                    // label:{
                    //     normal:{
                    //         formatter:function(params){
                    //             return "均值加两倍标准差：" + params.value
                    //         }
                    //     }
                    // }                    
                  },  
                  {
                    yAxis: this.analysisResult.market_snd_cycle[0].avg_pb+this.analysisResult.market_snd_cycle[0].std_pb,
                    name:"均值加标准差"
                    // label:{
                    //     normal:{
                    //         formatter:function(params){
                    //             return "均值减一倍标准差：" + params.value
                    //         }
                    //     }
                    // }                    
                  }, 
                  {
                    yAxis: this.analysisResult.market_snd_cycle[0].avg_pb-this.analysisResult.market_snd_cycle[0].std_pb,
                    name:"均值减标准差"
                    // label:{
                    //     normal:{
                    //         formatter:function(params){
                    //             return "均值减一倍标准差：" + params.value
                    //         }
                    //     }
                    // }                    
                  },                                                                      
                ]
              }              
            },

          ]
        }
      let myChart =this.$echarts.init(this.$refs.marketSeasonPBChart);
      myChart.setOption(option)
   },           
 },
 components:{},
 mounted(){
   this.$http.market_cycle_detail().then(res=>{
     console.log(res.data);
     this.analysisResult = res.data     
    }).catch(err=>{
        console.log(err)
    })         
 } 
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 10px;
    margin-left: 30px;
    font-size: 16px;
}
.proCharts{
  width: 100%;
  height: 500px;
  // background: rgb(14, 51, 129);
}
</style>