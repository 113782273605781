<template>
 <div>
  <div class="navigator">
    <span><router-link :to="{name:'sensitivity_analysis_of_capital_expenditure'}" class="one">资本开支的敏感性分析</router-link></span>
    <span><router-link :to="{name:'segmented_valuation_method'}" class="two">分部估值法</router-link></span>
    <span><router-link :to="{name:'extreme_value_valuation_method'}" class="three">DCF极值估值法</router-link></span>
    <span><router-link :to="{name:'other'}" class="four">其他</router-link></span>  
  </div>
  <div>
   <router-view></router-view>
  </div>
 </div>

</template>

<script type="text/ecmascript-6">
export default {
 name: "Good_Price",
 data() {
   return {}
 }
}
</script>

<style scoped lang='scss'>
.navigator{
  width: 450px;
  margin: 5px 5px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  .one{
    color: coral;
  }
  .two{
    color: chartreuse;
  }
  .three{
    color: deepskyblue;
  }
  .four{
    color: darkmagenta;
  }
}
</style>