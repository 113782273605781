<template>
 <div>
  <div class="navigator">
    <span><router-link :to="{name:'duration_of_operation'}" class="one">经营存续期</router-link></span>
    <span><router-link :to="{name:'cash_creativity'}" class="two">现金创造力</router-link></span>
    <span><router-link :to="{name:'business_cycle_positioning'}" class="three">经营周期定位</router-link></span>
  </div>
  <div>
   <router-view></router-view>
  </div>
 </div>

</template>

<script type="text/ecmascript-6">
export default {
 name: "Good_Business",
 data() {
   return {}
 }
}
</script>

<style scoped lang='scss'>
.navigator{
  width: 300px;
  margin: 5px 5px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  .one{
    color: coral;
  }
  .two{
    color: chartreuse;
  }
  .three{
    color: deepskyblue;
  }
}
</style>