<template>
 <div class="">
    <!-- <div v-if="Object.keys(analysisResult).length==0">
      <h1>查询结果未找到！</h1>
    </div> -->
    <div>
      <!-- <div v-if="Object.keys(analysisResult.last_year).length>0 && Object.keys(analysisResult.ten_year_data).length>0 && Object.keys(analysisResult.last_day).length>0"> -->
      <div v-if="hasResult">
        <div v-if="Object.keys(analysisResult.last_day).length > 0">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="买什么" name="first"></el-tab-pane>
            <el-tab-pane label="以什么价格买" name="second"></el-tab-pane>
          </el-tabs>        
          <router-view></router-view>
        </div>
        <div v-else>
          <h1>金融类公司、5年期利润和10年期利润复合增长无法计算的公司不符合运营稳定类型公司的分析模型。请利用其他模型进行分析。</h1>
        </div>

      </div>  
      <div v-if="searchSymbol == undefined || searchSymbol == null || searchSymbol ==''">
        <h1>金融类公司、5年期利润和10年期利润复合增长无法计算的公司不符合运营稳定类型公司的分析模型。请利用其他模型进行分析。</h1>        
      </div>
   </div>

 </div>
</template>

<script type="text/ecmascript-6">
import {Tabs,TabPane} from 'element-ui'
export default {
 name: "TSA_Query_Result",
 data() {
   return {
    data:{aaa:"aaa"},
    activeName:"first",
    analysisResult:{},
    searchSymbol:'',
    hasResult:false
   }
 },
 components:{
   [Tabs.name]:Tabs,
   [TabPane.name]:TabPane
 },
 methods: {
   handleClick(tab) {
     if(tab.index == 0){
       this.$router.push({name:'four_qualitative_questions'})           
     }
     else{
       this.$router.push({name:'first_math_tool'})
     }
   }
 },
 mounted(){
   this.searchSymbol = this.$store.state.searchsymbol
   let params = new URLSearchParams()
   if(this.searchSymbol){
     params.append('symbol',this.searchSymbol)
     this.$http.sustainable_growth_analysis(params).then(res=>{
       this.analysisResult = res.data
       this.$store.state.tsa_result = res.data
       this.hasResult = true
       }).catch(err=>{
         console.log(err)
         this.analysisResult = {}
       })
   }   
   else{
    this.hasResult = false
   }    
 }
}
</script>

<style scoped lang='scss'>
</style>