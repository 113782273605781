<template>
 <div class=""> 
   <div v-if="hasResult">
     <ol class="container">
        <li>设定一个判断标准：</li>
        <p>（1）周期顶。一个申万二级行业过去10年的连续三个季度总利润同比为负，则为该行业一个周期的顶部，记录其开始季度时间</p>
        <p>（2）周期底。一个申万二级行业过去10年的连续三个季度总利润同比为正，则为该行业一个周期的底部，记录其开始季度时间</p>
        <p>（3）计算方法。取出该时间段，计算该行业在这个时间段内所有季度的平均利润和平均估值</p>
        <p>（4）比较。该行业当前的指标，或该行业内待分析公司当前的指标，和上述平均指标进行比较</p>
        <li>分析结果：</li>
          <div v-if="Object.keys(this.analysisResult.index_cycle_indicator).length > 0"> 
            <el-table
                :data="analysisResult.index_cycle_indicator"
                style="width: 601px"
                border>
                <el-table-column
                prop="index_code"
                label="行业代码"
                width="100">
                </el-table-column>
                <el-table-column
                prop="index_name"
                label="行业名称"
                width="100">
                </el-table-column>
                <el-table-column
                prop="avg_PE"
                label="周期平均PE"
                width="100">
                </el-table-column>
                <el-table-column
                prop="avg_PB"
                label="周期平均PB"
                width="100">
                </el-table-column>            
                <el-table-column
                prop="curr_PE"
                label="当前PE"
                width="100">
                </el-table-column> 
                <el-table-column
                prop="curr_PB"
                label="当前PB"
                width="100">
                </el-table-column>                                                                    
            </el-table>            
          </div>
          <div v-else>
            <p>该行业没有符合判断标准的周期特征。</p>
          </div>
     </ol>
   </div> 
   <div v-else>
     <p>该行业没有符合判断标准的周期特征。</p>    
   </div>
 </div>
</template>

<script type="text/ecmascript-6">
import {Table,TableColumn} from 'element-ui'
export default {
 name: "Periodic_Position",
 data() {
   return {
     analysisResult:{},
     hasResult: false
   }
 },
 components:{
   [Table.name]:Table,
   [TableColumn.name]:TableColumn        
 },
 mounted(){
   this.analysisResult = this.$store.state.bi_result
   if(this.analysisResult.index_cycle_indicator && Object.keys(this.analysisResult.index_cycle_indicator).length > 0){
     this.hasResult = true
   }
   else{
    this.hasResult = false
   }
 }  
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 20px;
    margin-left: 30px;
    font-size: 16px;
}    
</style>