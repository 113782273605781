import Vue from 'vue'
import App from './App.vue'
import router from "./routes"
import http from "./utils/http"
import store from "./utils/store"

import * as echarts from "echarts" 

Vue.config.productionTip = false
Vue.prototype.$http = http

Vue.prototype.$echarts = echarts 

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
