<template>
 <div class="container" v-if="Object.keys(reverseSetData).length > 0">
   <ol class="">
     <li>逆向分析结果股票集</li>  
        <div>
          <el-table
              :data="reverseSetData.reverse_set"
              style="width: 2351px"
              border>
              <el-table-column
                prop="id"
                label="序号"
                width="50">
              </el-table-column>
              <el-table-column
                prop="time"
                label="时间"
                width="100"
                :formatter="timeFormat">
              </el-table-column>
              <el-table-column
                prop="info"
                label="股票代码"
                width="100">
                <template slot-scope="scope">
                  <a @click="handleClick(scope.row.info)" style="text-decoration:underline;text-decoration-color:blue;cursor:pointer">{{scope.row.info}}</a>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="公司名称"
                width="100">
              </el-table-column>              
              <el-table-column
                prop="industry"
                label="行业"
                width="100">
              </el-table-column>
              <el-table-column
                prop="price"
                label="现价"
                width="100">
              </el-table-column>
              <el-table-column
                prop="mv"
                label="市值"
                width="200">
              </el-table-column>
              <el-table-column
                prop="EBITDA_TTM"
                label="EBITDA（TTM）"
                width="200">
              </el-table-column>              
              <el-table-column
                prop="DEBT_divide_EBITDA_TTM"
                label="DEBT除以EBITDA（TTM）"
                width="150">
              </el-table-column>
              <el-table-column
                prop="ev"
                label="企业价值"
                width="200">
              </el-table-column>   
              <el-table-column
                prop="revenue_TTM"
                label="营业收入(TTM)"
                width="200">
              </el-table-column> 
              <el-table-column
                prop="CFO"
                label="经营现金流"
                width="200">
              </el-table-column>               
              <el-table-column
                prop="last_year_repurchase_number"
                label="最近一年回购股票数量"
                width="100">
              </el-table-column>
              <el-table-column
                prop="last_year_repurchase_money"
                label="最近一年回购股票金额"
                width="150">
              </el-table-column>
              <el-table-column
                prop="three_month_total_return"
                label="3个月投资总回报"
                width="100">
              </el-table-column>
              <el-table-column
                prop="six_month_total_return"
                label="半年投资总回报"
                width="100">
              </el-table-column>
              <el-table-column
                prop="YTD_total_return"
                label="年初（年初第一个交易日）至今的股票总回报"
                width="100">
              </el-table-column>
          </el-table>
        </div>         
   </ol>
 </div>
</template>

<script type="text/ecmascript-6">
import {Table,TableColumn} from 'element-ui'
export default {
 name: "Reverse_Query",
 data() {
   return {
    reverseSetData:{}
   }
 },
 components:{
   [Table.name]:Table,
   [TableColumn.name]:TableColumn        
 },
 methods:{
   timeFormat(row, column, cellValue) {
     return cellValue.slice(0,10)
   },  
   handleClick(symbol){
     this.$store.state.reverse_symbol = symbol
     this.$router.push("/reverse/reverse_detail")
   }  
 },
 mounted(){
   this.$http.reverse_set().then(res=>{
     this.reverseSetData = res.data
    }).catch(err=>{
        console.log(err)
    })     
 } 
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 20px 20px;
    margin-left: 30px;
    font-size: 16px;
}
</style>