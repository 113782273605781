import axios from "axios";
// const BASE_URL = 'http://8.134.34.232:8000'
const BASE_URL = 'https://www.valueshunter.cn:8000';

class Http{
  constructor(){
    this.http = axios.create({
      baseURL: BASE_URL,
      timeout: 3000,
      // headers: {
      //   'X-Custom-Header': 'foobar'
      // }
    });
  }

  searchSymbol(params){
    const url = '/value/search_symbol'
    return this.http.post(url,params)
  }

  sustainable_growth_analysis(params){
    const url = '/value/sustainable_growth'
    return this.http.post(url,params)
  }

  tsa_ranking_list(){
    const url = '/value/tsa_ranking_list'
    return this.http.get(url)
  }
  
  character_analysis(params){
    const url = '/value/character'
    return this.http.post(url,params)
  }

  comparability_analysis(params){
    const url = '/value/comparability'
    return this.http.post(url,params)    
  }

  ffa_ranking_list(){
    const url = '/value/ffa_ranking_list'
    return this.http.get(url)
  }  

  searchIndustry(){
    const url = '/value/industry'
    return this.http.get(url)
  }

  comparability_analysis_common(params){
    const url = '/value/comparability_common'
    return this.http.post(url,params)
  }

  comparability_analysis_financial(params){
    const url = '/value/comparability_financial'
    return this.http.post(url,params)
  }

  comparability_analysis_industry(params){
    const url = '/value/comparability_industry_data'
    return this.http.post(url,params)
  }

  el_ranking_list(){
    const url = '/value/el_ranking_list'
    return this.http.get(url)
  }  

  reverse_set(){
    const url = '/reverse/reverse_set'
    return this.http.get(url)
  }

  reverse_detail(params){
    const url = '/reverse/reverse_detail'
    return this.http.post(url,params)    
  }

  financial_business_cycle_detail(){
    const url = '/cycle/financial_business_cycles'
    return this.http.get(url)
  } 
  
  market_cycle_detail(){
    const url = '/cycle/market_cycle'
    return this.http.get(url)    
  }

  business_cycle_detail(){
    const url = '/cycle/business_cycle'
    return this.http.get(url)  
  }

  industry_indicator_detail(params){
    const url = '/cycle/industry_indicator'
    return this.http.post(url,params)
  }

  indicator_ranking_list(){
    const url = '/cycle/indicator_ranking_list'
    return this.http.get(url)    
  }

  industry_stock_info_detail(params){
    const url = '/cycle/stock_info'
    return this.http.post(url,params)
  }

  quantitative_result_list(){
    const url = '/quantitative/quantitative_result_list'
    return this.http.get(url)   
  }
}

export default new Http()

