<template>
 <div v-if="industry.length > 0">
   <div class="inputform">
      <el-form ref="form" label-width="150px">
             <el-form-item label="请输入或选择行业:">
                <el-autocomplete
                popper-class="my-autocomplete"
                v-model="symbol"
                :fetch-suggestions="querySearch"
                :debounce="500"
                @select="handleSelect"
                @blur="handleBlur"
                >
                <template slot-scope="{ item }">
                    <div class="name">{{item.index_code}}&nbsp;&nbsp;&nbsp;&nbsp;{{item.index_name}}</div>
                </template>  
                </el-autocomplete>
            </el-form-item>
       </el-form>  
    </div>
    <div class="selectform" v-if="Object.keys(options).length > 0">
      <el-form ref="form2" label-width="150px">        
        <el-form-item label="请选择公司">
          <el-select v-model="company" multiple placeholder="请选择" @change="onSelect">
            <el-option
                v-for="item in options[0].industry_component"
                :key="item.company_code"
                :label="item.company_name"
                :value="item.company_code">
            </el-option>
          </el-select>
            <el-button type="primary" @click="handleClick">查询</el-button>   
        </el-form-item>
      </el-form>
   </div>
   <br>
   <div class="container" v-if="common.length > 0 && financialRatio.length > 0 && industry_data.length > 0">
     <ol>
       <li>归一化后的百分比的比较</li>
       <table class="abs_table" border="1">
          <thead>
            <tr>
              <th>公司</th>
              <th v-for='(item,index) in company' :key='index' :colspan="Object.keys(common).length/Object.keys(company).length">
                <div v-for="value in options[0].industry_component" :key="value.company_code">
                  <span v-if="item == value.company_code">{{value.company_name}}</span>
                </div>
              </th>
            </tr>
            <tr>
              <th>时间</th>  
              <th v-for='(item,index) in common' :key='index' class="content">{{item.time.substring(0,4)}}</th> 
            </tr>
          </thead>
          <tbody>
            <tr>
              <td :colspan="Object.keys(common).length*4"><b>利润表数据（单位：%）</b></td>
            </tr>
            <tr>
              <td>净销售收入 100%</td>
              <td v-for='(item,index) in common' :key='index' class="content">100</td> 
            </tr>
            <tr>
              <td>销货成本的百分比</td>
              <td v-for='(item,index) in common' :key='index' class="content">{{item.cogs_pct}}</td> 
            </tr>
            <tr>
              <td>销售费用、一般费用及管理费用的百分比</td>
              <td v-for='(item,index) in common' :key='index' class="content">{{item.sga_pct}}</td> 
            </tr>
            <tr>
              <td>研发费用的百分比</td>
              <td v-for='(item,index) in common' :key='index' class="content">{{item.rd_pct}}</td> 
            </tr>
            <tr>
              <td>折旧的百分比</td>
              <td v-for='(item,index) in common' :key='index' class="content">{{item.dep_pct}}</td> 
            </tr>                         
            <tr>
              <td>息税前利润（EBIT）的百分比</td>
              <td v-for='(item,index) in common' :key='index' class="content">{{item.ebit_pct}}</td> 
            </tr> 
            <tr>
              <td>EBITDA的百分比</td>
              <td v-for='(item,index) in common' :key='index' class="content">{{item.ebitda_pct}}</td> 
            </tr>
            <tr>
              <td>债务利息的百分比</td>
              <td v-for='(item,index) in common' :key='index' class="content">{{item.int_pct}}</td> 
            </tr>
            <tr>
              <td>税前利润的百分比</td>
              <td v-for='(item,index) in common' :key='index' class="content">{{item.ebt_pct}}</td> 
            </tr> 
            <tr>
              <td>所得税的百分比</td>
              <td v-for='(item,index) in common' :key='index' class="content">{{item.tax_pct}}</td> 
            </tr>
            <tr>
              <td>净利润的百分比</td>
              <td v-for='(item,index) in common' :key='index' class="content">{{item.net_income_pct}}</td> 
            </tr> 
            <tr>
              <td :colspan="Object.keys(common).length*4"><b>资产负债表数据（单位：%）</b></td>
            </tr>
            <tr>
              <td>现金的百分比</td>
              <td v-for='(item,index) in common' :key='index' class="content">{{item.cash_pct}}</td> 
            </tr> 
            <tr>
              <td>净营运资本的百分比</td>
              <td v-for='(item,index) in common' :key='index' class="content">{{item.net_working_capital_pct}}</td> 
            </tr> 
            <tr>
              <td>总资产100%</td>
              <td v-for='(item,index) in common' :key='index' class="content">100</td> 
            </tr> 
            <tr>
              <td>长期负债，减本年到期部分的百分比</td>
              <td v-for='(item,index) in common' :key='index' class="content">{{item.long_term_debt_minus_the_portion_this_year_pct}}</td> 
            </tr> 
            <tr>
              <td>股东权益的百分比</td>
              <td v-for='(item,index) in common' :key='index' class="content">{{item.equity_pct}}</td> 
            </tr> 
          </tbody>
       </table> 
       <div class="chartGroup">
         <div class="proCharts" ref="cogsChart" ></div>
         <div class="proCharts" ref='sgaChart'></div>
       </div>
       <div class="chartGroup">
         <div class="proCharts" ref="rdChart" ></div>
         <div class="proCharts" ref='depChart'></div>
       </div>       
       <div class="chartGroup">
         <div class="proCharts" ref="ebitChart" ></div>
         <div class="proCharts" ref='ebitdaChart'></div>
       </div>  
       <div class="chartGroup">
         <div class="proCharts" ref="intChart" ></div>
         <div class="proCharts" ref='ebtChart'></div>
       </div>    
       <div class="chartGroup">
         <div class="proCharts" ref="taxChart" ></div>
         <div class="proCharts" ref='netIncomeChart'></div>
       </div> 
       <div class="chartGroup">
         <div class="proCharts" ref="cashChart" ></div>
         <div class="proCharts" ref='netWorkingCapitalChart'></div>
       </div>  
       <div class="chartGroup">
         <div class="proCharts" ref="debtChart" ></div>
         <div class="proCharts" ref='equityChart'></div>
       </div>                            
       <li>财务比率的比较</li>   
       <div>
         <table class="abs_table" border="1">
           <thead>
            <tr>
              <th>公司</th>
              <th v-for='(item,index) in company' :key='index' :colspan="Object.keys(financialRatio).length/Object.keys(company).length">
                <div v-for="value in options[0].industry_component" :key="value.company_code">
                  <span v-if="item == value.company_code">{{value.company_name}}</span>
                </div>
              </th>
            </tr>
            <tr>
              <th>时间</th>  
              <th v-for='(item,index) in financialRatio' :key='index' class="content">{{item.time.substring(0,4)}}</th> 
            </tr>
           </thead>
           <tbody>
             <tr>
              <td :colspan="Object.keys(financialRatio).length*4"><b>利润表数据（单位：%）</b></td>
             </tr>
             <tr>
               <td>净利润/平均股东权益</td>
               <td v-for='(item,index) in financialRatio' :key='index' class="content">{{item.net_income_divide_avg_equity}}</td> 
             </tr>
             <tr>
               <td>EBITDA/平均总资产</td>
               <td v-for='(item,index) in financialRatio' :key='index' class="content">{{item.ebitda_divide_avg_total_asset}}</td> 
             </tr>
             <tr>
               <td :colspan="Object.keys(financialRatio).length*4"><b>周转能力比率（单位：%）</b></td>
             </tr>             
              <tr>
               <td>销售额/平均总资产</td>
               <td v-for='(item,index) in financialRatio' :key='index' class="content">{{item.revenue_divide_avg_total_asset}}</td> 
             </tr>
              <tr>
               <td>平均存货/销货成本</td>
               <td v-for='(item,index) in financialRatio' :key='index' class="content">{{item.avg_inventory_divide_cogs}}</td> 
             </tr>
             <tr>
               <td :colspan="Object.keys(financialRatio).length*4"><b>信贷比率（单位：%）</b></td>
             </tr>                         
              <tr>
               <td>流动资产/流动负债</td>
               <td v-for='(item,index) in financialRatio' :key='index' class="content">{{item.curr_asset_divide_curr_debt}}</td> 
             </tr> 
             <tr>
               <td>总负债/股东权益</td>
               <td v-for='(item,index) in financialRatio' :key='index' class="content">{{item.debt_divide_equity}}</td> 
             </tr>
             <tr>
               <td>EBIT/利息</td>
               <td v-for='(item,index) in financialRatio' :key='index' class="content">{{item.ebit_divide_interest}}</td> 
             </tr>
             <tr>
               <td :colspan="Object.keys(financialRatio).length*4"><b>增长比率（单位：%）</b></td>
             </tr> 
             <tr>
               <td>销售收入年化增速</td>
               <td class="content" colspan="1" v-for="(item,index) in financialRatio" :key="index">
                 <div v-if="index%3==0">{{item.revenue_annual_growth}}</div>
               </td> 
             </tr>
             <tr>
               <td>EBIT年化增速</td>
               <td class="content" colspan="1" v-for="(item,index) in financialRatio" :key="index">
                 <div v-if="index%3==0">{{item.ebit_annual_growth}}</div>
               </td> 
             </tr> 
             <tr>
               <td>EBITDA年化增速</td>
               <td class="content" colspan="1" v-for="(item,index) in financialRatio" :key="index">
                 <div v-if="index%3==0">{{item.ebitda_annual_growth}}</div>
               </td> 
             </tr> 
             <tr>
               <td>税前利润年化增速</td>
               <td class="content" colspan="1" v-for="(item,index) in financialRatio" :key="index">
                 <div v-if="index%3==0">{{item.ebt_annual_growth}}</div>
               </td> 
             </tr> 
             <tr>
               <td>净利润年化增速</td>
               <td class="content" colspan="1" v-for="(item,index) in financialRatio" :key="index">
                 <div v-if="index%3==0">{{item.net_income_annual_growth}}</div>
               </td> 
             </tr> 
           </tbody>
         </table>       
       </div> 
       <div class="chartGroup">
         <div class="proCharts" ref="netIncomeDivideAvgEquityChart" ></div>
         <div class="proCharts" ref='ebitdaDivideAvgTotalAssetChart'></div>
       </div>
       <div class="chartGroup">
         <div class="proCharts" ref="revenueDivideAvgTotalAssetChart" ></div>
         <div class="proCharts" ref='avgInventoryDivideCogsChart'></div>
       </div>   
       <div class="chartGroup">
         <div class="proCharts" ref="currAssetDivideCurrDebtChart" ></div>
         <div class="proCharts" ref='debtDivideEquityChart'></div>
         <div class="proCharts" ref='ebitDivideInterestChart'></div>
       </div>                           
       <li>行业数据</li>
       <div>
         <table class="abs_table" border="1">
           <thead>
             <tr>
               <th>时间</th>  
               <th v-for='item in industry_data' :key='item.time' class="content">{{item.time.substring(0,7)}}</th> 
             </tr>
           </thead>
           <tbody>
             <tr>
               <td>行业名</td>
               <td :colspan="Object.keys(industry_data).length" style="center"><b>{{industry_data[0].industry_name}}</b></td>
             </tr>
             <tr>
               <td>行业代码</td>
               <td :colspan="Object.keys(industry_data).length"><b>{{industry_data[0].industry_code}}</b></td>
             </tr>             
             <tr>
               <td>平均负债率（%）</td>
               <td v-for="item in industry_data" :key="item.time" class="content">{{item.avg_debt_ratio}}</td> 
             </tr>
             <tr>
               <td>平均扣非ROE（%）</td>
               <td v-for="item in industry_data" :key="item.time" class="content">{{item.avg_ROE_minus_non_recurring_gains}}</td> 
             </tr>
              <tr>
               <td>总营收</td>
               <td v-for="item in industry_data" :key="item.time" class="content">{{item.revenue}}</td> 
             </tr>
              <tr>
               <td>总扣非净利润</td>
               <td v-for="item in industry_data" :key="item.time" class="content">{{item.net_profit_minus_non_recurring_gains}}</td> 
             </tr>
           </tbody>
         </table>           
       </div>          
     </ol>
   </div>
 </div>
</template>

<script type="text/ecmascript-6">
import {Form, FormItem,Autocomplete,Button,Select,Option} from "element-ui"
export default {
  name: "CA_Query",
 data() {
   return {
      industry: [],
      symbol:'',
      company:[],
      options:[],
      common:[],
      financialRatio:[],
      industry_data:[]
   }
 },
  components:{
    [Autocomplete.name]: Autocomplete,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option
  },
  methods:{
    querySearch(queryString, cb) {
      var industry = this.industry
      var results = queryString ? industry.filter(inputsymbol=>(inputsymbol.index_code.indexOf(queryString) === 0  || inputsymbol.index_name.indexOf(queryString) != -1)) : industry;
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    handleSelect(item){
     this.company = []
     this.common = []
     this.financialRatio = []
     this.symbol= item.index_code
     this.options = this.industry.filter(inputsymbol=>inputsymbol.index_code.indexOf(this.symbol) === 0) 
    },
    onSelect(){
     this.common = []
     this.financialRatio = []
    },
    handleBlur(){
     this.company = []
     this.common = []
     this.financialRatio = []
     this.options = this.industry.filter(inputsymbol=>inputsymbol.index_code.indexOf(this.symbol) === 0)
    },
    handleClick(){
      this.common = []
      this.financialRatio = []
      var company = JSON.stringify({companys:this.company.sort()})
      let params = new URLSearchParams()
      params.append('company',company)      
      this.$http.comparability_analysis_common(params).then(res=>{
        this.common = res.data
     }).catch(err=>{
       console.log(err)
     }) 
     
      this.$http.comparability_analysis_financial(params).then(res=>{
        this. financialRatio = res.data
     }).catch(err=>{
       console.log(err)
     })   
     
     params = new URLSearchParams()
     params.append('index_code',this.symbol)      
     this.$http.comparability_analysis_industry(params).then(res=>{
        this.industry_data = res.data
     }).catch(err=>{
       console.log(err)
     })   
    },
    cogs_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.common[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.common).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.common[i+j*3].cogs_pct)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.common[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }
     for(i=0;i<Object.keys(this.common).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: '营业成本归一化百分比的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.cogsChart);
      myChart.setOption(option)      
    },  
    sga_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [], name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.common[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.common).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.common[i+j*3].sga_pct)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.common[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.common).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: '三费归一化百分比的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.sgaChart);
      myChart.setOption(option)      
    },    
    rd_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.common[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.common).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.common[i+j*3].rd_pct)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.common[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.common).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: '研发费用归一化百分比的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.rdChart);
      myChart.setOption(option)      
    }, 
    dep_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.common[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.common).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.common[i+j*3].dep_pct)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.common[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.common).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: '折旧费用归一化百分比的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.depChart);
      myChart.setOption(option)      
    },  
    ebit_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.common[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.common).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.common[i+j*3].ebit_pct)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.common[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.common).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: 'EBIT归一化百分比的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.ebitChart);
      myChart.setOption(option)      
    }, 
    ebitda_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.common[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.common).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.common[i+j*3].ebitda_pct)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.common[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.common).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: 'EBITDA归一化百分比的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.ebitdaChart);
      myChart.setOption(option)      
    },
    int_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.common[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.common).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.common[i+j*3].int_pct)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.common[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.common).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: '债务利息归一化百分比的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.intChart);
      myChart.setOption(option)      
    },
    ebt_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.common[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.common).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.common[i+j*3].ebt_pct)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.common[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.common).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: '税前利润（EBT）归一化百分比的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.ebtChart);
      myChart.setOption(option)      
    }, 
    tax_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.common[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.common).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.common[i+j*3].tax_pct)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.common[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.common).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: '所得税归一化百分比的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.taxChart);
      myChart.setOption(option)      
    }, 
    net_income_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.common[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.common).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.common[i+j*3].net_income_pct)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.common[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.common).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: '净利润归一化百分比的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.netIncomeChart);
      myChart.setOption(option)      
    },
    cash_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.common[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.common).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.common[i+j*3].cash_pct)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.common[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.common).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: '现金及等价物归一化百分比的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.cashChart);
      myChart.setOption(option)      
    }, 
    net_working_capital_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.common[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.common).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.common[i+j*3].net_working_capital_pct)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.common[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.common).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: '净营运资本归一化百分比的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.netWorkingCapitalChart);
      myChart.setOption(option)      
    },
    debt_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.common[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.common).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.common[i+j*3].long_term_debt_minus_the_portion_this_year_pct)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.common[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.common).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: '长期负债减本年到期部分的归一化百分比的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.debtChart);
      myChart.setOption(option)      
    },  
    equity_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.common[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.common).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.common[i+j*3].equity_pct)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.common[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.common).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: '股东权益归一化百分比的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.equityChart);
      myChart.setOption(option)      
    },  
    net_income_divide_avg_equity_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.financialRatio[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.financialRatio).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.financialRatio[i+j*3].net_income_divide_avg_equity)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.financialRatio[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.financialRatio).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: '净利润/平均股东权益的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.netIncomeDivideAvgEquityChart);
      myChart.setOption(option)      
    },  
    ebitda_divide_avg_total_asset_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.financialRatio[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.financialRatio).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.financialRatio[i+j*3].ebitda_divide_avg_total_asset)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.financialRatio[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.financialRatio).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: 'EBITDA/平均总资产的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.ebitdaDivideAvgTotalAssetChart);
      myChart.setOption(option)      
    },  
    revenue_divide_avg_total_asset_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.financialRatio[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.financialRatio).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.financialRatio[i+j*3].revenue_divide_avg_total_asset)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.financialRatio[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.financialRatio).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: '销售额/平均总资产的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.revenueDivideAvgTotalAssetChart);
      myChart.setOption(option)      
    },  
    avg_inventory_divide_cogs_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.financialRatio[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.financialRatio).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.financialRatio[i+j*3].avg_inventory_divide_cogs)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.financialRatio[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.financialRatio).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: '平均存货/销货成本的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.avgInventoryDivideCogsChart);
      myChart.setOption(option)      
    },   
    curr_asset_divide_curr_debt_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.financialRatio[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.financialRatio).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.financialRatio[i+j*3].curr_asset_divide_curr_debt)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.financialRatio[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.financialRatio).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: '流动资产/流动负债的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.currAssetDivideCurrDebtChart);
      myChart.setOption(option)      
    },   
    debt_divide_equity_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.financialRatio[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.financialRatio).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.financialRatio[i+j*3].debt_divide_equity)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.financialRatio[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.financialRatio).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: '总负债/股东权益的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.debtDivideEquityChart);
      myChart.setOption(option)      
    },
    ebit_divide_interest_chart(){
     var xdata = []
     var ydata = [],adata = [],bdata = [],seriesData = [],name = []
     for(var i=0;i<3;i++){
       xdata.splice(0,0,this.financialRatio[i].time.substring(0,4))
     }
     for(var j=0;j<Object.keys(this.financialRatio).length/3;j++){
       for(i=0;i<3;i++){
         ydata.splice(0,0,this.financialRatio[i+j*3].ebit_divide_interest)
       }
       bdata.push(ydata)
       ydata = []          
       adata.splice(adata.length,0,this.financialRatio[j*3].info) 
     }
     for(i=0;i<adata.length;i++){
       for(j=0;j<this.options[0].industry_component.length;j++){
         if(adata[i] == this.options[0].industry_component[j].company_code){
           name.splice(adata.length,0,this.options[0].industry_component[j].company_name)
         }
       }
     }     
     for(i=0;i<Object.keys(this.financialRatio).length/3;i++){
       seriesData.push({
         name: name[i],
         type:'bar',
         stack: 'total',
         label: {
           show: true
         },
         emphasis: {
           focus: 'series'
         },
         data:bdata[i]       
       })
     }      
     var option =  {
        title: {
          text: 'EBIT/利息的对比'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: xdata
        },
        series: seriesData
      }
      let myChart =this.$echarts.init(this.$refs.ebitDivideInterestChart);
      myChart.setOption(option)      
    },                                                         
  },
  updated(){
    this.$nextTick(()=>{
      if(this.common.length > 0){
        this.cogs_chart()
        this.sga_chart()
        this.rd_chart() 
        this.dep_chart()
        this.ebit_chart()
        this.ebitda_chart()
        this.int_chart()
        this.ebt_chart()
        this.tax_chart()
        this.net_income_chart()
        this.cash_chart()
        this.net_working_capital_chart()
        this.debt_chart()
        this.equity_chart()
        this.net_income_divide_avg_equity_chart()
        this.ebitda_divide_avg_total_asset_chart()
        this.revenue_divide_avg_total_asset_chart()
        this.avg_inventory_divide_cogs_chart()
        this.curr_asset_divide_curr_debt_chart()
        this.debt_divide_equity_chart()
        this.ebit_divide_interest_chart()
      }           
    })

  },
  mounted(){
    this.$http.searchIndustry().then(res=>{
     this.industry = res.data
    }).catch(err=>{
        console.log(err)
    })     
  }
}
</script>

<style scoped lang='scss'>
.container li{
  margin: 20px;
  margin-left: 30px;
  font-size: 16px;
}
.abs_table{
  margin-left: 30px;
  .content{
    padding-left: 50px;
    padding-right: 50px;
  }
  td{
    text-align: center;
    min-width: 100px;
  }
}
.chartGroup{
  margin: 20px 20px;
  padding-top: 100px;
  display: flex;
  .proCharts{
    width: 50%;
    height: 300px;
    // background: rgb(14, 51, 129);
  }  
}


</style>