<template>
 <div class="">
   <div v-if="hasResult">
     <ol class="container">
        <li>行业包含的公司10年以来的各项汇总指标</li>
            <el-table
                :data="analysisResult.business_financial_indicator"
                style="width: 1341px"
                border>
                <el-table-column
                prop="time"
                label="时间"
                width="100"
                :formatter="timeFormat">
                </el-table-column>
                <el-table-column
                prop="industry_code"
                label="行业代码"
                width="100">
                </el-table-column>
                <el-table-column
                prop="industry_name"
                label="行业名称"
                width="100">
                </el-table-column>
                <el-table-column
                prop="revenue"
                label="总营收"
                width="200">
                </el-table-column>
                <el-table-column
                prop="net_profit_minus_non_recurring_gains"
                label="总扣非净利润"
                width="200">
                </el-table-column>            
                <el-table-column
                prop="capital_expenditure"
                label="总资本开支"
                width="200">
                </el-table-column>
                <el-table-column
                prop="equity"
                label="总股东权益"
                width="200">
                </el-table-column>
                <el-table-column
                prop="avg_debt_ratio"
                label="平均负债（%）"
                width="120">
                </el-table-column>
                <el-table-column
                prop="avg_ROE_minus_non_recurring_gains"
                label="平均扣非ROE"
                width="120">
                </el-table-column>    
                                                                    
            </el-table>
        <li>营收与利润</li>
        <div>
          <el-select v-model="RIValue" placeholder="请选择" size="mini">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="proCharts" ref="RIChart" ></div>     
        <li>资本开支与负债率</li> 
        <div>
          <el-select v-model="CDValue" placeholder="请选择" size="mini">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>            
        <div class="proCharts" ref="CDChart" ></div>   
        <li>利润与资本开支</li> 
        <div>
          <el-select v-model="ICValue" placeholder="请选择" size="mini">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>            
        <div class="proCharts" ref="ICChart" ></div>  
        <li>负债率和ROE</li> 
        <div>
          <el-select v-model="DRValue" placeholder="请选择" size="mini">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>           
        <div class="proCharts" ref="DRChart" ></div>                               
     </ol>   
   </div>
   
  
 </div>
</template>

<script type="text/ecmascript-6">
import {Table,TableColumn,Select,Option} from 'element-ui'
export default {
 name: "Important_Indicators",
 data() {
   return {
     analysisResult:{},
     hasResult:false,
     RIValue:"1",
     CDValue:"1",
     ICValue:"1",
     DRValue:"1",
     options: [
       {
        value: '1', 
        label: '季度'
       }, 
       {
        value: '2',
        label: '年度'
        }
     ]     
   }
 },
 methods:{
   timeFormat(row, column, cellValue) {
     return cellValue.slice(0,7)
   },
   RIChart(){
     var xdata = [],ydata=[],zdata=[],adata=[],bdata=[]
      for(var i=0;i<Object.keys(this.analysisResult.business_financial_indicator).length;i++){
        if(this.RIValue == '1'){
          xdata.splice(0,0,this.analysisResult.business_financial_indicator[i].time.slice(0,7))
          ydata.splice(0,0,this.analysisResult.business_financial_indicator[i].revenue)
          zdata.splice(0,0,this.analysisResult.business_financial_indicator[i].net_profit_minus_non_recurring_gains)
          adata.splice(0,0,this.analysisResult.business_financial_indicator[i].revenue_YOY)
          bdata.splice(0,0,this.analysisResult.business_financial_indicator[i].net_profit_minus_non_recurring_gains_YOY)
        }
        else{
          if(this.analysisResult.business_financial_indicator[i].time.slice(5,7) == '12'){
            xdata.splice(0,0,this.analysisResult.business_financial_indicator[i].time.slice(0,7))
            ydata.splice(0,0,this.analysisResult.business_financial_indicator[i].revenue)
            zdata.splice(0,0,this.analysisResult.business_financial_indicator[i].net_profit_minus_non_recurring_gains)
            adata.splice(0,0,this.analysisResult.business_financial_indicator[i].revenue_YOY)
            bdata.splice(0,0,this.analysisResult.business_financial_indicator[i].net_profit_minus_non_recurring_gains_YOY)            
          }
        }

      }               
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "值",
              position: "left"
             },
            {
              type: 'value',
              name: "同比（%）",
              position: "right"
             }             
          ],  
          series: [
            {
              name: "总营收",
              data: ydata,
              yAxisIndex: 0,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "总扣非净利润",
              data: zdata,
              yAxisIndex: 0,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },            
            {
              name: "营收同比",
              data: adata,
              yAxisIndex: 1,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "利润同比",
              data: bdata,
              yAxisIndex: 1,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }            
          ]
        }
      let myChart =this.$echarts.init(this.$refs.RIChart);
      myChart.setOption(option)
   }, 
   CDChart(){
     var xdata = [],ydata=[],zdata=[],adata=[],bdata=[]
      for(var i=0;i<Object.keys(this.analysisResult.business_financial_indicator).length;i++){
        if(this.CDValue == '1'){
          xdata.splice(0,0,this.analysisResult.business_financial_indicator[i].time.slice(0,7))
          ydata.splice(0,0,this.analysisResult.business_financial_indicator[i].capital_expenditure)
          zdata.splice(0,0,this.analysisResult.business_financial_indicator[i].avg_debt_ratio)
          adata.splice(0,0,this.analysisResult.business_financial_indicator[i].capital_expenditure_YOY)
          bdata.splice(0,0,this.analysisResult.business_financial_indicator[i].avg_debt_ratio_YOY)
        }
        else{
          if(this.analysisResult.business_financial_indicator[i].time.slice(5,7) == '12'){
            xdata.splice(0,0,this.analysisResult.business_financial_indicator[i].time.slice(0,7))
            ydata.splice(0,0,this.analysisResult.business_financial_indicator[i].capital_expenditure)
            zdata.splice(0,0,this.analysisResult.business_financial_indicator[i].avg_debt_ratio)
            adata.splice(0,0,this.analysisResult.business_financial_indicator[i].capital_expenditure_YOY)
            bdata.splice(0,0,this.analysisResult.business_financial_indicator[i].avg_debt_ratio_YOY) 
          }             
        }
      }                 
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "值",
              position: "left"
             },
            {
              type: 'value',
              name: "同比（%）",
              position: "right"
             }             
          ],  
          series: [
            {
              name: "总资本开支",
              data: ydata,
              yAxisIndex: 0,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "平均负债率",
              data: zdata,
              yAxisIndex: 1,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },            
            {
              name: "资本开支同比",
              data: adata,
              yAxisIndex: 1,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "平均负债率同比",
              data: bdata,
              yAxisIndex: 1,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }            
          ]
        }
      let myChart =this.$echarts.init(this.$refs.CDChart);
      myChart.setOption(option)
   },  
   ICChart(){
     var xdata = [],ydata=[],zdata=[],adata=[],bdata=[]
      for(var i=0;i<Object.keys(this.analysisResult.business_financial_indicator).length;i++){
        if(this.ICValue == '1'){
          xdata.splice(0,0,this.analysisResult.business_financial_indicator[i].time.slice(0,7))
          ydata.splice(0,0,this.analysisResult.business_financial_indicator[i].net_profit_minus_non_recurring_gains)
          zdata.splice(0,0,this.analysisResult.business_financial_indicator[i].capital_expenditure)
          adata.splice(0,0,this.analysisResult.business_financial_indicator[i].net_profit_minus_non_recurring_gains_YOY)
          bdata.splice(0,0,this.analysisResult.business_financial_indicator[i].capital_expenditure_YOY)
        }
        else{
          if(this.analysisResult.business_financial_indicator[i].time.slice(5,7) == '12'){  
            xdata.splice(0,0,this.analysisResult.business_financial_indicator[i].time.slice(0,7))
            ydata.splice(0,0,this.analysisResult.business_financial_indicator[i].net_profit_minus_non_recurring_gains)
            zdata.splice(0,0,this.analysisResult.business_financial_indicator[i].capital_expenditure)
            adata.splice(0,0,this.analysisResult.business_financial_indicator[i].net_profit_minus_non_recurring_gains_YOY)
            bdata.splice(0,0,this.analysisResult.business_financial_indicator[i].capital_expenditure_YOY)          
          }
        }
      }               
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "值",
              position: "left"
             },
            {
              type: 'value',
              name: "同比（%）",
              position: "right"
             }             
          ],  
          series: [
            {
              name: "总扣非净利润",
              data: ydata,
              yAxisIndex: 0,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "总资本开支",
              data: zdata,
              yAxisIndex: 0,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },            
            {
              name: "利润同比",
              data: adata,
              yAxisIndex: 1,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "资本开支同比",
              data: bdata,
              yAxisIndex: 1,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }            
          ]
        }
      let myChart =this.$echarts.init(this.$refs.ICChart);
      myChart.setOption(option)
   }, 
   DRChart(){
     var xdata = [],ydata=[],zdata=[],adata=[],bdata=[]
      for(var i=0;i<Object.keys(this.analysisResult.business_financial_indicator).length;i++){
        if(this.DRValue == '1'){        
          xdata.splice(0,0,this.analysisResult.business_financial_indicator[i].time.slice(0,7))
          ydata.splice(0,0,this.analysisResult.business_financial_indicator[i].avg_debt_ratio)
          zdata.splice(0,0,this.analysisResult.business_financial_indicator[i].avg_ROE_minus_non_recurring_gains)
          adata.splice(0,0,this.analysisResult.business_financial_indicator[i].avg_debt_ratio_YOY)
          bdata.splice(0,0,this.analysisResult.business_financial_indicator[i].avg_ROE_minus_non_recurring_gains_YOY)
        }
        else{
          if(this.analysisResult.business_financial_indicator[i].time.slice(5,7) == '12'){
            xdata.splice(0,0,this.analysisResult.business_financial_indicator[i].time.slice(0,7))
            ydata.splice(0,0,this.analysisResult.business_financial_indicator[i].avg_debt_ratio)
            zdata.splice(0,0,this.analysisResult.business_financial_indicator[i].avg_ROE_minus_non_recurring_gains)
            adata.splice(0,0,this.analysisResult.business_financial_indicator[i].avg_debt_ratio_YOY)
            bdata.splice(0,0,this.analysisResult.business_financial_indicator[i].avg_ROE_minus_non_recurring_gains_YOY)   
          }         
        }
      }                                
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "值",
              position: "left"
             },
            {
              type: 'value',
              name: "同比（%）",
              position: "right"
             }             
          ],  
          series: [
            {
              name: "平均负债率",
              data: ydata,
              yAxisIndex: 1,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "扣非ROE",
              data: zdata,
              yAxisIndex: 0,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },            
            {
              name: "负债率同比",
              data: adata,
              yAxisIndex: 1,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "ROE同比",
              data: bdata,
              yAxisIndex: 1,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }            
          ]
        }
      let myChart =this.$echarts.init(this.$refs.DRChart);
      myChart.setOption(option)
   },               
 },  
 updated(){
   this.$nextTick(()=>{
     if(Object.keys(this.analysisResult).length>0){
       this.RIChart()
       this.CDChart()
       this.ICChart()
       this.DRChart()
     } 
   })
 },   
 components:{
   [Table.name]:Table,
   [TableColumn.name]:TableColumn,
   [Select.name]:Select,
   [Option.name]:Option       
 },
 mounted(){
   this.analysisResult = this.$store.state.bi_result
   if(this.analysisResult.business_financial_indicator && Object.keys(this.analysisResult.business_financial_indicator).length > 0){
     this.hasResult = true
   }
   else{
    this.hasResult = false
   }
 }
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 20px;
    margin-left: 30px;
    font-size: 16px;
}
.proCharts{
  width: 100%;
  height: 500px;
  // background: rgb(14, 51, 129);
}

</style>