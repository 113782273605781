<template>
 <div class="">
    <article>
      <section>
        <p class="paragraph">价值投资工具箱目前提供四个分析工具：</p>
        <p class="paragraph"><b>1、时间序列分析</b></p>
        <p class="paragraph">这里的时间序列分析，并不是量化投资或者技术分析里的价格时间序列统计分析，而是一种“企业前景投资“的投资方法。</p>
        <p class="paragraph">时间序列分析主要分析的目标是“持续经营稳定的公司”，这类公司具有消费垄断的特征（即收费桥），运营非常稳定，收入稳定增长，长期稳定分红或者净资产收益率长期保持稳定。本工具的主要思想就是在价格具有商业价值时买入优质企业。所谓商业价值是指投资的年化复合收益超过平均水平，满足投资者的收益要求。</p>
        <p class="paragraph">使用本工具的投资者需要关注企业是否满足“持续经营稳定的公司”的条件，即回答11个问题，并利用3个数学工具计算投资的年化收益率。</p>
        <p class="paragraph">注意，使用本工具分析不满足“持续经营稳定的公司”的条件的目标会存在估值错误，但还是可以作为一种筛选过滤的工具进行参考。</p>
        <br>
        <p class="paragraph"><b>2、财务特征分析</b></p>
        <p class="paragraph">财务特征分析针对所有上市企业，主要通过行业信息和财务数据甄别企业是否具有“好生意，好公司，好价格”的经营特征。</p>
        <p class="paragraph">（1）好生意</p>
        <p class="paragraph">分析企业的生意模式是否优秀，是否存在适合投资的内在价值。这里使用“DCF三要素”的思维体系，并引入了一些财务指标以做鉴别。</p>
        <p class="paragraph">（2）好公司</p>
        <p class="paragraph">判断一个公司是否为好公司，主要通过识别这个企业的经营特性，即经营特性分析。这里我们使用了对净资产收益率的结构分析（杜邦分析法）的3个指标，分析这3个指标10年的变化趋势，并与平均水平进行比较。</p>
        <p class="paragraph">（3）好价格</p>
        <p class="paragraph">一个拥有好的生意模式并经营稳健的企业如果卖价昂贵、估值很高，那么就不是投资的好时机。我们提出3种估值假设，通过极值分析或历史平均的方法进行判断。</p>
        <br>
        <p class="paragraph"><b>3、可比性分析</b></p>
        <p class="paragraph">对一家成熟型公司的未来前景的评估首先是基于其过往的历史。使用上市企业财务报表作为原始素材，以及4个基本工具：绝对数量变化、增长变化的百分比、归一化后的百分比和财务比率。这些工具使用分析对象3年的历史数据，并与同行业公司的同类数据进行比较。</p>
        <p class="paragraph">大多数行业的财务比率长期来看都表现得很一致，当财务比率分析中出现不合拍的结果时，比率失调或极度差异的原因就成为进一步研究该公司的焦点。</p>
        <br>
        <p class="paragraph"><b>4、边缘企业和杠杆收购分析</b></p>
        <p class="paragraph">边缘企业，又称问题企业，一般是指长期亏损市值很小，很少有投资机构关注的上市公司。本工具指导投资者分析边缘企业业绩欠佳的7个原因，从4个方面考虑是否值得投资，并提供3种估值方法计算边缘企业的投资价值。</p>
        <p class="paragraph">杠杆收购分析是另一种对正常运营企业的估值分析方法，可以作为其他估值方法的有效补充。这个方法假设有私募股权公司考虑收购一个企业，该企业应该具有什么特征，以及私募投资人应该支付多少价格。本工具指导投资者分析收购对象的4个特征，分析收购的3个原则，并提供1套筛选收购对象和估值计算的方法。</p>
        <p class="paragraph">价值投资工具箱的四个分析工具并不是只能独立使用，分析工具可以互为补充、互相印证、互相参考，投资人可以从不同的角度去观察一个企业的经营特征和估值水平，提高投资的安全性和成功率。</p>
        <p class="paragraph">注意，由于金融类上市公司存在经营和评估的特殊性，价值投资工具箱分析的对象暂不包括金融类上市公司。</p>
        <p class="paragraph">价值投资工具箱的系统框架如下所示：</p>
        <p style="text-align:center;margin-top:30px"><img src="../../assets/价值投资工具箱.png" alt="" width="1187px" height="625px"></p>
     </section>
    </article>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "Value",
 data() {
   return {}
 },
 components:{}
}
</script>

<style scoped lang='scss'>
.header{
  text-align: center;
  margin-bottom: 20px;
}
.paragraph{
  text-indent: 2em;
}
</style>