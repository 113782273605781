<template>
 <div class="">
    <ol class="container" v-if="Object.keys(analysisResult).length > 0">
        <li>公司是否有过重的债务负担？</li>
          <div class="question" >
            <p>上一年度：</p>
            <p>（1）股东权益/长期负债={{(analysisResult.last_year[0].equity/analysisResult.last_year[0].long_term_liability).toFixed(2)}} 
              &nbsp;&nbsp;净利润/长期负债={{(analysisResult.last_year[0].net_profit/analysisResult.last_year[0].long_term_liability).toFixed(2)}}
            </p>
            <p>（2）总负债/EBITDA={{(analysisResult.last_year[0].total_liability/analysisResult.last_year[0].EBITDA).toFixed(2)}}</p>
            <p>（3）有息负债/EBITDA={{(analysisResult.last_year[0].interest_bearing_liability/analysisResult.last_year[0].EBITDA).toFixed(2)}}</p>
            <p>上一季度：</p> 
            <p>（1）股东权益/长期负债={{(analysisResult.last_season[0].equity/analysisResult.last_season[0].long_term_liability).toFixed(2)}}
              &nbsp;&nbsp;净利润/长期负债={{(analysisResult.last_season[0].net_profit_returned_to_parent/analysisResult.last_season[0].long_term_liability).toFixed(2)}}
            </p>    
          </div>
        <li>公司的盈利能力强吗？是否呈上升趋势？？</li>
          <div class="question">
            <div class="proCharts" ref="EPSChart" ></div>
            <p>过去十年每股净利润的复合增长率是{{ten_year_eps_cgr}}，过去五年每股净利润的复合增长率是{{five_year_eps_cgr}}。</p>
          </div>
        <li>公司是否回购过股票？</li>
          <div class="question">
            <div class="buyback">
              <div class="proCharts" ref="capitalChart" ></div>
              <div class="proCharts" ref="profitVsEPSChart" ></div>
            </div>            
          </div>
        <li>公司留存收益的利用是否增加了每股收益，从而增加了股东的价值？</li>
          <div class="question">
            <p>从{{beginyear}}年至{{lastyear}}年，公司的留存收益为每股{{ten_year_retained_sum}}元，每股收益从{{beginyear}}年年末的{{analysisResult.ten_year_data[lastindex].eps}}元增至{{lastyear}}年年末的{{analysisResult.ten_year_data[0].eps}}元，增长了{{analysisResult.ten_year_data[0].eps - analysisResult.ten_year_data[lastindex].eps}}元。因此，每股{{ten_year_retained_sum}}的留存收益，在{{lastyear}}年预期可以产生每股{{analysisResult.ten_year_data[0].eps - analysisResult.ten_year_data[lastindex].eps}}元的税后收益，相当于收益率为<b>{{toPercent((analysisResult.ten_year_data[0].eps - analysisResult.ten_year_data[lastindex].eps)/ten_year_retained_sum)}}</b>。</p>
          </div>
        <li>公司的股东权益回报率是否稳定，是否高于平均水平？</li>
          <div class="question">
            <div>
              <el-table
                  :data="analysisResult.ten_year_data"
                  style="width: 451px"
                  border>
                  <el-table-column
                    prop="time"
                    label="年份"
                    width="200"
                    :formatter="timeFormat">
                  </el-table-column>
                  <el-table-column
                    prop="ROE"
                    label="权益回报率（全面摊薄ROE）"
                    width="250"
                    :formatter="dataPercentFormat">
                  </el-table-column>
              </el-table>
            </div>
            <p>在这十年中，公司的年均股东权益回报率为<b>{{roe_avg}}</b>，波动率为{{roe_std}}。</p>            
          </div>
        <li>公司的总资本回报率是否高于平均水平？</li>
        <div class="question">
          <p>在这十年中，公司的年均总资本回报率为<b>{{toPercent(analysisResult.last_year[0].Ten_Year_Avg_ROC)}}</b>。</p>
        </div>
    </ol>
 </div>
</template>

<script type="text/ecmascript-6">
import {Table,TableColumn} from 'element-ui'
export default {
  name: "Six_Quantitative_Questions",
  data() {
    return {
      analysisResult:{},
      ten_year_eps_cgr: 0,
      five_year_eps_cgr: 0,
      ten_year_retained_per_share:0,
      ten_year_retained_sum:0,
      lastyear: "",
      beginyear: "",
      years:0,
      lastindex:0,
      roetable:[],
      roe_avg:0,
      roe_std:0             
    }
  },
  methods: {
    ten_year_data_eps_cgr_compute(){
      if(Object.keys(this.analysisResult.ten_year_data).length>10){
        this.ten_year_eps_cgr = this.toPercent(Math.pow(this.analysisResult.ten_year_data[0].eps/this.analysisResult.ten_year_data[10].eps,1/10)-1)
      }
      else{
        this.ten_year_eps_cgr = this.toPercent(Math.pow(this.analysisResult.ten_year_data[0].eps/this.analysisResult.ten_year_data[Object.keys(this.analysisResult.ten_year_data).length-1].eps,1/(this.year-1))-1)   
      }
    },
    five_year_data_eps_cgr_compute(){
      if(Object.keys(this.analysisResult.ten_year_data).length>5){
        this.five_year_eps_cgr = this.toPercent(Math.pow(this.analysisResult.ten_year_data[0].eps/this.analysisResult.ten_year_data[5].eps,1/5)-1)
      }
      else{
        this.five_year_eps_cgr = this.toPercent(Math.pow(this.analysisResult.ten_year_data[0].eps/this.analysisResult.ten_year_data[Object.keys(this.analysisResult.ten_year_data).length-1].eps,1/(this.years-1))-1)       
      }
    },
    ten_year_retained_sum_compute(){
      let sum = 0
      for(var i=0;i<this.years;i++){
        sum += this.analysisResult.ten_year_data[i].retained_income / this.analysisResult.ten_year_data[i].capital
      }
      this.ten_year_retained_sum = sum.toFixed(2)
    },
    roe_avg_std_compute(){
      this.roe_avg = this.toPercent(this.analysisResult.last_year[0].Ten_Year_Avg_ROE)
      this.roe_std = this.analysisResult.last_year[0].Ten_Year_ROE_Std.toFixed(2)
      this.dividend_yield_avg = this.toPercent(this.analysisResult.last_year[0].Ten_Year_Avg_Dividend_Yield)
      this.retained_yield_avg = this.toPercent(1-this.analysisResult.last_year[0].Ten_Year_Avg_Dividend_Yield)
    },
    toPercent(point){
      var str=Number(point*100).toFixed(2);
      str+="%";
      return str;
    },    
    epsChart(){
      var xdata = [],ydata=[]
      for(var i=0;i<Object.keys(this.analysisResult.ten_year_data).length;i++){
        xdata.splice(0,0,this.analysisResult.ten_year_data[i].time.slice(0,4))
      }
      for(i=0;i<Object.keys(this.analysisResult.ten_year_data).length;i++){
        ydata.splice(0,0,this.analysisResult.ten_year_data[i].eps)
      }     
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: {
            type: 'value',
            name: "EPS"
          },
          series: [
            {
              name: "十年EPS走势图",
              data: ydata,
              type: 'line',
              label: {
                show: true,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }
          ]
        }
      let myChart =this.$echarts.init(this.$refs.EPSChart);
      myChart.setOption(option)
    },
    capitalChart(){
     var xdata = [],ydata=[]
      for(var i=0;i<Object.keys(this.analysisResult.ten_year_data).length;i++){
        xdata.splice(0,0,this.analysisResult.ten_year_data[i].time.slice(0,4))
      }
      for(i=0;i<Object.keys(this.analysisResult.ten_year_data).length;i++){
        ydata.splice(0,0,this.analysisResult.ten_year_data[i].capital)
      }     
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: {
            type: 'value',
            name: "股本",
            axisLabel:{
              show:true,
              interval:"auto",
              formatter: function(a){
                return (a/100000000) + "亿"
              },
              scale:true
            }
          },
          series: [
            {
              name: "十年股本变化趋势图",
              data: ydata,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }
          ]
        }
      let myChart =this.$echarts.init(this.$refs.capitalChart);
      myChart.setOption(option)

    },
    profitVsEPSChart(){
     var xdata = [],ydata=[],zdata=[]
      for(var i=0;i<Object.keys(this.analysisResult.ten_year_data).length;i++){
        xdata.splice(0,0,this.analysisResult.ten_year_data[i].time.slice(0,4))
      }
      for(i=0;i<Object.keys(this.analysisResult.ten_year_data).length;i++){
        ydata.splice(0,0,this.analysisResult.ten_year_data[i].eps)
      }
      for(i=0;i<Object.keys(this.analysisResult.ten_year_data).length;i++){
        zdata.splice(0,0,this.analysisResult.ten_year_data[i].net_profit_returned_to_parent)
      }          
      var option =  {
          legend: {
            // orient: 'vertical',
            top: 10,
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: xdata,
            name:"时间",
            axisTick: {
              alignWithLabel: true
            },
          },
          yAxis: [
            {
              type: 'value',
              name: "EPS",
              position: "left"
             },
             {
               type: "value",
               name: "归母净利润",
               position: "right"
             }
          ],  
          series: [
            {
              name: "十年EPS走势图",
              data: ydata,
              yAxisIndex: 0,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            },
            {
              name: "十年归母净利润走势图",
              data: zdata,
              yAxisIndex: 1,
              type: 'line',
              label: {
                show: false,
                position: 'bottom',
                textStyle: {
                  fontSize: 10
                }
              }
            }
          ]
        }
      let myChart =this.$echarts.init(this.$refs.profitVsEPSChart);
      myChart.setOption(option)
    },
    timeFormat(row, column, cellValue) {
      return cellValue.slice(0,4)
    },
    dataPercentFormat(row, column, cellValue) {
      return this.toPercent(cellValue)
    }   
  },
  updated(){
    if(Object.keys(this.analysisResult.last_year).length>0 && Object.keys(this.analysisResult.ten_year_data).length>0 && Object.keys(this.analysisResult.last_day).length>0){
      this.epsChart()
      this.capitalChart()
      this.profitVsEPSChart()
      this.years = Object.keys(this.analysisResult.ten_year_data).length
      this.lastindex = this.years - 1
      this.beginyear = this.analysisResult.ten_year_data[this.years-1].time.slice(0,4)
      this.lastyear = this.analysisResult.ten_year_data[0].time.slice(0,4)
      this.ten_year_data_eps_cgr_compute()
      this.five_year_data_eps_cgr_compute()
      this.ten_year_retained_sum_compute()
      this.roe_avg_std_compute()
    }
  },  
  components:{
    [Table.name]:Table,
    [TableColumn.name]:TableColumn    
  },
  mounted(){
    this.analysisResult = this.$store.state.tsa_result
  }
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 20px;
    margin-left: 30px;
    font-size: 16px;
}
.question{
  margin-left:30px;
  p{
    font-family: "宋体";
    margin: 10px;
  }
}
.buyback{
  display: flex;
} 
.proCharts{
  width: 50%;
  height: 500px;
  // background: rgb(14, 51, 129);
}

</style>