<template>
 <div class="container" v-if="Object.keys(rankingData).length > 0">
   <ol class="">
     <li>边缘企业（按权益价值估值除以当前市值排序）</li>
       <ul style="list-style-type:disc" class="ranking">
         <li>第一类（EBITDA为正）</li>
            <div>
              <el-table
                  :data="rankingData.edge_one_list"
                  style="width: 1851px"
                  border>
                  <el-table-column
                    prop="time"
                    label="时间"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="info_id"
                    label="代码"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="industry"
                    label="行业"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="EBITDA"
                    label="EBITDA_TTM"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="ev_divide_ebitda"
                    label="EV/EBITDA"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="equity"
                    label="净资产"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="mv_divide_equity"
                    label="MV/EQUITY"
                    width="150">
                  </el-table-column>  
                  <el-table-column
                    prop="EV"
                    label="企业价值"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="MV"
                    label="当前市值"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="ev_valuation"
                    label="企业价值估值"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="mv_valuation"
                    label="权益价值估值"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="mv_valuation_divide_MV"
                    label="权益价值估值除以当前市值"
                    width="200">
                  </el-table-column>                  
              </el-table>
            </div>         
         <li>第二类（EBITDA为负）</li>
            <div>
              <el-table
                  :data="rankingData.edge_two_list"
                  style="width: 1401px"
                  border>
                  <el-table-column
                    prop="time"
                    label="时间"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="info_id"
                    label="代码"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="industry"
                    label="行业"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="revenue"
                    label="营收（TTM）"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="EV"
                    label="企业价值"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="MV"
                    label="当前市值"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="ev_valuation"
                    label="企业价值估值"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="mv_valuation"
                    label="权益价值估值"
                    width="150">
                  </el-table-column>
                  <el-table-column
                    prop="mv_valuation_divide_MV"
                    label="权益价值估值除以当前市值"
                    width="200">
                  </el-table-column>                                                                                       
              </el-table>
            </div>              
       </ul>
     <li>杠杆收购（按权益价值估值除以当前市值排序）</li>  
        <div>
          <el-table
              :data="rankingData.lever_list"
              style="width: 2101px"
              border>
              <el-table-column
                prop="time"
                label="时间"
                width="150">
              </el-table-column>
              <el-table-column
                prop="info_id"
                label="代码"
                width="150">
              </el-table-column>
              <el-table-column
                prop="industry"
                label="行业"
                width="150">
              </el-table-column>
              <el-table-column
                prop="EBIT"
                label="EBIT_TTM"
                width="150">
              </el-table-column>
              <el-table-column
                prop="lever_interest"
                label="杠杆利息"
                width="150">
              </el-table-column>
              <el-table-column
                prop="risk_free_rate"
                label="无风险利率"
                width="150">
              </el-table-column>              
              <el-table-column
                prop="lever_debt"
                label="杠杆债务"
                width="150">
              </el-table-column>
              <el-table-column
                prop="lever_cash"
                label="杠杆现金"
                width="150">
              </el-table-column>   
              <el-table-column
                prop="lever_ev"
                label="杠杆收购的企业价值估值"
                width="200">
              </el-table-column> 
              <el-table-column
                prop="lever_mv"
                label="杠杆收购的权益价值估值"
                width="200">
              </el-table-column>               
              <el-table-column
                prop="EV"
                label="企业价值"
                width="150">
              </el-table-column>
              <el-table-column
                prop="MV"
                label="当前市值"
                width="150">
              </el-table-column>
              <el-table-column
                prop="lever_mv_divide_MV"
                label="权益价值估值除以当前市值"
                width="200">
              </el-table-column>
          </el-table>
        </div>         
   </ol>
 </div>
</template>

<script type="text/ecmascript-6">
import {Table,TableColumn} from 'element-ui'
export default {
 name: "EL_Ranking_List",
 data() {
   return {
    rankingData:{}
   }
 },
 components:{
   [Table.name]:Table,
   [TableColumn.name]:TableColumn        
 },
 mounted(){
   this.$http.el_ranking_list().then(res=>{
     this.rankingData = res.data
    }).catch(err=>{
        console.log(err)
    })     
 } 
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 20px;
    margin-left: 30px;
    font-size: 16px;
}
.ranking{
  margin: 20px;
}
</style>