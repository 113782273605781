<template>
 <div class="frame">
   <el-container class="frame-container">
    <el-header class="header">
      <img src="../assets/标题.png" alt="" class="logo">
      <a href="/" class="brand"><strong>价值猎手</strong></a>
      <span class="title"><strong>手把手教你搭建投资系统</strong></span>
    </el-header>
    <el-container>
      <el-aside width='200px' class="aside">
        <el-row class="tac">
          <el-col :span="24">
            <el-menu
              default-acitve="/"
              class="menu"
              background-color = "#C3DCE1"
              text-color = "#000000"
              :router = "true">
              <el-submenu index="0">
                <template slot="title">
                  <i class="el-icon-s-home"></i>
                  <span>价值投资</span>
                </template>
                  <el-menu-item index="/value">简述</el-menu-item>
                  <el-submenu index="0-1">
                    <template slot="title">时间序列分析</template>
                    <el-menu-item index="/value/tsa/summary">简述</el-menu-item>
                    <el-menu-item index="/value/tsa/framework">框架</el-menu-item>
                    <el-menu-item index="/value/tsa/query">查询</el-menu-item>
                    <el-menu-item index="/value/tsa/ranking_list">龙虎榜</el-menu-item>
                  </el-submenu>
                  <el-submenu index="0-2">
                    <template slot="title">财务特征分析</template>
                    <el-menu-item index="/value/ffa/summary">简述</el-menu-item>
                    <el-menu-item index="/value/ffa/framework">框架</el-menu-item>
                    <el-menu-item index="/value/ffa/query">查询</el-menu-item>
                    <el-menu-item index="/value/ffa/ranking_list">龙虎榜</el-menu-item>
                  </el-submenu>                  
                  <el-submenu index="0-3">
                    <template slot="title">可比性分析</template>
                    <el-menu-item index="/value/ca/summary">简述</el-menu-item>
                    <el-menu-item index="/value/ca/framework">框架</el-menu-item>
                    <el-menu-item index="/value/ca/query">查询</el-menu-item>
                  </el-submenu>       
                  <el-submenu index="0-4">
                    <template slot="title">边缘企业和杠杆收购</template>
                    <el-menu-item index="/value/el/summary">简述</el-menu-item>
                    <el-menu-item index="/value/el/framework">框架</el-menu-item>
                    <el-menu-item index="/value/el/ranking_list">龙虎榜</el-menu-item>
                  </el-submenu>   
              </el-submenu>
              <el-submenu index="1">
                <template slot="title">
                  <i class="el-icon-s-promotion"></i>
                  <span>逆向投资</span>
                </template>
                  <el-menu-item index="/reverse/summary">简述</el-menu-item>
                  <el-menu-item index="/reverse/framework">框架</el-menu-item>
                  <el-menu-item index="/reverse/query">查询</el-menu-item>
              </el-submenu>
              <el-submenu index="2">
                <template slot="title">
                  <i class="el-icon-s-data"></i>
                  <span>周期投资</span>
                </template>
                  <el-menu-item index="/cycle/summary">简述</el-menu-item>
                  <el-menu-item index="/cycle/framework">框架</el-menu-item>
                  <el-menu-item index="/cycle/financial_business_cycles">金融和商业周期</el-menu-item>
                  <el-menu-item index="/cycle/market_cycle">市场周期</el-menu-item>
                  <el-submenu index="0-5">
                    <template slot="title">行业周期</template>
                    <el-menu-item index="/cycle/tracking_model">跟踪模型</el-menu-item>
                    <el-menu-item index="/cycle/industry_indicator">行业指标</el-menu-item>
                    <el-menu-item index="/cycle/indicator_component">行业组成</el-menu-item>
                    <el-menu-item index="/cycle/indicator_ranking_list">龙虎榜</el-menu-item>
                  </el-submenu>
                  
              </el-submenu>
              <el-submenu index="3">
                <template slot="title">
                  <i class="el-icon-s-marketing"></i>
                  <span>量化投资</span>
                </template>
                  <el-menu-item index="/quantitative/summary">简述</el-menu-item>
                  <el-menu-item index="/quantitative/result">策略执行结果</el-menu-item>
              </el-submenu>
              <el-menu-item index="/financial_terminology">
                <template slot="title">
                  <i class="el-icon-files"></i>
                  <span>财务术语</span>
                </template>
              </el-menu-item> 
              <el-menu-item index="/books">
                <template slot="title">
                  <i class="el-icon-notebook-2"></i>
                  <span>推荐书籍</span>
                </template>
              </el-menu-item>   
              <el-menu-item index="/qa">
                <template slot="title">
                  <i class="el-icon-question"></i>
                  <span>Q&A</span>
                </template>
              </el-menu-item>                                           
            </el-menu>
          </el-col>
        </el-row>
      </el-aside>
        <el-container>
          <el-main class="main">
            <router-view></router-view>
          </el-main>
          <el-footer class="footer">
            <p>Copyright © 2024 广州价值猎手数据分析团队 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration:none">粤ICP备2024282830号-1</a></p>
            <p style="color:red">本网站的所有数据和分析方法仅用于学习和研究，并不保证准确，根据网站数据进行投资一切后果自负</p>
          </el-footer>
        </el-container>
    </el-container>
  </el-container>
 </div>
</template>

<script type="text/ecmascript-6">
import {Container,Header,Aside,Main,Footer,Row,Col,Menu,Submenu,MenuItem} from "element-ui"
export default {
 name: "Frame",
 data() {
   return {}
 },
 components:{
   [Container.name]: Container,
   [Header.name]: Header,
   [Aside.name]: Aside,
   [Main.name]: Main,
   [Footer.name]: Footer,
   [Row.name]: Row,
   [Col.name]: Col,
   [Menu.name]: Menu,
   [Submenu.name]: Submenu,
   [MenuItem.name]: MenuItem,
 },
  methods: {
    // handleOpen(key, keyPath) {
    //   console.log(key, keyPath);
    // },
    // handleClose(key, keyPath) {
    //   console.log(key, keyPath);
    // }
  }
}
</script>

<style scoped lang='scss'>
.frame-container{
  height: 100vh;
  .header{
    height:200px;
    background-color: #00a65a;
    display: flex;
    background-image: url('../assets/顶部背景.png');
    .logo{
      margin-left: -20px;
    }
    .brand{
      width: 140px;
      // margin-left: 20px;
      background-color: #E2F0F3;
      font-size: 20px;
      text-shadow: 2px 2px 4px #000000;
      color: #fff;
      display: flex;
      justify-content:space-between;
      align-items: center;
      text-decoration: none;
      letter-spacing: 5px;
    }
    .title{
      margin-left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color:deepskyblue;
      text-shadow: 2px 2px 4px #000000;
      letter-spacing: 20px;
    }  
  }
  .aside{
    background-color: #c3dce1; 
  }
  .footer{
    background-color: gray;
    text-align: center;
    background-image: url('../assets/底部背景.png');    
  }
}
</style>

<style scoped>
.aside >>> .el-menu{
  border-right: none;
}
</style>