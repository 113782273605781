<template>
 <div class="container" v-if="Object.keys(analysisResult).length > 0">
   <p class="title">利润增长估值法</p>
   <div>
     <span>（1）根据过去十年的每股利润数据进行预测</span>
     <p>1、过去10年的归母净利润、每股利润和每股股息数据：</p>
     <el-table
       :data="analysisResult.ten_year_data"
       style="width: 492px"
       border>
       <el-table-column
         prop="time"
         label="年份"
         width="120"
         :formatter="timeFormat">
       </el-table-column>                     
       <el-table-column
         prop="net_profit_returned_to_parent"
         label="归母净利润"
         width="130">
       </el-table-column>
       <el-table-column
         prop="eps"
         label="每股净利润"
         width="120">
       </el-table-column>                
       <el-table-column
         prop="cumulative_dividend"
         label="每股派发股息"
         width="120"
         :formatter="dataDivide">
       </el-table-column>                                                                        
       </el-table>  
       <p>2、过去10年归母净利润的年复合增长率为{{toPercent(analysisResult.last_year[0].Ten_Year_Profit_Annual_Growth_Rate)}}，平均派发股息率为{{toPercent(analysisResult.last_year[0].Ten_Year_Avg_Dividend_Yield)}}。</p>      
       <p>3、假设未来10年的每股利润的年复合增长率即为过去10年的归母净利润的年复合增长率，估算未来10年的每股净利润和每股派发股息:</p>
       <el-table
         :data="analysisResult.ten_year_profit_assessment.slice(1,Object.keys(analysisResult.ten_year_profit_assessment).length)"
         style="width: 361px"
         border>
         <el-table-column
           prop="index"
           label="年份"
           width="120"
           :formatter="timeFormat">
         </el-table-column>
         <el-table-column
           prop="eps"
           label="每股净利润"
           width="120"
           :formatter="datafix">
         </el-table-column> 
         <el-table-column
           prop="dividend"
           label="每股派发股息"
           width="120"
           :formatter="datafix">
         </el-table-column> 
       </el-table>   
       <p>4、根据过去10年的平均市盈率和10年后每股净利润，估算10年后的每股价格，根据现价、10年后的每股价格加10年股息后的价格，计算年复合收益率</p>  
       <p>过去10年的平均市盈率为{{(analysisResult.last_year[0].Ten_Year_Avg_PE).toFixed(2)}}，预测10年后的每股价格为{{(analysisResult.last_year[0].After_10Y_Price_Profit_Law).toFixed(2)}}。根据昨日价格{{analysisResult.last_day[0].price}}，预计10年年复合收益率为<b>{{analysisResult.last_day[0].after_10Y_compound_annual_return_rate_PA}}%</b>。</p>                 
    </div>  
    <br>
    <div>
        <span>（2）根据过去五年的每股利润数据进行预测</span>
        <p>1、过去5年的归母净利润、每股利润和每股股息数据：</p>
        <el-table
            :data="analysisResult.ten_year_data.slice(0,6)"
            style="width: 491px"
            border>
            <el-table-column
                prop="time"
                label="年份"
                width="120"
                :formatter="timeFormat">
            </el-table-column>                     
            <el-table-column
                prop="net_profit_returned_to_parent"
                label="归母净利润"
                width="130">
                </el-table-column>
            <el-table-column
                prop="eps"
                label="每股净利润"
                width="120">
                </el-table-column>                
            <el-table-column
                prop="cumulative_dividend"
                label="每股派发股息"
                width="120"
                :formatter="dataDivide">
            </el-table-column>                                                                        
        </el-table>  
        <p>2、过去5年归母净利润的年复合增长率为{{toPercent(analysisResult.last_year[0].Five_Year_Profit_Annual_Growth_Rate)}}，平均派发股息率为{{toPercent(analysisResult.last_year[0].Five_Year_Avg_Dividend_Yield)}}。</p>      
        <p>3、假设未来5年的每股利润的年复合增长率即为过去5年的归母净利润的年复合增长率，估算未来5年的每股净利润和每股派发股息:</p>
        <el-table
            :data="analysisResult.five_year_profit_assessment.slice(1,Object.keys(analysisResult.ten_year_profit_assessment).length)"
            style="width: 361px"
            border>
            <el-table-column
                prop="index"
                label="年份"
                width="120"
                :formatter="timeFormat">
            </el-table-column>
            <el-table-column
                prop="eps"
                label="每股净利润"
                width="120"
                :formatter="datafix">
            </el-table-column> 
            <el-table-column
                prop="dividend"
                label="每股派发股息"
                width="120"
                :formatter="datafix">
            </el-table-column> 
        </el-table>   
        <p>4、根据过去5年的平均市盈率和5年后每股净利润，估算5年后的每股价格，根据现价、5年后的每股价格加5年股息后的价格，计算年复合收益率</p>  
        <p>过去5年的平均市盈率为{{(analysisResult.last_year[0].Five_Year_Avg_PE).toFixed(2)}}，预测5年后的每股价格为{{(analysisResult.last_year[0].After_5Y_Price_Profit_Law).toFixed(2)}}。根据昨日价格{{analysisResult.last_day[0].price}}，预计5年年复合收益率为<b>{{analysisResult.last_day[0].after_5Y_compound_annual_return_rate_PA}}%</b>。</p>                 
    </div>
 
 </div>
</template>

<script type="text/ecmascript-6">
import {Table,TableColumn} from 'element-ui'
export default {
  name: "Third_Math_Tool",
  data() {
    return {
      analysisResult:{},  

    }
  },
  methods:{
    timeFormat(row, column, cellValue) {
      return cellValue.slice(0,4)
    },
    dataPercentFormat(row, column, cellValue) {
      return this.toPercent(cellValue)
    },
    dataDivide(row,column,cellValue,index){
      return (cellValue/this.analysisResult.ten_year_data[index].capital).toFixed(2)
    },
    datafix(row,column,cellValue){
      return (cellValue).toFixed(2)
    },   
    toPercent(point){
      var str=Number(point*100).toFixed(2);
      str+="%";
      return str;
    }       
  },
  components:{
    [Table.name]:Table,
    [TableColumn.name]:TableColumn        
  },
  mounted(){
    this.analysisResult = this.$store.state.tsa_result
  }
}
</script>

<style scoped lang='scss'>
.container{
  span{
    font-size: 16px;
  }
  p{
    font-family: "宋体";
    margin: 10px;
  }
  .title{
    font-family: "微软雅黑";
    margin: 10px 10px;
  }  
}
</style>