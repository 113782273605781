<template>
 <div class="">
    <ol class="container" v-if="Object.keys(analysisResult).length > 0">
        <li>公司的上市时间：{{analysisResult.time_to_market}}</li>
        <li>公司所在的行业：{{analysisResult.industry}}</li>
        <li>公司的无形资产占比</li>
        <ul style="list-style-type:disc" class="detail">
            <li>公司过去一年的无形资产占比：{{analysisResult.stock_key_info_summary[0].intangible_assets_ratio}}</li>
            <li>公司过去十年的无形资产占比</li>
            <div class="proCharts" ref="intangibleAssetsRatioChart" ></div>
        </ul>
        <li>公司的固定资产占比</li>
        <ul style="list-style-type:disc" class="detail">
            <li>公司过去一年的固定资产占比：{{analysisResult.stock_key_info_summary[0].fixed_assets_ratio}}</li>
            <li>公司过去十年的固定资产占比</li>
            <div class="proCharts" ref="fixedAssetsRatioChart" ></div>            
        </ul>        
        <li>公司的资本开支占比</li>
        <ul style="list-style-type:disc" class="detail">
            <li>公司过去一年的资本开支占比：{{analysisResult.stock_key_info_summary[0].capital_expenditure_ratio}}</li>
            <li>公司过去十年的资本开支占比</li>
            <div class="proCharts" ref="capitalExpenditureRatioChart"></div>
        </ul>         
    </ol>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "Duration_of_Operation",
 data() {
   return {
    analysisResult:{}
   }
 },
  updated(){
    if(Object.keys(this.analysisResult.stock_key_info_summary).length>0){
      this.intangibleAssetsRatioChart()
      this.fixedAssetsRatioChart()
      this.capitalExpenditureRatioChart()
    }
  }, 
 methods:{
   intangibleAssetsRatioChart(){
    var xdata = [],ydata=[]
    for(var i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
     xdata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].time.slice(0,4))
    }
    for(i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
     ydata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].intangible_assets_ratio)
    }     
    var option =  {
        legend: {
        // orient: 'vertical',
        top: 10,
        },
        tooltip: {},
        xAxis: {
        type: 'category',
        data: xdata,
        name:"时间",
        axisTick: {
            alignWithLabel: true
        },
        },
        yAxis: {
        type: 'value',
        name: "无形资产占比"
        },
        series: [
            {
            name: "十年无形资产占比趋势图",
            data: ydata,
            type: 'line',
            label: {
            show: true,
            position: 'bottom',
            textStyle: {
                fontSize: 10
            }
            }
            }
        ]
    }
    let myChart =this.$echarts.init(this.$refs.intangibleAssetsRatioChart);
    myChart.setOption(option)
   },
   fixedAssetsRatioChart(){
    var xdata = [],ydata=[]
    for(var i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
    xdata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].time.slice(0,4))
    }
    for(i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
    ydata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].fixed_assets_ratio)
    }     
    var option =  {
        legend: {
        // orient: 'vertical',
        top: 10,
        },
        tooltip: {},
        xAxis: {
        type: 'category',
        data: xdata,
        name:"时间",
        axisTick: {
            alignWithLabel: true
        },
        },
        yAxis: {
        type: 'value',
        name: "固定资产占比"
        },
        series: [
            {
            name: "十年固定资产占比趋势图",
            data: ydata,
            type: 'line',
            label: {
            show: true,
            position: 'bottom',
            textStyle: {
                fontSize: 10
            }
            }
            }
        ]
    }
    let myChart =this.$echarts.init(this.$refs.fixedAssetsRatioChart);
    myChart.setOption(option)
   }, 
   capitalExpenditureRatioChart(){
    var xdata = [],ydata=[]
    for(var i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
    xdata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].time.slice(0,4))
    }
    for(i=0;i<Object.keys(this.analysisResult.ten_year_intrinsic_value).length;i++){
    ydata.splice(0,0,this.analysisResult.ten_year_intrinsic_value[i].capital_expenditure_ratio)
    }     
    var option =  {
        legend: {
        // orient: 'vertical',
        top: 10,
        },
        tooltip: {},
        xAxis: {
        type: 'category',
        data: xdata,
        name:"时间",
        axisTick: {
            alignWithLabel: true
        },
        },
        yAxis: {
        type: 'value',
        name: "资本开支占比"
        },
        series: [
            {
            name: "十年资本开支占比趋势图",
            data: ydata,
            type: 'line',
            label: {
            show: true,
            position: 'bottom',
            textStyle: {
                fontSize: 10
            }
            }
            }
        ]
    }
    let myChart =this.$echarts.init(this.$refs.capitalExpenditureRatioChart);
    myChart.setOption(option)
   },       
 },
 components:{},
 mounted(){
   this.analysisResult = this.$store.state.ffa_result
//    console.log(this.analysisResult);
 } 
}
</script>

<style scoped lang='scss'>
.container li{
    margin: 10px;
    margin-left: 30px;
    font-size: 16px;
}
.detail{
  margin: 20px;    
}
.proCharts{
  width: 50%;
  height: 500px;
  // background: rgb(14, 51, 129);
}
</style>