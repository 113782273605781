<template>
 <div class="">
    <article>
      <section>
        <p class="paragraph"><b>Q：</b>价值猎手网站和其他数据平台有什么不同？</p>
        <p class="paragraph"><b>A：</b>我们认为，<b>数据真正的价值在于如何利用数据，而非数据本身。</b>我们利用市场公开数据为投资者提供了一个系统分析的框架，利用这个投资框架，投资者可以参考经典书籍和投资大师们的投资分析逻辑和方法，提升一个维度去分析投资标的的各种数据。</p>
        <br>
        <p class="paragraph"><b>Q：</b> 各种分析工具之间有什么区别？我应该用哪个分析工具？</p>
        <p class="paragraph"><b>A：</b>价值猎手网站提供的各种工具没有优劣之分，也没有非此即彼的区别。一般而言，时间序列分析适用于稳定的公司，逆向分析适用于遭遇了黑天鹅的公司，边缘企业和杠杆收购分析适用于利润亏损的公司，周期投资分析适用于周期性行业。但是，这并不是绝对的。例如用时间序列分析也可以看出一些不稳定的公司的周期性表现，比如利润增长的不稳定性；财务特征分析深入了解财务的细节，观察有无判断的盲点；杠杆收购分析作为其他分析工具的补充验证；量化投资分析作为技术特征可以辅助判断投资市场的热度等等。<b>这些分析工具筛选出来的“龙虎榜“是需要特别注意的目标。</b>我们应该谨记分析分三种：财务特征分析占45%，技术特征分析占5%，业务特征分析占50%。工具提供了财务特征和技术特征分析的思路，但永远代替不了投资者对投资标的的深刻理解：业务特征分析。<b>业务特征分析需要投资者自己线下去完成。</b></p>
        <br>
        <p class="paragraph"><b>Q：</b>网站数据的更新周期为何？</p>
        <p class="paragraph"><b>A：</b>我们有日频数据库和月频数据库。目前安排日频数据库在每日0点进行更新，月频数据库在每月28日晚上20点进行更新。采集时间是：</p>
        <p style="text-align:center;margin-top:20px;margin-bottom:20px"><img src="../assets/定时任务.png" alt="" width="1000px" height="350px" ></p>
        <br>
        <p class="paragraph"><b>Q： </b>网站提供的数据准确吗？</p>
        <p class="paragraph"><b>A：</b>价值猎手网站的数据目前来源于公开数据，数据更新速度和完整性尚未达到券商机构接口的水平，但是经验证准确率是比较高的（<b>但不保证也不承诺100%准确</b>）。我们承诺会继续提高数据的准确性，未来会更换更优质的数据源。<b><font color="red">需要强调的是，网站提供的数据仅用于学习和研究，投资者据此进行投资责任自负。</font></b></p>        
     </section>
    </article>
 </div>
</template>

<script type="text/ecmascript-6">
export default {
 name: "QA",
 data() {
   return {}
 },
 components:{}
}
</script>

<style scoped lang='scss'>
.header{
  text-align: center;
  margin-bottom: 20px;
}
.paragraph{
  text-indent: 2em;
}
</style>