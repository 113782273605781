<template>
 <div class="">
   <div class="inputform">
      <el-form ref="form" label-width="100px">
        <el-form-item label="请输入代码:">
          <el-autocomplete
            popper-class="my-autocomplete"
            v-model="symbol"
            :fetch-suggestions="querySearch"
            :debounce="500"
            @select="handleSelect"
            >
            <template slot-scope="{ item }">
              <div class="name">{{item.symbol}}&nbsp;&nbsp;&nbsp;&nbsp;{{item.name}}&nbsp;&nbsp;&nbsp;&nbsp;{{item.market}}</div>
            </template>  
          </el-autocomplete>
          <el-button type="primary" @click="handleClick">查询</el-button>
        </el-form-item>
      </el-form>
   </div>
 </div>
</template>

<script type="text/ecmascript-6">
import {Form, FormItem,Autocomplete,Button} from "element-ui"
export default {
 name: "FFA_Query",
 data() {
   return {
      allSymbol: [],
      symbol:''
   }
 },
 components:{
   [Autocomplete.name]: Autocomplete,
   [Form.name]: Form,
   [FormItem.name]: FormItem,
   [Button.name]: Button,
 },
 methods:{
   querySearch(queryString, cb) {
     var allSymbol = this.allSymbol
     var results = queryString ? allSymbol.filter(inputsymbol=>(inputsymbol.symbol.indexOf(queryString) === 0 || inputsymbol.name.indexOf(queryString) != -1)) : allSymbol;
     // 调用 callback 返回建议列表的数据
     cb(results)
   },
   handleSelect(item){
    this.symbol = item.symbol
   },
   handleClick(){
    if(this.symbol != ''){
      this.$router.push("/value/ffa/query/result")
      this.$store.state.searchsymbol = this.symbol
    }
   }
 },
 mounted(){
   let params = new URLSearchParams()
   params.append('symbol',this.symbol)
   this.$http.searchSymbol(params).then(res=>{
     this.allSymbol = res.data
    }).catch(err=>{
        console.log(err)
    })     
 }
}
</script>

<style scoped lang='scss'>

</style>