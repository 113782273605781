<template>
 <div v-if="industry.length > 0">
   <div class="inputform">
      <el-form ref="form" label-width="150px">
             <el-form-item label="请输入或选择行业:">
                <el-autocomplete
                popper-class="my-autocomplete"
                v-model="symbol"
                :fetch-suggestions="querySearch"
                :debounce="500"
                @select="handleSelect"
                >
                <template slot-scope="{ item }">
                    <div class="name">{{item.index_code}}&nbsp;&nbsp;&nbsp;&nbsp;{{item.index_name}}</div>
                </template>  
                </el-autocomplete>
                <el-button type="primary" @click="handleClick">查询</el-button>   
            </el-form-item>
       </el-form>  
    </div>
 </div>
</template>

<script type="text/ecmascript-6">
import {Form, FormItem,Autocomplete,Button} from "element-ui"
export default {
 name: "Industry_Indicator",
 data() {
   return {
     industry: [],  
     symbol:'',       
   }
 },
 methods:{
   querySearch(queryString, cb) {
     var industry = this.industry
     var results = queryString ? industry.filter(inputsymbol=>(inputsymbol.index_code.indexOf(queryString) === 0 || inputsymbol.index_name.indexOf(queryString) != -1)) : industry;
      // 调用 callback 返回建议列表的数据
     cb(results)
   },    
   handleClick(){
     if(this.symbol != ''){
       this.$router.push("/cycle/industry_indicator/result")
       this.$store.state.searchIndustry = this.symbol
     }
   },
   handleSelect(item){
     this.symbol= item.index_code
   }
 },
 components:{
    [Autocomplete.name]: Autocomplete,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Button.name]: Button,

 },
 mounted(){
   this.$http.searchIndustry().then(res=>{
     this.industry = res.data
   }).catch(err=>{
        console.log(err)
   })    
 }
}
</script>

<style scoped lang='scss'>
.container li{
  margin: 20px;
  margin-left: 30px;
  font-size: 16px;
}
</style>